import { useAtom } from "jotai";
import { MouseEventHandler } from "react";
import { Background, Button, Dialogue, CharacterImages } from "../components";
import {
  Dropdown,
  InputText,
  MultipleChoice,
  Rating,
  SelectPut,
  Video,
} from "../components/mechanics";
import Zoom from "../components/mechanics/Zoom";
import { showNextButtonAtom } from "../stores";
import styles from "../styles/scene.module.css";

interface SceneProps {
  background: string;
  characterNames: string[];
  characterImages: string[];
  line: string;
  option: Option;
  transition?: "dark" | "light";
  onNext: MouseEventHandler;
}

const Scene = ({
  background,
  characterNames,
  characterImages,
  line,
  option,
  transition,
  onNext,
}: SceneProps) => {
  const [showNextButton, setShowNextButton] = useAtom(showNextButtonAtom);

  return (
    <div className={styles.sceneContainer}>
      <Background src={background} transition={transition} />
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div className={styles.sceneTop}></div>
        <div className={styles.sceneCenter}>
          {option.name !== "OptionWithContent" &&
            option.name !== "SelectPut" && (
              <CharacterImages characters={characterImages} />
            )}
          {option.name === "Text" && (
            <InputText
              key={line}
              type={option.param ? option.param["type"] ?? "short" : "short"}
              placeholder={option.param ? option.param["placeholder"] : ""}
              variableCode={
                option.param ? option.param["variableCode"] : undefined
              }
            />
          )}
          {option.name === "LongText" && (
            <InputText
              key={line}
              type="long"
              placeholder={option.param ? option.param["placeholder"] : ""}
              variableCode={
                option.param ? option.param["variableCode"] : undefined
              }
            />
          )}
          {(option.name === "Zoom" || option.name === "OptionWithContent") && (
            <Zoom
              key={"Zoom " + line}
              contents={option.param ? option.param["contents"] : []}
              fullWidth={option.name === "OptionWithContent"}
            />
          )}
          {(option.name === "Option" ||
            option.name === "OptionWithContent") && (
            <MultipleChoice
              key={"Option " + line}
              options={option.param ? option.param["options"] : []}
              values={option.param ? option.param["values"] : []}
              select={option.param ? option.param["select"] : 1}
              variableCode={
                option.param ? option.param["variableCode"] : undefined
              }
              variableIndex={
                option.param ? option.param["variableIndex"] : undefined
              }
            />
          )}
          {option.name === "Video" && (
            <Video key={line} src={option.param ? option.param["src"] : ""} />
          )}
          {option.name === "SelectPut" && (
            <SelectPut
              key={"SelectPut " + line}
              title={option.param ? option.param["title"] : ""}
              options={option.param ? option.param["options"] : []}
              values={option.param ? option.param["values"] : []}
              select={option.param ? option.param["select"] : 1}
              variableCode={
                option.param ? option.param["variableCode"] : undefined
              }
            />
          )}
          {option.name === "Dropdown" && (
            <Dropdown
              key={line}
              text={option.param ? option.param["text"] : ""}
              optionsList={option.param ? option.param["optionList"] : ""}
            />
          )}
          {option.name === "Rating" && (
            <Rating
              key={"Rating" + line}
              variableCode={
                option.param ? option.param["variableCode"] : undefined
              }
            />
          )}
        </div>
        <div className={styles.sceneBottom}>
          <Dialogue
            line={line}
            characters={characterNames}
            onLoopDone={() => {
              if (option.name === null) {
                setShowNextButton(true);
              }
            }}
          />
          {showNextButton && (
            <Button text="Next" type="next" onClick={onNext} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Scene;
