const Scene033: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Rata-rata pendekar padepokan Selaras dapat menempuh jarak 200 meter dalam waktu 40 detik.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Sekarang pilih target yang ingin dicapai dalam percobaan UJI KECEPATAN LARI berikutnya.",
      option: {
        name: "Option",
        param: {
          options: ["20 detik", "35 detik", "40 detik", "60 detik"],
          values: [20, 35, 40, 60],
          select: 1,
          variableCode: "targetAwal",
        },
      },
    },
  ],
};

export default Scene033;
