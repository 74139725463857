const Scene214: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 215,
  dialogues: [
    {
      characters: ["Alif (shock)"],
      line: "Kok masih salah sih, sepertinya kakak kurang menyimak materi dari Eyang Guru. SWOT itu merupakan singkatan dari Strength, Weakness, Opportunity, dan Threat",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene214;
