import aturanMain from "../assets/contents/aturanMiniGame3.jpg";

const Scene161: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmWarm1",
  sfx: "",
  next: 162,
  dialogues: [
    {
      characters: ["eyang guru (shock)"],
      line: "Astagfirullah. Kalah ya. Tau kenapa kamu kalah? Karena kamu tidak tahu aturan pertandingan, tidak tahu apa kekuatan kamu sendiri dan apa kekuatan lawan. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ini aturan pertandingannya. Lihat dan ingat baik-baik ya. Untuk putaran kedua ini kamu akan berjalan terlebih dahulu.",
      option: {
        name: "Zoom",
        param: {
          contents: [aturanMain],
        },
      },
    },
  ],
};

export default Scene161;
