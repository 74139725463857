import materi from "../assets/contents/materi1prove2.jpg";

const Scene091: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Silahkan dibaca terlebih dahulu profil CDP bernama Ikoy berikut",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Berdasarkan cerita di samping, apakah Ikoy tergolong CDP yang prestatif",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [false, true],
          select: 1,
          variableCode: "111",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Apa alasan kakak menggolongkan Ikoy seperti jawaban sebelumnya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Tidak mau memasang target melebihi standar",
            "Tidak menerima umpan balik",
            "Berhati-hati",
            "Teliti",
          ],
          values: [true, true, false, false],
          select: 1,
          variableCode: "111",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene091;
