import { useCallback, useState } from "react";
import styles from "../styles/input.module.css";
import eyeIcon from "../assets/icons/eye.png";

interface InputProps {
  type: string;
  placeholder: string;
  icon: string;
  onChange: (value: string) => void;
}

const Input = ({ type, placeholder, icon, onChange }: InputProps) => {
  const [show, setShow] = useState(false);

  const onClick = useCallback(() => {
    setShow((prev) => !prev);
  }, []);

  return (
    <div className={styles.inputContainer}>
      <img className={styles.inputIcon} src={icon} alt={placeholder} />
      <input
        className={type === "password" ? styles.inputBox : styles.inputBoxPad}
        type={show ? "text" : type}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {type === "password" && (
        <img
          className={styles.togglePassword}
          onClick={onClick}
          src={eyeIcon}
          alt={"Toggle Visibility Password"}
        />
      )}
    </div>
  );
};

export default Input;
