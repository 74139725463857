const Scene179: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa1",
  sfx: "",
  variableCheck: "306",
  nextTrue: 180,
  nextFalse: 181,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Kamu harus terus melakukan latihan dan segera menerapkan ilmu yang sudah dipelajari! ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Eyang akan memberikan tambahan poin XP jika kamu lolos latihan ini. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Jawab pertanyaan Eyang! Jelaskan mana yang termasuk kekuatan (strength)? Silahkan pilih 4 ya!",
      option: {
        name: "SelectPut",
        param: {
          title: "Kekuatan",
          options: [
            "Menguasai jurus Matahari Tenggelam",
            "Kuda-kuda yang kokoh",
            "Kecepatan dalam bergerak",
            "Daya konsentrasi yang tinggi",
            "Terlalu percaya diri",
            "Gudang dengan persenjataan yang lengkap",
            "Bantuan sesama pendekar",
            "Kitab ajaran silat yang sangat mumpuni",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "306",
        },
      },
    },
  ],
};

export default Scene179;
