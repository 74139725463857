const Scene234: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmChill3",
  sfx: "",
  next: 235,
  dialogues: [
    {
      characters: ["Humaira (normal)"],
      line: "Pasti sekarang sudah lebih segar kan! saatnya kita post test!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene234;
