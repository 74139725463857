const Scene174: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 175,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Ya.. ya.. ya.. Seorang pendekar bisa memenangkan pertandingan jika tahu apa kekuatan dan kelemahan yang kita miliki dan lawan. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ingat juga, kamu harus tahu bagaimana aturan main yang ada, bisa melihat peluang, sehingga bisa menyusun strategi untuk MENANG sebuah pertandingan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Latih-tanding tadi bisa saja terjadi dalam pertempuran melawan musuh. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Oleh karena itu, kamu harus tahu dan mengenali apa kekuatan dan kelemahan baik dalam diri sendiri maupun dari luar diri. Supaya kamu memiliki strategi untuk mencapai tujuan.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene174;
