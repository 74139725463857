const Scene011: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Yang dimaksud dengan tujuan pribadi adalah sesuatu yang ....",
      option: {
        name: "Option",
        param: {
          options: [
            "Secara sadar dan sengaja diinginkan",
            "Tidak tahu",
            "Mengikuti arahan orangtua",
            "Mengikuti ajakan teman",
            "Sesuai dengan keinginan perusahaan",
          ],
          values: [true, false, false, false, false],
          variableCode: "201",
          select: 1,
        },
      },
    },
  ],
};

export default Scene011;
