const pre_test_codes = [
  "101",
  "102",
  "103",
  "104",
  "105",
  "201",
  "202",
  "203",
  "204",
  "205",
  "301",
  "302",
  "303",
  "304",
  "305",
];
const practice_materi_1_codes = ["106", "107", "108", "109"];
const prove_materi_1_codes = ["110", "111", "112", "113"];
const practice_materi_2_codes = ["206", "207", "208", "209"];
const prove_materi_2_codes = ["210", "211", "212", "213"];
const practice_materi_3_codes = ["306", "307", "308", "309"];
const prove_materi_3_codes = [
  "310",
  "311",
  "312",
  "313",
  "314",
  "315",
  "316",
  "317",
];
const post_test_codes = [
  "114",
  "115",
  "116",
  "117",
  "118",
  "214",
  "215",
  "216",
  "217",
  "218",
  "318",
  "319",
  "320",
  "321",
  "322",
];
const action_plan_codes = [
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "411",
  "412",
  "413",
  "414",
  "415",
  "416",
  "417",
  "418",
  "419",
  "420",
  "421",
  "422",
  "423",
  "424",
  "425",
  "426",
  "427",
  "428",
];
const rating_codes = ["501", "502"];
const remedial_materi_1_codes = [
  ...prove_materi_1_codes,
  ...post_test_codes.slice(0, 5),
];
const remedial_materi_2_codes = [
  ...prove_materi_2_codes,
  ...post_test_codes.slice(5, 10),
];
const remedial_materi_3_codes = [
  ...prove_materi_3_codes,
  post_test_codes[14],
  ...post_test_codes.slice(10, 14),
];

export {
  pre_test_codes,
  practice_materi_1_codes,
  prove_materi_1_codes,
  practice_materi_2_codes,
  prove_materi_2_codes,
  practice_materi_3_codes,
  prove_materi_3_codes,
  post_test_codes,
  action_plan_codes,
  rating_codes,
  remedial_materi_1_codes,
  remedial_materi_2_codes,
  remedial_materi_3_codes,
};
