const Scene026: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Sebelum mengalahkan siluman tamak, kamu harus berhasil melewati setiap tingkatan dalam dunia pendekar",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ada beberapa tingkatan dalam pendekar dan kenaikan tingkatan dinilai berdasarkan XP yang dimiliki... Pertama, Pendekar Muda. Kamu diharuskan memperoleh 50 XP. Kedua, Pendekar Madya. kamu harus memperoleh 100 XP.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Misi utamamu adalah menjadi pendekar Madya",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene026;
