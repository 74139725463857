import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { backgrounds, bgms, sfxs, characters } from "../assets";
import { showNextButtonAtom, variablesAtom } from "../stores";
import { checkValues } from "../utils";
import ls from "localstorage-slim";
import { variableInjection } from "../utils/ui";
import { useCheckpoint, useXP } from ".";
import useRating from "./useRating";

function useSceneManager(scenes: { [key: number]: Scene }) {
  const [sceneNumber, setSceneNumber] = useState(0);
  const [dialogueNumber, setDialogueNumber] = useState(0);
  const [, setShowNextButton] = useAtom(showNextButtonAtom);
  const [variables] = useAtom(variablesAtom);
  const { addXp } = useXP();
  useCheckpoint(sceneNumber);
  useRating(sceneNumber);

  useEffect(() => {
    if (sceneNumber !== 0) {
      ls.set("lastScene", sceneNumber);
    }
  }, [sceneNumber]);

  const type = useMemo(() => {
    return scenes[sceneNumber].type;
  }, [sceneNumber, scenes]);

  const background = useMemo(() => {
    if (scenes[sceneNumber].background.includes(".mp4")) {
      return scenes[sceneNumber].background;
    }
    return backgrounds[scenes[sceneNumber].background];
  }, [sceneNumber, scenes]);

  const bgm = useMemo(() => {
    if (scenes[sceneNumber].bgm === "[continue]") {
      return scenes[sceneNumber].bgm;
    }
    return bgms[scenes[sceneNumber].bgm];
  }, [sceneNumber, scenes]);

  const sfx = useMemo(() => {
    return sfxs[scenes[sceneNumber].sfx];
  }, [sceneNumber, scenes]);

  const transition = useMemo(() => {
    return scenes[sceneNumber].transition;
  }, [sceneNumber, scenes]);

  const characterNames = useMemo(() => {
    if (scenes[sceneNumber].type !== "Scene") {
      return [];
    }

    return scenes[sceneNumber].dialogues[dialogueNumber].characters.map(
      (char) => {
        let splitName = char.split(" ");
        if (char.includes("(") && char.includes(")")) {
          splitName.pop();
        }
        return splitName
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(" ");
      }
    );
  }, [dialogueNumber, sceneNumber, scenes]);

  const characterImages = useMemo(() => {
    if (scenes[sceneNumber].type !== "Scene") {
      return [];
    }

    return scenes[sceneNumber].dialogues[dialogueNumber].characters.map(
      (char) => characters[char]
    );
  }, [dialogueNumber, sceneNumber, scenes]);

  const line = useMemo(() => {
    if (
      scenes[sceneNumber].type !== "Scene" &&
      scenes[sceneNumber].type !== "Transition"
    ) {
      return "";
    }

    return variableInjection(
      scenes[sceneNumber].dialogues[dialogueNumber].line,
      variables
    );
  }, [dialogueNumber, sceneNumber, scenes, variables]);

  const option = useMemo(() => {
    if (scenes[sceneNumber].type !== "Scene") {
      return { name: null };
    }

    const op = scenes[sceneNumber].dialogues[dialogueNumber].option;
    if (op.param && "options" in op.param) {
      const options = op.param.options.map((op: string) => {
        return variableInjection(op, variables);
      });
      op.param.options = options;
    }
    return op;
  }, [dialogueNumber, sceneNumber, scenes, variables]);

  const next = useCallback(async () => {
    setShowNextButton(false);

    if (dialogueNumber + 1 < scenes[sceneNumber].dialogues.length) {
      setDialogueNumber((num) => num + 1);
    } else {
      setDialogueNumber(0);

      // Next Scene Condition
      const variable = variables["targetAwal"] as Answer;
      const targetAwal = parseInt(variable.text);
      if (sceneNumber === 33 || sceneNumber === 34 || sceneNumber === 35) {
        if (targetAwal === 20) {
          setSceneNumber(35);
        } else if (targetAwal === 60) {
          setSceneNumber(34);
        } else {
          setSceneNumber(36);
        }
      } else if (sceneNumber === 40) {
        const variable = variables["ulangiLari"] as Answer;
        const ulangiLari = parseInt(variable.text);
        if (ulangiLari) {
          setSceneNumber(41);
        } else {
          setSceneNumber(42);
        }
      } else if (sceneNumber === 43 || sceneNumber === 45) {
        const variable = variables["targetBaru"] as Answer;
        const targetBaru = parseInt(variable.text);
        if (targetBaru) {
          setSceneNumber(44);
        } else {
          setSceneNumber(45);
        }
      } else if (sceneNumber === 70 || sceneNumber === 85) {
        const values = [
          checkValues(variables["106"]),
          checkValues(variables["107"]),
          checkValues(variables["108"]),
          checkValues(variables["109"]),
        ];
        setSceneNumber(
          values.filter((val) => val).length >= 3
            ? sceneNumber + 1
            : sceneNumber + 2
        );
      } else if (sceneNumber === 122 || sceneNumber === 138) {
        const values = [
          checkValues(variables["206"]),
          checkValues(variables["207"]),
          checkValues(variables["208"]),
          checkValues(variables["209"]),
        ];
        setSceneNumber(
          values.filter((val) => val).length >= 3
            ? sceneNumber + 1
            : sceneNumber + 2
        );
      } else if (sceneNumber === 191 || sceneNumber === 206) {
        const values = [
          checkValues(variables["306"]),
          checkValues(variables["307"]),
          checkValues(variables["308"]),
          checkValues(variables["309"]),
        ];
        setSceneNumber(
          values.filter((val) => val).length >= 3
            ? sceneNumber + 1
            : sceneNumber + 2
        );
      } else if (sceneNumber === 265) {
        const values1 = [
          checkValues(variables["114"]),
          checkValues(variables["115"]),
          checkValues(variables["116"]),
          checkValues(variables["117"]),
          checkValues(variables["118"]),
        ];
        const values2 = [
          checkValues(variables["110"]),
          checkValues(variables["111"]),
          checkValues(variables["112"]),
          checkValues(variables["113"]),
        ];
        const nextTrue =
          values1.filter((val) => val).length >= 4 &&
          values2.filter((val) => val).length >= 3;
        setSceneNumber(nextTrue ? 292 : 293);
      } else if (sceneNumber === 276) {
        const values1 = [
          checkValues(variables["214"]),
          checkValues(variables["215"]),
          checkValues(variables["216"]),
          checkValues(variables["217"]),
          checkValues(variables["218"]),
        ];
        const values2 = [
          checkValues(variables["210"]),
          checkValues(variables["211"]),
          checkValues(variables["212"]),
          checkValues(variables["213"]),
        ];
        const nextTrue =
          values1.filter((val) => val).length >= 4 &&
          values2.filter((val) => val).length >= 3;
        setSceneNumber(nextTrue ? 295 : 296);
      } else if (sceneNumber === 291) {
        const values1 = [
          checkValues(variables["318"]),
          checkValues(variables["319"]),
          checkValues(variables["320"]),
          checkValues(variables["321"]),
          checkValues(variables["322"]),
        ];
        const values2 = [
          checkValues(variables["310"]),
          checkValues(variables["311"]),
          checkValues(variables["312"]),
          checkValues(variables["313"]),
          checkValues(variables["314"]),
          checkValues(variables["315"]),
          checkValues(variables["316"]),
          checkValues(variables["317"]),
        ];
        const nextTrue =
          values1.filter((val) => val).length >= 4 &&
          values2.filter((val) => val).length >= 6;
        setSceneNumber(nextTrue ? 298 : 299);
      } else if ("variableCheck" in scenes[sceneNumber]) {
        const variableCheck = scenes[sceneNumber].variableCheck as string;
        setSceneNumber(
          checkValues(variables[variableCheck])
            ? (scenes[sceneNumber].nextTrue as number)
            : (scenes[sceneNumber].nextFalse as number)
        );
      } else {
        const nextScene = scenes[sceneNumber].next ?? false;
        if (nextScene) {
          setSceneNumber(nextScene);
        } else {
          setSceneNumber((num) => num + 1);
        }
      }
    }

    const sceneXpCondition = [153, 154, 155, 156];
    const dialgoueXpCondition = [1, 0, 0, 0];
    if (
      scenes[sceneNumber].dialogues.length > 0 &&
      "xp" in scenes[sceneNumber].dialogues[dialogueNumber]
    ) {
      const index = sceneXpCondition.findIndex((val) => val === sceneNumber);
      if (index !== -1) {
        const sceneXp = sceneXpCondition[index];
        const dialogueXp = dialgoueXpCondition[index];
        if (sceneNumber === sceneXp && dialogueNumber === dialogueXp) {
          const variable = variables[`Scene${sceneNumber}`] as Answer;
          if (variable.value) {
            addXp(scenes[sceneNumber].dialogues[dialogueNumber].xp as number);
          }
        }
      } else {
        addXp(scenes[sceneNumber].dialogues[dialogueNumber].xp as number);
      }
    }
  }, [
    addXp,
    dialogueNumber,
    sceneNumber,
    scenes,
    setShowNextButton,
    variables,
  ]);

  const nextScene = useCallback((index: number) => {
    setSceneNumber(index);
  }, []);

  const nextDialogue = useCallback((index: number) => {
    setDialogueNumber(index);
  }, []);

  const nextUjiLari = useCallback(
    (speed: number) => {
      const targetAwal = variables["targetAwal"] as Answer;
      if (sceneNumber === 37) {
        if (speed < parseInt(targetAwal.text)) {
          setSceneNumber(38);
        } else {
          setSceneNumber(39);
        }
      } else if (sceneNumber === 46) {
        let targetBaru = variables["targetBaru"] as Answer;
        if (speed < parseInt(targetBaru.text)) {
          setSceneNumber(47);
        } else {
          setSceneNumber(48);
        }
      } else {
        setSceneNumber((scene) => scene + 1);
      }
    },
    [sceneNumber, variables]
  );

  const nextBattleCard = useCallback(
    (playerWin: boolean) => {
      if (sceneNumber === 160) {
        setSceneNumber(161);
      } else {
        setSceneNumber(playerWin ? sceneNumber + 1 : sceneNumber + 2);
      }
    },
    [sceneNumber]
  );

  return {
    sceneNumber,
    dialogueNumber,
    type,
    background,
    bgm,
    sfx,
    transition,
    characterNames,
    characterImages,
    line,
    option,
    next,
    nextScene,
    nextDialogue,
    nextUjiLari,
    nextBattleCard,
  };
}

export default useSceneManager;
