const Scene203: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "309",
  nextTrue: 204,
  nextFalse: 205,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Pilih 4 pernyataan yang termasuk ANCAMAN? ",
      option: {
        name: "SelectPut",
        param: {
          title: "Ancaman",
          options: [
            "Siluman Tamak yang semakin banyak pendukungnya",
            "Ilmu Siluman Tamak yang sudah tinggi",
            "Pendekar lain yang tidak mau kerjasama",
            "Wabah penyakit di masa pancaroba",
            "Kurang mampu memahami strategi lawan",
            "Mata yang kurang awas di malam hari",
            "Mudah mengantuk kalau lelah seharian",
            "Rasa khawatir yang berlebihan",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "309",
        },
      },
    },
  ],
};

export default Scene203;
