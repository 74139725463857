const Scene154: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 155,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Lalu, pertanyaan selanjutnya, apakah kepanjangan AMPUH?",
      xp: 2,
      option: {
        name: "Option",
        param: {
          options: [
            "Afeksi, Manfaat, Peluang, Urgensi dan Harmoni",
            "Aksi, Martabat, Penampilan, Usaha, Harmoni",
            "Ancaman, Metode, Peluang, Usaha, Hasil",
            "Aktivitas, Manajemen, Partner, Upaya, Hasil",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene154",
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Yang ini jangan sampai lupa.. AMPUH adalah kepanjangan dari Afeksi, Manfaat, Peluang, Urgensi dan Harmoni",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene154;
