import materi from "../assets/videos/materi1a.mp4";

const Scene054: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  variableCheck: "Scene054",
  nextTrue: 56,
  nextFalse: 55,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Eyang sudah menyiapkan materi video. Lihat, dengar dan pelajari baik-baik. Akan ada pertanyaan setelah materi diberikan.",
      option: {
        name: "Video",
        param: {
          src: materi,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Baik, kamu sudah menonton video tadi. Sekarang jawab pertanyaan, Eyang. Apa itu pribadi prestatif?",
      option: {
        name: "Option",
        param: {
          options: [
            "Pribadi yang ingin selalu maju, bertanggungjawab secara pribadi, visioner dan mau menerima umpan balik dari orang lain",
            "Pribadi yang selalu ingin menjadi nomor 1, ingin selalu berhasil, dan bisa memberi masukan",
            "Pribadi yang selalu bisa mengalahkan orang lain, selalu ingin menang dalam persaingan, yakin dengan pendapat sendiri",
            "Pribadi yang memiliki banyak kekuasaan, sehingga mampu membuat orang lain tunduk dan mengakui kebesaran dirinya",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene054",
          variableIndex: 0,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Pertanyaan berikutnya: Siapa saja contoh pribadi prestatif? Hayo jawaaab",
      option: {
        name: "Option",
        param: {
          options: [
            "Susi Susanti",
            "Jaka Sembung",
            "William Tanujaya",
            "Wiro Sableng",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene054",
          variableIndex: 1,
        },
      },
    },
  ],
};

export default Scene054;
