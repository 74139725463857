import materi from "../assets/videos/materi1b.mp4";

const Scene056: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  variableCheck: "Scene056",
  nextTrue: 58,
  nextFalse: 57,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Kamu sudah memperhatikan video bagian pertama dengan baik. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo kita lanjutkan menonton materi mengenai menjadi pribadi prestatif. Ingat kamu harus mencermati video dengan baik!",
      option: {
        name: "Video",
        param: {
          src: materi,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Baik, menonton video sudah selesai. Jawab pertanyaan Eyang. Yang manakah yang BUKAN ciri pribadi prestatif?",
      option: {
        name: "Option",
        param: {
          options: [
            "Bersedia meminta bantuan orang lain",
            "Mengetahui kekuatan dan kelemahan orang lain",
            "Terbuka terhadap masukan orang lain",
            "Memiliki tujuan untuk menjadi lebih baik",
          ],
          values: [false, true, false, false],
          select: 1,
          variableCode: "Scene056",
          variableIndex: 0,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Pertanyaan berikutnya. Apa saja cara untuk menjadi pribadi prestatif?",
      option: {
        name: "Option",
        param: {
          options: [
            "Selalu bepikir untuk melakukan inovasi",
            "Berusaha untuk selalu menang lomba dengan segala macam cara",
            "Banyak berkhayal yang indah dan romantis",
            "Berusaha mendapatkan pengakuan dari orang lain",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene056",
          variableIndex: 1,
        },
      },
    },
  ],
};

export default Scene056;
