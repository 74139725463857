const Scene097: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  next: 98,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Assalamualaikum. Selamat datang kembali calon pendekar.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Apa kamu sudah siap melanjutkan pelajaran berikutnya?",
      option: {
        name: "Option",
        param: {
          options: ["Sangat siap!", "Ya, sudah siap!"],
          values: [true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Eyang ragu apakah materi yang sudah Eyang ajarkan masih diingat. Eyang mau uji dulu. Sekarang silahkan bersiap. Ucapkan, Bismillah.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Eyang akan menunjukkan beberapa cerita pendekar. Kamu pilih, pendekar mana yang prestatif. Geser ke kanan jika menurutmu pendekar ini prestatif dan geser ke kiri jika menurutmu pendekar ini tidak prestatif.  Waktumu hanya 40 detik sehingga baca dan putuskanlah dengan cepat!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene097;
