import lulus from "../assets/contents/lulusPendekarMuda.jpg";

const Scene123: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 141,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah! Kamu lulus! Selamat menjadi pendekar muda!",
      xp: 25,
      option: {
        name: "Zoom",
        param: {
          contents: [lulus],
        },
      },
    },
  ],
};

export default Scene123;
