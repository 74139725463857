const Scene231: Scene = {
  type: "Transition",
  background: "class",
  transition: "dark",
  bgm: "bgmGame1",
  sfx: "",
  next: 232,
  dialogues: [
    {
      characters: [""],
      line: "Menuju tantangan selanjutnya...",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene231;
