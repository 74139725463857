import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { Button } from "../components";
import { InputText } from "../components/mechanics";
import { variablesAtom } from "../stores";
import { page_1, page_2 } from "../stores/action-plan";
import styles from "../styles/action-plan.module.css";

interface ActionPlanProps {
  onFinish: () => void;
}

const ActionPlan = ({ onFinish }: ActionPlanProps) => {
  const [page, setPage] = useState(1);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [variables] = useAtom(variablesAtom);

  useEffect(() => {
    let blank = false;
    for (
      let index = page === 1 ? 1 : 15;
      index <= (page === 1 ? 14 : 28);
      index++
    ) {
      const variable = variables[
        "4" + String(index).padStart(2, "0")
      ] as Answer;
      if (variable.text === "") {
        blank = true;
        break;
      }
    }
    setNextButtonDisabled(blank);
  }, [page, variables]);

  if (page === 1) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {page_1.map((action_plan) => {
            return (
              <div key={action_plan.title}>
                <div className={styles.title}>{action_plan.title}</div>
                <div className={styles.subtitle}>{action_plan.subtitle}</div>
                {action_plan.questions.map((question, index) => {
                  return (
                    <div key={page + question} className={styles.form}>
                      <div className={styles.question}>{question}</div>
                      <InputText
                        type="short"
                        variableCode={action_plan.variableCodes[index]}
                        maxLength={action_plan.maxLength[index]}
                        placeholder={`Maksimum ${action_plan.maxLength[index]} karakter`}
                        inputClass={styles.input}
                      />
                    </div>
                  );
                })}
                {action_plan.note && (
                  <div className={styles.note}>{action_plan.note}</div>
                )}
              </div>
            );
          })}
          <div className={styles.nextButton}>
            <Button
              type="primary"
              text="Next"
              onClick={() => {
                setPage(2);
                window.scrollTo(0, 0);
              }}
              disabled={nextButtonDisabled}
            />
          </div>
          <div className={styles.page}>{page} / 2</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {page_2.map((action_plan) => {
            return (
              <div key={action_plan.title}>
                <div className={styles.title}>{action_plan.title}</div>
                <div className={styles.subtitle}>{action_plan.subtitle}</div>
                {action_plan.questions.map((question, index) => {
                  return (
                    <div key={question} className={styles.form}>
                      <div className={styles.question}>{question}</div>
                      <InputText
                        type="short"
                        variableCode={action_plan.variableCodes[index]}
                        maxLength={action_plan.maxLength[index]}
                        placeholder={`Maksimum ${action_plan.maxLength[index]} karakter`}
                        inputClass={styles.input}
                      />
                    </div>
                  );
                })}
                {action_plan.note && (
                  <div className={styles.note}>{action_plan.note}</div>
                )}
              </div>
            );
          })}
          <div className={styles.nextButton}>
            <Button
              type="primary"
              text="Next"
              onClick={onFinish}
              disabled={nextButtonDisabled}
            />
          </div>
          <div className={styles.page}>{page} / 2</div>
        </div>
      </div>
    );
  }
};

export default ActionPlan;
