import { useAtom } from "jotai";
import { shuffle } from "lodash";
import { useEffect, useState } from "react";
import { showNextButtonAtom, variablesAtom } from "../../stores";
import styles from "../../styles/select-put.module.css";

interface SelectPutProps {
  title: string;
  options: string[];
  values: boolean[];
  select: number;
  containerClass?: string;
  optionContainerClass?: string;
  optionClass?: string;
  boxClass?: string;
  titleClass?: string;
  variableCode?: string;
}

interface Choice {
  option: string;
  value: boolean;
  selected: boolean;
  index: number;
}

const SelectPut = ({
  title,
  options,
  values,
  select,
  containerClass,
  optionContainerClass,
  optionClass,
  boxClass,
  titleClass,
  variableCode,
}: SelectPutProps) => {
  const [choices, setChoices] = useState<Choice[]>(() => {
    const options_ = shuffle(
      options.map((opt, index) => {
        return {
          option: opt,
          value: values[index],
          selected: false,
          index: index,
        };
      })
    );
    return options_;
  });
  const [, setShowNextButton] = useAtom(showNextButtonAtom);
  const [, setVariables] = useAtom(variablesAtom);

  useEffect(() => {
    const selectedChoice = choices.filter((cho) => cho.selected);
    setShowNextButton(selectedChoice.length === select);
    if (variableCode && selectedChoice.length === select) {
      setVariables((variables) => {
        const newAnswer: Answer = {
          index: -1,
          value: selectedChoice.every((choice) => choice.value),
          text: selectedChoice.map((choice) => choice.option).join(","),
        };
        return {
          ...variables,
          [variableCode]: newAnswer,
        };
      });
    }
  }, [choices, select, setShowNextButton, setVariables, variableCode]);

  function onClickOption(index: number) {
    let choices_ = [...choices];
    if (choices.filter((choice) => choice.selected).length < select) {
      choices_ = choices_.map((cho) => {
        if (cho.index === index) {
          return {
            ...cho,
            selected: !cho.selected,
          };
        }
        return cho;
      });
      // choices_[index].selected = !choices_[index].selected;
    } else {
      choices_ = choices_.map((cho) => {
        if (cho.index === index) {
          return {
            ...cho,
            selected: false,
          };
        }
        return cho;
      });
      // choices_[index].selected = false;
    }
    setChoices(choices_);
  }

  return (
    <div className={containerClass ?? styles.selectPutContainer}>
      <div className={boxClass ?? styles.selectPutBox}>
        <div className={titleClass ?? styles.selectPutTitle}>
          {title}
          {` (${choices.filter((choice) => choice.selected).length}/${select})`}
        </div>
        {choices
          .filter((choice) => choice.selected)
          .map((choice) => {
            return (
              <span
                key={choice.option}
                className={optionClass ?? styles.selectPutOption}
                onClick={() => onClickOption(choice.index)}
              >
                {choice.option}
              </span>
            );
          })}
      </div>
      <div className={optionContainerClass ?? styles.selectPutOptionContainer}>
        {choices
          .filter((choice) => !choice.selected)
          .map((choice) => {
            return (
              <span
                key={choice.option}
                className={optionClass ?? styles.selectPutOption}
                onClick={() => onClickOption(choice.index)}
              >
                {choice.option}
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default SelectPut;
