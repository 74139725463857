import materi from "../assets/contents/materi1practice3.jpg";

const Scene064: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "108",
  nextTrue: 65,
  nextFalse: 66,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Berikutnya adalah mengenai pendekar Jagawana. Baca dan pahami.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Pertanyannya : Apakah Jagawana melakukan cara yang tepat untuk menjadi pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Ya caranya sudah tepat",
            "Belum",
            "Belum tentu",
            "Tidak tahu",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "108",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Selalu berpikir untuk maju dan lebih baik",
            "Tidak berani mengambil risiko dan tidak cepat puas",
            "Membahayakan diri sendiri",
            "Karena berpikir menang kalah",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "108",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene064;
