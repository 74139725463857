import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { showNextButtonAtom, variablesAtom } from "../../stores";
import star from "../../assets/buttons/star.png";
import starActive from "../../assets/buttons/star-active.png";
import styles from "../../styles/rating.module.css";

interface RatingProps {
  variableCode?: string;
}

const Rating = ({ variableCode }: RatingProps) => {
  const [rating, setRating] = useState(0);
  const [, setShowNextButton] = useAtom(showNextButtonAtom);
  const [, setVariables] = useAtom(variablesAtom);

  useEffect(() => {
    if (rating !== 0) {
      setShowNextButton(true);
      if (variableCode) {
        setVariables((variables) => {
          return {
            ...variables,
            [variableCode]: {
              index: -1,
              value: true,
              text: rating.toString(),
            },
          };
        });
      }
    }
  }, [rating, setShowNextButton, setVariables, variableCode]);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <img
          src={rating >= 1 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(1)}
        />
        <img
          src={rating >= 2 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(2)}
        />
        <img
          src={rating >= 3 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(3)}
        />
        <img
          src={rating >= 4 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(4)}
        />
        <img
          src={rating >= 5 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(5)}
        />
        <img
          src={rating >= 6 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(6)}
        />
        <img
          src={rating >= 7 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(7)}
        />
        <img
          src={rating >= 8 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(8)}
        />
        <img
          src={rating >= 9 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(9)}
        />
        <img
          src={rating >= 10 ? starActive : star}
          alt="Rating"
          className={styles.star}
          onClick={() => setRating(10)}
        />
        {/* <span
          onClick={() => setRating(1)}
          style={{ color: rating >= 1 ? "red" : "black" }}
        >
          1
        </span>
        <span
          onClick={() => setRating(2)}
          style={{ color: rating >= 2 ? "red" : "black" }}
        >
          2
        </span>
        <span
          onClick={() => setRating(3)}
          style={{ color: rating >= 3 ? "red" : "black" }}
        >
          3
        </span>
        <span
          onClick={() => setRating(4)}
          style={{ color: rating >= 4 ? "red" : "black" }}
        >
          4
        </span>
        <span
          onClick={() => setRating(5)}
          style={{ color: rating >= 5 ? "red" : "black" }}
        >
          5
        </span> */}
      </div>
    </div>
  );
};

export default Rating;
