import manfaatBelajar from "../assets/contents/ManfaatBelajar.jpg";

const Scene004: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Tugasmu adalah mengikuti seluruh aktivitas yang diberikan dengan sebaik-baiknya agar bisa merasakan manfaatnya",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Apa sih manfaatnya bagi kakak sebagai CDP untuk mengikuti program ini?",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Kakak akan belajar untuk menjadi seorang CDP yang profesional, berkualitas, dan mampu mengelola diri sendiri maupun Ibu Idamaan dengan lebih baik lagi!",
      option: {
        name: "Zoom",
        param: {
          contents: [manfaatBelajar],
        },
      },
    },
  ],
};

export default Scene004;
