const Scene244: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 245,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: 'Kriteria yang belum terpenuhi dalam tujuan berikut ini adalah : "Memiliki 20 Ibu Idamaan dan 40 Sahabat AMAAN" ',
      option: {
        name: "Option",
        param: {
          options: ["Berbatas waktu", "Terukur", "Spesifik", "Hasil"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "217",
        },
      },
    },
  ],
};

export default Scene244;
