const Scene040: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Apakah kamu masih ingin mencoba UJI KECEPATAN LARI lagi? ",
      option: {
        name: "Option",
        param: {
          options: ["Mau", "Tidak"],
          values: [true, false],
          select: 1,
          variableCode: "ulangiLari",
        },
      },
    },
  ],
};

export default Scene040;
