const Scene169: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmFestiveDesa",
  sfx: "sfxChampion",
  next: 171,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah! Bagus! Selamat!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene169;
