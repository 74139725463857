const Scene065: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 67,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Betul! Pertahankan fokus dan niat belajarmu.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, kita lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene065;
