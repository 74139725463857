const Scene006: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmClassic2",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Seseorang yang selalu ingin memperoleh hasil yang lebih baik dari sebelumnya, disebut ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Prestatif",
            "Ambisius",
            "Tidak mau kalah",
            "Tidak tahu",
            "Kompetitif",
          ],
          values: [true, false, false, false, false],
          variableCode: "101",
          select: 1,
        },
      },
    },
  ],
};

export default Scene006;
