const Scene085: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Baik. Latihan tahap 2 sudah selesai. Kamu sudah menjawab 4 pertanyaan mengenai calon pendekar prestatif. Sekarang saatnya pengumuman nilai.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene085;
