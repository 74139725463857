import materi from "../assets/contents/materi2Practice3jawaban.jpg";

const Scene118: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 119,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Salah dong. Bagaimana sih. Tujuan yang kamu pilih tidak memenuhi kriteria terukur! Ayo, perhatikan ini jawaban yang tepat.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene118;
