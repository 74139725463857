import materi from "../assets/contents/materi1practice1.jpg";

const Scene058: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa2",
  sfx: "sfxChampion",
  variableCheck: "106",
  nextTrue: 59,
  nextFalse: 60,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah! Bagus! Kamu berhasil menjawab pertanyaan. Eyang akan memberikan tambahan 5 XP.",
      xp: 5,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Kamu harus terus melakukan latihan dan segera terapkan ilmu yang sudah dipelajari.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Ayo, kita lanjutkan pelajaran berikutnya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Eyang akan memberikan tambahan poin XP. Dan kamu harus terus melakukan latihan dan segera menerapkan ilmu yang sudah dipelajari.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Baik, Ayo kita lanjutkan ke latihan soal. Silahkan membaca profil pendekar berikut",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Berdasarkan bacaan disamping, apakah Jagawana tergolong pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Bukan", "Belum tentu", "Tidak tahu"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "106",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasan kamu menjawab itu?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Selalu mengalami peningkatan kemampuan setelah bertanding",
            "Tidak mau belajar dan berlatih dari kekalahan setelah bertanding",
            "Terlalu percaya diri",
            "Tidak punya banyak teman",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "106",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene058;
