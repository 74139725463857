const Scene158: Scene = {
  type: "Transition",
  background: "lapangan",
  transition: "dark",
  bgm: "bgmFestiveDesa",
  sfx: "",
  dialogues: [
    {
      characters: [""],
      line: "Menuju Lapangan Padepokan Selaras",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene158;
