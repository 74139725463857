const Scene190: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 191,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullah. Perhatikan dan fokus maka kemenangan akan diraih! Jawabannya adalah ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Ancaman atau threat merupakan hal-hal di luar diri yang menghambat pencapaian misi pribadi.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Sebagai pendekar, ini merupakan hal-hal yang berpotensi mengancam kamu untuk menjadi pendekar terbaik di desa Kepala Desa yang membuat aturan sesuka hatinya, pendekar lain yang ilmunya lebih tinggi, perguruan silat lain, dan persenjataan perguruan lain yang lebih lengkap",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene190;
