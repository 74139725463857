const Scene038: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "sfxChampion",
  next: 40,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "BAGUS! HEBAT! Alhamdulillah! ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Kamu sudah berhasil mencapai target yang sudah kamu tentukan dan menyamai standar pendekar AMAAN. Saya berikan 2 xp! Ini hasil waktu larimu $[speedRondeDua] detik",
      xp: 2,
      option: {
        name: null,
      },
    },
  ],
};

export default Scene038;
