const Scene127: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 129,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Benar. Tujuan Marutama ada yang tidak selaras, sehingga tidak memenuhi kriteria harmonis.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene127;
