import { useCallback, useEffect, useRef } from "react";
import styles from "../styles/audio.module.css";
import { ReactComponent as AudioOnImage } from "../assets/buttons/audio-on.svg";
import { ReactComponent as AudioOffImage } from "../assets/buttons/audio-off.svg";
import { mutedAtom } from "../stores";
import { useAtom } from "jotai";

interface AudioProps {
  bgmSrc: string;
  sfxSrc: string;
  silent?: boolean;
}

const Audio = ({ bgmSrc, sfxSrc, silent }: AudioProps) => {
  // const [muted, setMuted] = useState(false);
  const [muted, setMuted] = useAtom(mutedAtom);
  const bgm = useRef<HTMLAudioElement>(null);
  const sfx = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (bgm.current) {
      bgm.current.volume = silent ? 0.0 : 0.34;
    }
    if (sfx.current) {
      sfx.current.volume = silent ? 0.0 : 0.67;
    }
  }, [silent]);

  useEffect(() => {
    if (sfxSrc && sfx.current && !muted) {
      sfx.current.load();
      sfx.current.play();
    }
    if (bgmSrc && bgm.current && !muted) {
      if (bgmSrc !== "[continue]") {
        bgm.current.load();
        bgm.current.play();
      }
    }
  }, [sfxSrc, bgmSrc, muted]);

  const onToggleMuted = useCallback(() => {
    setMuted((m) => !m);
  }, [setMuted]);

  return (
    <>
      {muted ? (
        <AudioOffImage className={styles.buttonAudio} onClick={onToggleMuted} />
      ) : (
        <AudioOnImage className={styles.buttonAudio} onClick={onToggleMuted} />
      )}
      <audio ref={bgm} loop muted={muted}>
        <source src={bgmSrc} type="audio/mpeg" />
      </audio>
      <audio ref={sfx} muted={muted}>
        <source src={sfxSrc} type="audio/mpeg" />
      </audio>
    </>
  );
};

export default Audio;

