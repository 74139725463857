const Scene107: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 109,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus. Alhamdulillah. Eyang beri kamu tambahan xp 5!",
      xp: 5,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Sekarang kamu harus langsung latihan untuk membuktikan apakah sudah bisa menerapkan ilmu yang kamu pelajari melalui video sebelumnya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Sudah siap untuk latihan?",
      option: {
        name: "Option",
        param: {
          options: ["Ya sudah siap!", "Tentu siap dong Eyang Guru."],
          values: [true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene107;
