const Scene216: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 218,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Keren! kakak serius belajarnya, Eyang Guru pasti bangga! Betul jawabannya adalah Strength dan Weakness ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene216;
