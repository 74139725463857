import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import { useAlert } from "react-alert";
import { put_point } from "../services";
import { accessTokenAtom } from "../stores";
import { xpAtom } from "../stores/variable";

const useXP = () => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [xp, setXp] = useAtom(xpAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const alert = useAlert();

  const addXp = useCallback(
    async (addedXp: number) => {
      setIsInProgress(true);
      const { status } = await put_point(accessToken, xp + addedXp);
      if (status === 200) {
        setXp((xp) => xp + addedXp);
        alert.success("XP berhasil ditambahkan");
      } else {
        alert.error("XP gagal ditambahkan");
      }
      setIsInProgress(false);
    },
    [accessToken, alert, setXp, xp]
  );

  return { xp, addXp, isInProgress };
};

export default useXP;
