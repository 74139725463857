const Scene202: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 203,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Jawaban yang benar adalah ajang kompetisi pendekar setiap tahun, ayah angkat yang mendukung, sarana transportasi yang mudah untuk ke kota mengikuti diskusi antar pendekar, dan catatan Eyang Guru tentang kekuatan dan kelemahan Siluman Tamak.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Kamu ingin menang dalam pertarungan kan?! Konsentrasi!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene202;
