const Scene176: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  variableCheck: "Scene176",
  nextTrue: 177,
  nextFalse: 178,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Kamu sudah menyimak video pembelajarannya, sekarang jawab pertanyaan Eyang.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa singkatan SWOT?",
      option: {
        name: "Option",
        param: {
          options: [
            "Strength, Weakness, Opportunity, Threat",
            "Strength, Weakness, Obstacles, Threat",
            "Strong, Weakness, Option, Trick",
            "Strong, Weakness, Obstacles, Trick",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene176",
          variableIndex: 0,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Sekarang, aspek mana yang datangnya dari luar diri? ",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Opportunity", "Weakness", "Trick"],
          values: [false, true, false, false],
          select: 1,
          variableCode: "Scene176",
          variableIndex: 1,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Lalu, aspek mana yang datangnya dari dalam diri? ",
      option: {
        name: "Option",
        param: {
          options: ["Strong", "Opportunity", "Weakness", "Threat"],
          values: [false, false, true, false],
          select: 1,
          variableCode: "Scene176",
          variableIndex: 2,
        },
      },
    },
  ],
};

export default Scene176;
