const Scene057: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  next: 56,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim! Eyang kecewa! Dari 2 pertanyaan tadi, masih ada yang jawabannya salah! Tampaknya kamu kurang menyimak videonya",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Perbaiki sikap jika ingin berhasil. Ayo, fokus, tonton video tadi.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene057;
