const Scene211: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmChill2",
  sfx: "",
  next: 212,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Selamat datang kembali $[fullname]! Bagaimana tadi sesi dengan Eyang Guru?",
      option: {
        name: "Option",
        param: {
          options: [
            "Seru sekali!",
            "Sangat bermanfaat",
            "Masih banyak yang bingung",
          ],
          values: [true, true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Materi tentang personal SWOT bisa juga diterapkan dalam kehidupan sebagai CDP loh.",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Tapi sebelumnya yuk coba diingat kembali materi dari Eyang Guru.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene211;
