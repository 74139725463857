const Scene039: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "sfxLose",
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "HAH! Kamu meleset dari target dan belum sesuai dengan standar pendekar AMAAN. Waktu yang kamu butuhkan untuk berlari adalah $[speedRondeDua] detik",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene039;
