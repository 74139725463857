const Scene247: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 248,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Atasan yang sabar dalam membimbing kita sebagai bawahannya, dalam SWOT termasuk ...",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Opportunity", "Support", "Obligation"],
          values: [false, true, false, false],
          select: 1,
          variableCode: "319",
        },
      },
    },
  ],
};

export default Scene247;
