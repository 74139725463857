import styles from "../styles/background.module.css";
import { velocity } from "../stores/constant";

type BackgroundProps = {
  url: string;
  clickCount: number;
};

const Background = ({ url, clickCount }: BackgroundProps) => {
  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: -velocity * clickCount,
      }}
      className={styles.background}
    ></div>
  );
};

export default Background;
