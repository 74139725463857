import materi from "../assets/contents/materi1prove4.jpg";

const Scene093: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Silahkan dibaca telebih dahulu profil CDP bernama Yuni berikut",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Berdasarkan cerita di samping, apakah Yuni tergolong CDP yang prestatif",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [false, true],
          select: 1,
          variableCode: "113",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Apa alasan kakak menggolongkan Yuni seperti jawaban sebelumnya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Menggunakan cara-cara lama yang terbukti efektif",
            "Memasang target sesuai keinginan perusahaan",
            "Tidak pernah terlambat",
            "Menjaga kinerja sehingga tidak ada angsuran bermasalah",
          ],
          values: [true, true, false, false],
          select: 1,
          variableCode: "113",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene093;
