const Scene049: Scene = {
  type: "Scene",
  background: "padepokanselaras",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Apa yang sudah kamu pelajari dari uji seleksi tadi?",
      option: {
        name: "Option",
        param: {
          options: [
            "Berani menentukan target",
            "Mengenal diri untuk menentukan target",
          ],
          values: [true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Betul, tentang menentukan target.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Sekarang, jawab pertanyaan Eyang, bagaimana cara kita menentukan target yang akan ditetapkan?",
      option: {
        name: "Option",
        param: {
          options: [
            "Seorang pendekar harus berani menetapkan target minimal sesuai standar",
            "Seorang pendekar harus berani menetapkan target di atas standar",
            "Seorang pendekar harus berani menetapkan target yang lebih baik dari hasil yang pernah dicapai",
            "Seorang pendekar harus tahu kemampuan yang dimiliki untuk bisa menentukan target",
          ],
          values: [true, true, true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene049;
