import materi from "../assets/contents/materi1prove1.jpg";

const Scene090: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Silahkan dibaca telebih dahulu profil CDP bernama Rangga berikut",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Berdasarkan cerita di samping, apakah Rangga tergolong CDP yang prestatif",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [true, false],
          select: 1,
          variableCode: "110",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Apa alasan kakak menggolongkan Rangga seperti jawaban sebelumnya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Selalu mencari cara terbaik",
            "Ingin menjalankan tugas sebaik-baiknya",
            "Berani untuk gagal",
            "Sangat ingin menolong orang lain",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "110",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene090;
