const Scene201: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 203,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah. Betul, silahkan lanjutkan latihan",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene201;
