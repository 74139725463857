const Scene128: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 129,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Salah. Konsentrasi. Tentu saja Marutama tidak lolos karena tidak memenuhi kriteria Harmoni. Ada keinginan lain yang bertentangan.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene128;
