const Scene048: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "sfxLose",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Yayaya. Sudah tidak apa-apa, kali ini kamu belum mencapai target yang sudah kamu tetapkan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo kita refleksi kembali atas uji seleksi tadi.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene048;
