const Scene156: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 157,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Kemudian, apakah kamu bisa mengenali aspek apa yang kurang dari tujuan : menjadi pendekar ternama di padepokan Selaras?",
      xp: 2,
      option: {
        name: "Option",
        param: {
          options: ["Berbatas waktu", "Terukur", "Tipe"],
          values: [true, true, false],
          select: 1,
          variableCode: "Scene156",
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Jelas sekali kan, yang tidak ada adalah aspek berbatas waktu dan aspek terukur",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene156;
