import personalEffectiveness1 from "../assets/contents/PersonalEffectiveness1.jpg";

const Scene005: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (normal)"],
      line: "Mari kita mulai petualangan untuk menjadi CDP yang lebih baik lagi, dengan mengikuti game pertama yaitu Personal Effectiveness 1",
      option: {
        name: "Zoom",
        param: {
          contents: [personalEffectiveness1],
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Sebelum memulai petualangan ini, kami ingin tahu dulu pengetahuan awalmu mengenai materi-materi yang akan diberikan nantinya",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Silakan kakak menjawab pertanyaan yang diajukan, dengan cara memilih jawaban yang menurut kakak paling tepat. Jika tidak tahu jawabannya, silakan pilih tidak tahu ya!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Selamat mengerjakan!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene005;
