import materi from "../assets/contents/materi2Practice4jawaban.jpg";

const Scene121: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 122,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfiullah. Ya salah dong. Tujuan yang kamu pilih tidak memenuhi kriteria BTS Hasil! Ayo, perhatikan penjelasannya berikut ini.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene121;
