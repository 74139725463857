import lulusPendekarMuda from "../assets/contents/lulusPendekarMuda.jpg";

const Scene139: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 141,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah. Kamu lolos jadi pendekar muda. Eyang bangga!",
      xp: 25,
      option: {
        name: "Zoom",
        param: {
          contents: [lulusPendekarMuda],
        },
      },
    },
  ],
};

export default Scene139;
