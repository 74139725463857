const Scene228: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 229,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Dari pernyataan berikut ini, manakah yang termasuk Opportunity seorang CDP ? (Silahkan pilih 4 pernyataan)",
      option: {
        name: "Option",
        param: {
          options: [
            "Wilayah potensial yang banyak Ibu-ibu pengusahanya, dan sangat ingin bisnisnya maju",
            "Ada ibu-ibu yang cepat dalam memahami aplikasi AMAAN",
            "Ada Ibu-ibu yang bisa membantu menjelaskan penggunaan aplikasi AMAAN kepada Ibu-ibu lainnya",
            "Ada pelatihan untuk menjadi pengusaha yang disediakan oleh perusahaan",
            "Kedisiplinan diri yang kuat untuk menepati jadwal kerja yang sudah dibuat",
            "Terampil membina relasi yang hamonis dengan Business Leader",
            "Ibu-ibu yang suka berkumpul menghabiskan waktu dengan bergossip tentang tetangganya yang bermasalah",
            "Pemahaman akan cara-cara praktis mengatasi masalah yang terjadi saat aplikasi tidak lancar digunakan",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "316",
        },
      },
    },
  ],
};

export default Scene228;
