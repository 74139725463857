import {
  post_test_codes,
  practice_materi_1_codes,
  practice_materi_2_codes,
  practice_materi_3_codes,
  pre_test_codes,
  prove_materi_1_codes,
  prove_materi_2_codes,
  prove_materi_3_codes,
  action_plan_codes,
  rating_codes,
  remedial_materi_1_codes,
  remedial_materi_3_codes,
  remedial_materi_2_codes,
} from "./codes";

function getVarCodes(scene_number: number) {
  switch (scene_number) {
    case 21:
      return pre_test_codes;
    case 71:
    case 86:
      return practice_materi_1_codes;
    case 94:
      return prove_materi_1_codes;
    case 123:
    case 139:
      return practice_materi_2_codes;
    case 149:
      return prove_materi_2_codes;
    case 192:
    case 207:
      return practice_materi_3_codes;
    case 230:
      return prove_materi_3_codes;
    case 251:
      return post_test_codes;
    case 253:
      return action_plan_codes;
    case 254:
      return rating_codes;
    case 292:
      return remedial_materi_1_codes;
    case 295:
      return remedial_materi_2_codes;
    case 298:
      return remedial_materi_3_codes;
    default:
      return [];
  }
}

function getValues(
  varCodes: string[],
  variables: { [key: string]: Answer | Answer[] }
) {
  return varCodes.map((varCode) => {
    if (Array.isArray(variables[varCode])) {
      // type is Answer[]
      const answers = variables[varCode] as Answer[];
      const values = answers.every((answer) => answer.value);
      return values;
    } else {
      // type is Answer
      const answer = variables[varCode] as Answer;
      return answer.value;
    }
  });
}

function getRemarks(
  varCodes: string[],
  variables: { [key: string]: Answer | Answer[] }
) {
  return varCodes.map((varCode) => {
    if (Array.isArray(variables[varCode])) {
      // type is Answer[]
      const answers = variables[varCode] as Answer[];
      const remarks = answers.map((answer) => answer.text).join(",");
      return remarks;
    } else {
      // type is Answer
      const answer = variables[varCode] as Answer;
      return answer.text;
    }
  });
}

function getJumpSceneNumber(variable_code: string) {
  switch (variable_code) {
    case pre_test_codes[pre_test_codes.length - 1]:
      return 22;
    case practice_materi_1_codes[practice_materi_1_codes.length - 1]:
      return 88;
    case prove_materi_1_codes[prove_materi_1_codes.length - 1]:
      return 95;
    case practice_materi_2_codes[practice_materi_2_codes.length - 1]:
      return 141;
    case prove_materi_2_codes[prove_materi_2_codes.length - 1]:
      return 150;
    case practice_materi_3_codes[practice_materi_3_codes.length - 1]:
      return 209;
    case prove_materi_3_codes[prove_materi_3_codes.length - 1]:
      return 231;
    case post_test_codes[post_test_codes.length - 1]:
      return 252;
    case action_plan_codes[action_plan_codes.length - 1]:
      return 253;
    case rating_codes[rating_codes.length - 1]:
    case remedial_materi_1_codes[remedial_materi_1_codes.length - 1]:
    case remedial_materi_2_codes[remedial_materi_2_codes.length - 1]:
    case remedial_materi_3_codes[remedial_materi_3_codes.length - 1]:
      return 254;
    default:
      return 1;
  }
}

function checkValues(answers: Answer[] | Answer) {
  if (Array.isArray(answers)) {
    return answers.every((answer) => answer.value);
  } else {
    return answers.value;
  }
}

export { getJumpSceneNumber, getVarCodes, checkValues, getValues, getRemarks };
