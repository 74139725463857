const Scene188: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "309",
  nextTrue: 189,
  nextFalse: 190,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Apa yang dikategorikan sebagai ancaman (threat)? Pilih 4 ya!",
      option: {
        name: "SelectPut",
        param: {
          title: "Ancaman",
          options: [
            "Kepala Desa yang membuat aturan sesuka hatinya",
            "Pendekar lain yang ilmunya lebih tinggi",
            "Perguruan silat lain ",
            "Persenjataan perguruan lain yang lebih lengkap",
            "Ketidakdisiplinan dalam berlatih",
            "Tidak mampu membagi waktu dengan baik",
            "Pergaulan yang kurang luas dengan pendekar dari perguruan lain",
            "Catatan yang tidak lengkap",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "309",
        },
      },
    },
  ],
};

export default Scene188;
