import materi from "../assets/contents/materi1practice5.jpg";

const Scene073: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa2",
  sfx: "",
  variableCheck: "106",
  nextTrue: 74,
  nextFalse: 75,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Baik. Sekarang kamu akan mengikuti latihan kedua. Kali ini bersungguh-sungguhlah.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Pertanyaan pertama mengenai pendekar Marutama. Baca dan pahami.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah Marutama tergolong pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Bukan", "Belum tentu", "Tidak tahu"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "106",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Maju menerima masukan dari orang lain",
            "Tidak bersedia mengenali diri sendiri",
            "Ingin mengalahkan lawan-lawannya",
            "Meninggalkan kehidupan yang sebenarnya bisa memberi manfaat bagi sesama",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "106",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene073;
