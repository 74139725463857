import materi from "../assets/contents/materi2Practice4jawaban.jpg";

const Scene120: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 122,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus, Benar, Tujuan yang dipilih lebih tepat karena sudah memenuhi seluruh kriteria BTS Hasil",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene120;
