import styles from "../styles/xp.module.css";
import coin from "../assets/icons/coin.png";
import { useXP } from "../hooks";

const XP = () => {
  const { xp } = useXP();

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={coin} alt="XP" />
      <div className={styles.box}>{xp} XP</div>
    </div>
  );
};

export default XP;
