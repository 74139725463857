const Scene206: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Apa pun hasilnya, Eyang ucapkan terima kasih karena sudah berusaha mengikuti latihan demi latihan. Saatnya, Eyang umumkan hasilnya. Dan, hasilnya adalah.. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene206;
