import rangkuman from "../assets/contents/rangkumanMateri3.jpg";

const Scene221: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmFocus1",
  sfx: "",
  next: 222,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Agar kakak bisa lebih ingat lagi, berikut adalah rangkuman dari materi Personal SWOT yang baru saja kita pelajari",
      option: {
        name: "Zoom",
        param: {
          contents: [rangkuman],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Sekarang kita uji pemahaman kakak tentang personal SWOT, namun dalam peran sebagai CDP. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Silakan pilih jawaban yang sesuai yaa....",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene221;
