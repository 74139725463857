const Scene189: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 191,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Betul sekali. Kamu hebat!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene189;
