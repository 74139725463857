const Scene248: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 249,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Aspek-aspek di dalam diri yang perlu dikenali adalah ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Values, kepribadian, kompetensi",
            "Values, kekuatan, kelemahan",
            "Visi pribadi, kemampuan, bantuan",
            "Visi pribadi, Kepribadian, kompetensi",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "320",
        },
      },
    },
  ],
};

export default Scene248;
