import materi3 from "../assets/videos/materi3.mp4";

const Scene175: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 176,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo simak video ini untuk mempelajari caranya!",
      option: {
        name: "Video",
        param: {
          src: materi3,
        },
      },
    },
  ],
};

export default Scene175;
