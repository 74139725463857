const Scene062: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 64,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah. Itulah manfaat fokus dan tekun belajar.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, kita lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene062;
