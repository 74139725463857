const Scene086: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 88,
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Selamat! Alhamdulillah! Kamu berhasil menyelesaikan dan melewati tahap latihan dengan baik. Sekarang istirahat dulu. 20 XP untukmu.",
      xp: 20,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (proud)"],
      line: "Sekarang, kamu akan bertemu dengan suhu di kelas training virtual. Jaga sikap dan tetap waspada. Jangan malu-maluin, Eyang.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene086;
