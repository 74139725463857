import { Button } from ".";
// import close from "../assets/buttons/close.svg";
import styles from "../styles/modal.module.css";

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const Modal = ({ show, onClose }: ModalProps) => {
  const resetData = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div
      className={styles.modalContainer}
      style={{
        opacity: show ? 1 : 0,
        pointerEvents: show ? "auto" : "none",
      }}
    >
      <div
        className={styles.modalBox}
        style={{
          transform: show ? "translateY(0px)" : "translateY(400px)",
        }}
      >
        {/* <img
          className={styles.closeButton}
          src={close}
          alt="Close"
          onClick={onClose}
        /> */}
        <ul>
          <li>
            Pastikan jaringan internet Anda stabil selama memainkan game ini
          </li>
          <li>Gunakan browser Chrome pada perangkat Tablet Android</li>
          <li>
            Bila terjadi error, coba gunakan tombol 'Reset Data' di bawah ini.
          </li>
          <li>
            Apabila masih terjadi error harap screenshot bagian yang error dan
            kirimkan email ke petualangancdp@flip.co.id
          </li>
        </ul>
        <div style={{ textAlign: "center", marginTop: 48, marginBottom: 32 }}>
          <Button text="Continue" type="primary" onClick={onClose} />
          <Button text="Reset Data" type="primary" onClick={resetData} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
