const Scene249: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 250,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Berikut ini pernyataan yang BENAR tentang kepribadian adalah ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Kepribadian adalah sesuatu yang netral, tidak ada kepribadian yang buruk",
            "Kepribadian terbentuk dari lingkungan ",
            "Kepribadian dapat berubah-ubah sesuai kebutuhan",
            "Kepribadian merujuk pada perbedaan pemikiran, perasaan, dan tindakan",
          ],
          values: [true, false, false, true],
          select: 1,
          variableCode: "321",
        },
      },
    },
  ],
};

export default Scene249;
