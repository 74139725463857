import testImage from "../assets/contents/Contoh1.jpg";
import testImage2 from "../assets/contents/Contoh2.jpg";
import sampleVideo from "../assets/videos/sample.mp4";

const Scene002: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Sebelum kami jelaskan bedanya seperti apa, kami ingin memperkenalkan fitur-fitur yang akan kita gunakan dalam pelatihan kita! Kita coba satu persatu yuk!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk kotak berisikan opsi yang ingin dipilih. Jika ingin mengganti opsi, ketuk opsi lain yang ingin dipilih. Ketuk next untuk melanjutkan ke bagian selanjutnya, perhatikan setiap dialog yang ada karena tidak ada opsi untuk kembali.",
      option: {
        name: "Option",
        param: {
          options: ["Opsi 1", "Opsi 2", "Opsi 3", "Opsi 4"],
          values: [false, false, false, false],
          select: 1,
        },
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk video di atas untuk menonton, ketuk tombol bagian kanan bawah video untuk memperbesar tampilan menjadi penuh. Lalu, simak video hingga selesai.",
      option: {
        name: "Video",
        param: {
          src: sampleVideo,
        },
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk tombol berbentuk kaca pembesar untuk memperbesar gambar dan ketuk kembali tombol tersebut untuk kembali ke ukuran semula. Ketuk tombol panah untuk melihat gambar selanjutnya atau kembali ke gambar sebelumnya.",
      option: {
        name: "Zoom",
        param: {
          contents: [testImage, testImage2],
        },
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk pilihan untuk memasukkannya ke dalam grup. Ketuk kembali pilihan untuk mengeluarkannya dari grup dan memilih pilihan lain.",
      option: {
        name: "SelectPut",
        param: {
          title: "Pilih 2",
          options: ["Pilihan 1", "Pilihan 2", "Pilihan 3"],
          values: [true, true, true],
          select: 2,
        },
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk panah ke bawah untuk melihat opsi. Pilih salah satu opsi tersebut. Lalu, isi semua kotak yang kosong.",
      option: {
        name: "Dropdown",
        param: {
          text: "Dari sekian opsi pilihlah satu yang menurutmu paling tepat $[] . Kakak memilih opsi tersebut karena $[] . Jika kakak bisa mengganti opsi sebelumnya, opsi mana yang akan kakak pilih $[]",
          optionList: [
            ["opsi A", "opsi B", "opsi C"],
            ["opsi A", "opsi B", "opsi C"],
            ["opsi A", "opsi B", "opsi C"],
          ],
        },
      },
    },
    {
      characters: ["Tutorial"],
      line: "Ketuk kotak di atas untuk mengisi jawaban. Isi dengan namamu ya.",
      option: {
        name: "Text",
        param: {
          placeholder: "Nama kakak",
          variableCode: "name",
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Hai $[name]!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene002;
