function variableInjection(
  line: string,
  variables: { [key: string]: Answer | Answer[] }
) {
  if (line.includes("$[name]")) {
    const variable = variables["name"] as Answer;
    line = line.replace("$[name]", variable.text);
  } else if (line.includes("$[fullname]")) {
    const variable = variables["fullname"] as Answer;
    line = line.replace("$[fullname]", variable.text);
  } else if (line.includes("$[speedRondeSatu]")) {
    const variable = variables["speedRondeSatu"] as Answer;
    line = line.replace("$[speedRondeSatu]", variable.text);
  } else if (line.includes("$[speedRondeDua]")) {
    const variable = variables["speedRondeDua"] as Answer;
    line = line.replace("$[speedRondeDua]", variable.text);
  } else if (line.includes("$[targetAwalChallange]")) {
    const variable = variables["targetAwal"] as Answer;
    line = line.replace(
      "$[targetAwalChallange]",
      (parseInt(variable.text) - 2).toString()
    );
  } else if (line.includes("$[speedChallange]")) {
    const variable = variables["speedRondeDua"] as Answer;
    line = line.replace(
      "$[speedChallange]",
      (parseInt(variable.text) - 2).toString()
    );
  } else if (line.includes("$[speedRondeDuaPlus]")) {
    const variable = variables["speedRondeDua"] as Answer;
    line = line.replace(
      "$[speedRondeDuaPlus]",
      (parseInt(variable.text) + 10).toString()
    );
  } else if (line.includes("$[targetAwalPlus]")) {
    const variable = variables["targetAwal"] as Answer;
    line = line.replace(
      "$[targetAwalPlus]",
      (parseInt(variable.text) + 10).toString()
    );
  }
  return line;
}

export { variableInjection };
