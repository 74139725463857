const Scene171: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "",
  next: 172,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Baik, kita refleksi, apa yang menjadi penyebab kekalahan kamu di putaran pertama? ",
      option: {
        name: "Option",
        param: {
          options: [
            "Tidak mengenali kekuatan sendiri",
            "Tidak mengenali kekuatan lawan",
            "Tidak paham aturan bertanding",
            "Lawannya curang",
          ],
          values: [true, true, true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene171;
