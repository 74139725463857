import materi3 from "../assets/videos/materi3.mp4";

const SceneTontonMateri3: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmClassic2",
  sfx: "",
  next: 278,
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Silahkan tonton telebih dahulu materi berikut.",
      option: {
        name: "Video",
        param: {
          src: materi3,
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Setelah ini kakak harus menyelesaikan soal ujian. Semangat kak kerjakan dengan baik.",
      option: {
        name: null,
      },
    },
  ],
};

export default SceneTontonMateri3;
