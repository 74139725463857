import commitVideo from "../assets/videos/commit1materi2.mp4";

const Scene101: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "",
  next: 102,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Ibu Peri(h) memiliki banyak cerita, ayo kita simak ceritanya.",
      option: {
        name: "Video",
        param: {
          src: commitVideo,
        },
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Bagaimana, kalian sudah melihat video dari sahabat Eyang, Ibu Peri(h)",
      option: {
        name: "Option",
        param: {
          options: [
            "Sudah Eyang Guru!",
            "Tentu saja sudah dong!",
            "Sudah sampai selesai menontonnya dan dipahami juga.",
          ],
          values: [true, true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene101;
