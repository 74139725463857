import materi from "../assets/contents/materi2Prove4.jpg";

const Scene148: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 149,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Berikut ini adalah sebuah target pribadi yang dimiliki oleh salah seorang CDP yang bernama Ulfa, silakan dibaca!",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Pilihlah pernyataan tujuan yang lebih tepat",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Di akhir masa bakti sebagai CDP, mengumpulkan tabungan sebesar 15 juta rupiah untuk memulai usaha sebagai reseller pakaian wanita",
            "Di akhir masa bakti sebagai CDP, memiliki tabungan sebesar 15 juta rupiah untuk memulai usaha",
            "Di akhir masa bakti sebagai CDP, memiliki tabungan sebesar 15 juta rupiah untuk memulai usaha sebagai reseller pakaian wanita ",
            "Di akhir masa bakti sebagai CDP, mengumpulkan tabungan sebesar 15 juta rupiah untuk memulai usaha",
          ],
          values: [false, false, true, false],
          select: 1,
          variableCode: "213",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene148;
