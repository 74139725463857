const initCards = ["Mata", "Tiupan", "Pukulan", "Super Power"];
const together = 1;
const playerFirst = 2;
const npcFirst = 3;
const positionX = (name: string) => {
  if (name === "Mata") {
    return "5%";
  } else if (name === "Tiupan") {
    return "35%";
  } else if (name === "Pukulan") {
    return "65%";
  } else if (name === "Super Power") {
    return "95%";
  }
};

export { initCards, together, playerFirst, npcFirst, positionX };
