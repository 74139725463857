const Scene016: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Analisis SWOT pribadi diperlukan untuk ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Menyusun rencana tindakan untuk mewujudkan misi pribadi",
            "Tidak tahu",
            "Memperoleh data analisis diri yang akurat",
            "Memperkirakan tingkat keberhasilan dalam mewujudkan tujuan pribadi",
            "Membuat rekan kerja lebih paham tentang diri kita",
          ],
          values: [true, false, false, false, false],
          variableCode: "301",
          select: 1,
        },
      },
    },
  ],
};

export default Scene016;
