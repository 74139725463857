import tidakLulusPendekarMuda from "../assets/contents/tidakLulusPendekarMuda.jpg";

const Scene140: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  // next: 105, by pass
  next: 141,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Maaf. Kamu tidak lolos dan belum berhasil menjadi pendekar muda. Tetap semangat!",
      option: {
        name: "Zoom",
        param: {
          contents: [tidakLulusPendekarMuda],
        },
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Sekarang kamu harus kembali menonton materi mengenai misi pribadi!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene140;
