import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { showNextButtonAtom, variablesAtom } from "../../stores";
import styles from "../../styles/input-text.module.css";

interface InputTextProps {
  type: "short" | "long" | "number";
  placeholder?: string;
  containerClass?: string;
  inputClass?: string;
  variableCode?: string;
  maxLength?: number;
}

const InputText = ({
  type,
  placeholder,
  containerClass,
  inputClass,
  variableCode,
  maxLength,
}: InputTextProps) => {
  const [answer, setAnswer] = useState("");
  const [, setShowNextButton] = useAtom(showNextButtonAtom);
  const [, setVariables] = useAtom(variablesAtom);

  useEffect(() => {
    setShowNextButton(answer !== "");
    if (variableCode) {
      setVariables((variables) => {
        return {
          ...variables,
          [variableCode]: {
            index: -1,
            text: answer,
            value: true,
          },
        };
      });
    }
  }, [answer, setShowNextButton, setVariables, variableCode]);

  if (type === "short" || type === "number") {
    return (
      <div className={containerClass ?? styles.inputContainer}>
        <input
          className={inputClass ?? styles.inputBox}
          type={type === "short" ? "text" : "number"}
          placeholder={placeholder}
          onChange={(e) => setAnswer(e.target.value)}
          maxLength={maxLength}
        />
      </div>
    );
  }
  if (type === "long") {
    return (
      <div className={containerClass ?? styles.inputContainer}>
        <textarea
          className={inputClass ?? styles.inputBox}
          placeholder={placeholder}
          onChange={(e) => setAnswer(e.target.value)}
          rows={4}
          maxLength={maxLength}
        ></textarea>
      </div>
    );
  }
  throw new Error("Input text type not supported");
};

export default InputText;
