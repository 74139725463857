const Scene222: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 223,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Dalam pekerjaan kakak sebagai CDP, banyaknya pinjaman online di luar sana, dapat dikategorikan sebagai ....",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Weakness", "Opportunity", "Threat"],
          values: [false, false, false, true],
          select: 1,
          variableCode: "310",
        },
      },
    },
  ],
};

export default Scene222;
