const Scene238: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 239,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Dalam bertindak, seorang pribadi prestatif akan memilih tindakan yang ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Memiliki risiko sedang",
            "Memiliki risiko tinggi",
            "Memiliki risiko rendah",
            "Menghindari risiko",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "116",
        },
      },
    },
  ],
};

export default Scene238;
