const Scene212: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "Scene212",
  nextTrue: 213,
  nextFalse: 214,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Apa singkatan dari SWOT?",
      option: {
        name: "Option",
        param: {
          options: [
            "Strength, Weakness, Opportunity, Threat",
            "Steady, Wait, Option, Tactic",
            "Strength, Wayout, Opportunity, Tactic",
            "Steady, Weakness, Option, Target",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene212",
        },
      },
    },
  ],
};

export default Scene212;
