const Scene197: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "307",
  nextTrue: 198,
  nextFalse: 199,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Jelaskan mana yang menurutmu adalah KELEMAHAN? (Pilih 4)",
      option: {
        name: "SelectPut",
        param: {
          title: "Kelemahan",
          options: [
            "Tidak tahan udara panas",
            "Tidak menguasai jurus pukul dengan sempurna",
            "Meremehkan pendekar lain",
            "Mudah mengantuk",
            "Tidak ada dukungan keluarga",
            "Teman tidak mau mengajari jurus yang benar",
            "Sarana latihan kurang memadai",
            "Lingkungan padepokan yang kotor dan tidak terawat",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "307",
        },
      },
    },
  ],
};

export default Scene197;
