import class_ from "./class.jpg";
import padepokanselaras from "./padepokanselaras.jpg";
import kelaspadepokan from "./kelaspadepokan.jpg";
import lapangan from "./lapangan.jpg";

const backgrounds: { [key: string]: string } = {
  class: class_,
  padepokanselaras: padepokanselaras,
  lapangan: lapangan,
  kelaspadepokan: kelaspadepokan,
};

export default backgrounds;
