import { useEffect, useState } from "react";
import { useXP } from "../../hooks";
import Countdown from "./components/Countdown";
import SwipeCard from "./components/SwipeCard";

interface SiapaPrestatifProps {
  onFinish: () => void;
}

const SiapaPrestatif = ({ onFinish }: SiapaPrestatifProps) => {
  const { addXp } = useXP();
  const [play, setPlay] = useState(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setPlay(true);
    }, 3000);
    return () => clearTimeout(time);
  }, []);

  if (!play) {
    return <Countdown />;
  } else {
    return (
      <SwipeCard
        onFinish={(score: number) => {
          if (score >= 61 && score <= 80) {
            addXp(5);
          } else if (score >= 21 && score <= 50) {
            addXp(3);
          } else if (score >= 0 && score <= 20) {
            addXp(1);
          }
          onFinish();
        }}
      />
    );
  }
};

export default SiapaPrestatif;
