const Scene103: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 105,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Astagfirullah. Jawaban kamu masih banyak yang salah.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Melalui video Ibu Peri(h), kamu dapat belajar bahwa memiliki misi pribadi itu penting bagi seorang pendekar.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Trio pendekar Idamaan itu berhasil menjadi versi terbaik dari dirinya karena punya mimpi dan sabar untuk terus fokus mencapai mimpinya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Kamu juga harus punya misi pribadi. Cari apa mimpimu.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene103;
