const Scene081: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 82,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim. Konsentrasi dong.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Jawaban yang benar adalah karena giat berlatih untuk mencapai tujuan",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Ayo, lebih fokus, lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene081;
