const Scene070: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Itu tadi 4 soal latihan mengenai beberapa pendekar yang sudah kamu pelajari. Dan inilah nilai yang sudah kamu capai..",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene070;
