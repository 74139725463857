const Scene042: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "APA?! Sudah menyerah? Mana ada pendekar cepat putus asa! Kamu harus mau mencoba lagi",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene042;
