const Scene149: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  variableCheck: "lanjutKeMateri3",
  nextTrue: 150,
  nextFalse: 0,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Selamat! Kakak sudah menyelesaikan rangkaian materi 2 mengenai tujuan pribadi!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Apakah kakak ingin melanjutkan pembelajaran ke materi selanjutnya?",
      option: {
        name: "Option",
        param: {
          options: ["Ya, lanjut ke materi selanjutnya!", "Lanjut nanti saja"],
          values: [true, false],
          select: 1,
          variableCode: "lanjutKeMateri3",
        },
      },
    },
  ],
};

export default Scene149;
