import materi from "../assets/contents/materi2Practice3.jpg";

const Scene116: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  variableCheck: "208",
  nextTrue: 117,
  nextFalse: 118,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Dalam kasus Jagawana ini, kamu pilih mana yang merupakan penyataan tujuan yang lebih tepat?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Dalam waktu 10 tahun, sudah menjadi pendekar yang paling disegani di pelosok negeri, karena menguasai seluruh jurus yang ada di 15 kitab pusaka.",
            "Saat berusia 60 tahun, menjadi pendekar yang paling disegani di pelosok negeri, karena menguasai berbagai macam jurus ",
            "Saat berusia 60 tahun, menjadi pendekar sakti yang sudah menguasai banyak jurus pamungkas dari 15 kitab pusaka",
            "Dalam waktu 10 tahun, sudah menjadi pendekar yang paling disegani di pelosok negeri dan diakui oleh Eyang Guru",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "208",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene116;
