import { useAtom } from "jotai";
import { shuffle } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { showNextButtonAtom, variablesAtom } from "../../stores";
import styles from "../../styles/multiple-choice.module.css";

interface MultipleChoiceProps {
  options: string[];
  values: any[];
  select: number;
  containerClass?: string;
  optionClass?: string;
  optionActiveClass?: string;
  variableCode?: string;
  variableIndex?: number;
}

interface Choice {
  option: string;
  value: any;
  selected: boolean;
  index: number;
}

const MultipleChoice = ({
  options,
  values,
  select,
  containerClass,
  optionClass,
  optionActiveClass,
  variableCode,
  variableIndex,
}: MultipleChoiceProps) => {
  const [choices, setChoices] = useState<Choice[]>(() => {
    let choicesShuffle = shuffle(
      options.map((option, index) => {
        return {
          option: option,
          value: values[index],
          index: index,
          selected: false,
        };
      })
    );
    return choicesShuffle;
  });
  const [, setShowNextButton] = useAtom(showNextButtonAtom);
  const [, setVariables] = useAtom(variablesAtom);

  useEffect(() => {
    const selectedChoice = choices.filter((cho) => cho.selected);
    setShowNextButton(selectedChoice.length === select);
    if (variableCode && selectedChoice.length === select) {
      if (typeof variableIndex !== "undefined") {
        setVariables((variables) => {
          const answers = variables[variableCode] as Answer[];
          const newAnswer: Answer = {
            index: selectedChoice[0].index,
            value: selectedChoice[0].value,
            text: selectedChoice[0].option,
          };
          answers[variableIndex] = newAnswer;
          return {
            ...variables,
            [variableCode]: answers,
          };
        });
      } else {
        if (select === 1) {
          setVariables((variables) => {
            const newAnswer: Answer = {
              index: selectedChoice[0].index,
              value: selectedChoice[0].value,
              text: selectedChoice[0].option,
            };
            return {
              ...variables,
              [variableCode]: newAnswer,
            };
          });
        } else {
          setVariables((variables) => {
            const newAnswer: Answer = {
              index: -1,
              value: selectedChoice.every((choice) => choice.value),
              text: selectedChoice.map((choice) => choice.option).join(","),
            };
            return {
              ...variables,
              [variableCode]: newAnswer,
            };
          });
        }
      }
    }
  }, [
    choices,
    select,
    setShowNextButton,
    setVariables,
    variableCode,
    variableIndex,
  ]);

  const onClickChoice = useCallback(
    (index: number) => {
      let choicesSelected = choices.filter((cho) => cho.selected);
      let newChoices = [...choices];
      if (select === 1) {
        newChoices = newChoices.map((cho, indexCho) => {
          if (indexCho === index) {
            return {
              ...cho,
              selected: true,
            };
          }
          return {
            ...cho,
            selected: false,
          };
        });
      } else {
        if (newChoices[index].selected) {
          newChoices[index].selected = false;
        } else if (choicesSelected.length < select) {
          newChoices[index].selected = true;
        }
      }
      setChoices(newChoices);
    },
    [choices, select]
  );

  return (
    <div
      className={
        containerClass ?? choices.length >= 8
          ? styles.optionContainerGrid
          : styles.optionContainer
      }
    >
      {choices.map((choice: Choice, index: number) => {
        return (
          <button
            key={index + choice.option}
            style={{
              padding:
                options[index].length > 80
                  ? "0.3rem"
                  : options.length >= 6
                  ? "1rem"
                  : "1.2rem",
            }}
            className={
              choice.selected
                ? optionActiveClass ??
                  (choices.length >= 8
                    ? styles.optionButtonSelectedGrid
                    : styles.optionButtonSelected)
                : optionClass ??
                  (choices.length >= 8
                    ? styles.optionButtonGrid
                    : styles.optionButton)
            }
            onClick={() => onClickChoice(index)}
          >
            {choice.option}
          </button>
        );
      })}
    </div>
  );
};

export default MultipleChoice;
