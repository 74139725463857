import { useEffect, useState } from "react";
import styles from "../styles/countdown.module.css";

const Countdown = () => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    const time = setInterval(() => {
      setCount((count) => {
        if (count > 0) {
          return count - 1;
        }
        return count;
      });
    }, 1000);
    return () => clearInterval(time);
  }, []);

  if (count > 0) {
    return <div className={styles.container}>{count}</div>;
  } else {
    return <div></div>;
  }
};

export default Countdown;
