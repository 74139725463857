const Scene047: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "sfxChampion",
  next: 49,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "BAGUS! Kamu sudah berhasil mencapai target yang sudah kamu tetapkan. Saya berikan 2 xp tambahan.",
      xp: 2,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Sekarang, ayo kita lakukan refleksi atas uji seleksi tadi.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene047;
