import { useProgress } from "../hooks";
import styles from "../styles/progress.module.css";

interface ProgressProps {
  sceneNumber: number;
}

const Progress = ({ sceneNumber }: ProgressProps) => {
  const { progress } = useProgress(sceneNumber);

  return (
    <div className={styles.container}>
      <div className={styles.progressBar}>
        <div
          className={styles.progressValue}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className={styles.value}>{progress}%</div>
    </div>
  );
};

export default Progress;
