const Scene027: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Untuk mendapatkan XP, kamu harus menyelesaikan tantangan, dan saya sendiri yang akan mengevaluasi dan memberikan XP.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Akan ada pelantikan yang dilakukan setelah materi yang diberikan selesai.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Mengerti?",
      option: {
        name: "Option",
        param: {
          options: ["Ya, mengerti", "Paham betul", "Sangat paham"],
          values: [true, true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Bagus!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene027;
