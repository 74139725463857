import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { showNextButtonAtom } from "../../stores";
import styles from "../../styles/dropdown.module.css";

interface DropdownProps {
  text: string;
  optionsList: string[][];
  //   codeList?: string[];
  containerClass?: string;
  dropdownClass?: string;
}

const Dropdown = ({
  text,
  optionsList,
  containerClass,
  dropdownClass,
}: DropdownProps) => {
  const [values, setValues] = useState(Array(optionsList.length).fill(""));
  const [, setShowNextButton] = useAtom(showNextButtonAtom);

  useEffect(() => {
    setValues(Array(optionsList.length).fill(""));
  }, [optionsList.length, text]);

  useEffect(() => {
    setShowNextButton(values.filter((val) => val === "").length === 0);
  }, [setShowNextButton, values]);

  const onChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    let userValues = [...values];
    userValues[index] = event.target.value;
    setValues(userValues);
  };

  const dropDown = (options: string[], index: number) => {
    return (
      <select
        className={dropdownClass ?? styles.dropdown}
        key={index}
        onChange={(event) => onChange(event, index)}
        value={values[index]}
      >
        <option></option>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    );
  };

  const RenderText = () => {
    const split = text.split(" ");
    let indexPlaceholder = 0;
    const textWithDropdowns = split.map((el, index) => {
      if (el === "$[]") {
        const dropdown = dropDown(
          optionsList[indexPlaceholder],
          indexPlaceholder
        );
        indexPlaceholder += 1;
        return dropdown;
      } else if (el === "\n") {
        return <br key={index} />;
      } else {
        return el + " ";
      }
    });
    return <span>{textWithDropdowns}</span>;
  };

  return (
    <div className={containerClass ?? styles.dropdownContainer}>
      <RenderText />
    </div>
  );
};

export default Dropdown;
