import materi from "../assets/contents/materi2Practice4.jpg";

const Scene119: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "209",
  nextTrue: 120,
  nextFalse: 121,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Selanjutnya, dalam kasus Janilaya ini, mana yang merupakan pernyataan tujuan yang lebih tepat?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Saat berusia 40 tahun, aku sudah memiliki padepokan silat dan memiliki minimal 100 murid ",
            "20 tahun lagi, aku ingin memiliki padepokan dengan 100 murid seperti padepokan selaras",
            "Saat berusia 40 tahun, aku mau memiliki padepokan silat dengan minimal 100 murid seperti padepokan selaras",
            "20 tahun lagi, aku akan memiliki padepokan dengan 100 murid seperti padepokan selaras",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "209",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene119;
