const Scene227: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 228,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Dari pernyataan berikut ini, manakah yang termasuk Weakness seorang CDP ? (Silahkan pilih 4 pernyataan)",
      option: {
        name: "Option",
        param: {
          options: [
            "Menunda-nunda pertemuan silaturahmi kelompok",
            "Kurang teliti dalam memeriksa data administrasi",
            "Belum menguasai fitur-fitur dalam aplikasi AMAAN dengan lancar",
            "Tidak yakin bahwa ibu-ibu bisa menggunakan aplikasi dengan baik",
            "Petugas dari lembaga pembiayaan lain lebih pandai menarik perhatian Ibu-ibu",
            "Persepsi bahwa aplikasi AMAAN adalah pinjol",
            "Atasan yang terlalu banyak tuntutan",
            "Pasangan yang tidak setuju dengan pekerjaan sebagai seorang CDP",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "315",
        },
      },
    },
  ],
};

export default Scene227;
