import styles from "../styles/petunjuk.module.css";

interface PetunjukProps {
  onClick: () => void;
}

const Petunjuk = ({ onClick }: PetunjukProps) => {
  return (
    <div className={styles.Petunjuk}>
      <div className={styles.PetunjukTitle}>Petunjuk</div>
      <ol className={styles.PetunjukList}>
        <li>
          Klik bola yang datang dari arah lawan untuk memukulnya, sebelum bola
          tersebut menghilang
        </li>
        <li>Apabila anda berhasil memukul bola, anda mendapat 1 poin.</li>
        <li>Apabila anda gagal memukul bola, musuh mendapat 1 poin.</li>
        <li>
          Pemenang permainan adalah orang pertama yang berhasil mengumpulkan 21
          poin.
        </li>
      </ol>
      <button className={styles.PetunjukButton} onClick={onClick}>
        Mulai
      </button>
    </div>
  );
};

export default Petunjuk;
