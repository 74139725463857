const Scene155: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 156,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Berikutnya, apa kriteria penulisan tujuan pribadi? ",
      xp: 2,
      option: {
        name: "Option",
        param: {
          options: ["BTS Hasil", "SMART", "AMPUH", "JUARA"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene155",
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ini juga penting sekali. Kriterianya adalah BTS Hasil",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene155;
