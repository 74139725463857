const Scene125: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa2",
  sfx: "",
  next: 126,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Kali ini usahakan untuk lebih konsentrasi dalam menjalani ujian. Kesempatan yang diberikan untuk menjadi pendekar muda itu terbatas. Jadi berusahalah mencoba sebaik-baiknya!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene125;
