import materi from "../assets/contents/materi2Prove3.jpg";

const Scene147: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 148,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Berikut ini adalah sebuah target pribadi yang dimiliki oleh salah seorang CDP yang bernama Tania, silakan dibaca!",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Pilihlah pernyataan tujuan yang lebih tepat",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Di akhir tahun 2023, sudah memiliki tabungan sebesar 10 juta rupiah untuk melanjutkan sekolah, serta menjadi CDP taat di modis",
            "Di akhir tahun 2023, ingin memiliki tabungan sebesar 10 juta rupiah untuk melanjutkan sekolah, serta menjadi CDP terbaik di modis",
            "Di akhir tahun 2023, ingin memiliki tabungan sebesar 10 juta rupiah untuk melanjutkan sekolah, serta masuk 3 besar CDP terbaik di modis",
            "Di akhir tahun 2023, memiliki tabungan sebesar 10 juta rupiah untuk melanjutkan sekolah, serta masuk 3 besar CDP terbaik di modis",
          ],
          values: [false, false, false, true],
          select: 1,
          variableCode: "212",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene147;
