const Scene144: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 145,
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Sama seperti sebelumnya, saatnya untuk mengaplikasi pendidikan Anda sebagai pendekar ke dalam pekerjaan sebagai CDP!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene144;
