import sfxChampion from "./sfxChampion.aac";
import sfxCorrect from "./sfxCorrect.aac";
import sfxLose from "./sfxLose.aac";
import sfxWrong from "./sfxWrong.aac";

const sfxs: { [key: string]: string } = {
  sfxChampion: sfxChampion,
  sfxCorrect: sfxCorrect,
  sfxLose: sfxLose,
  sfxWrong: sfxWrong,
};

export default sfxs;
