const Scene194: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa2",
  sfx: "",
  variableCheck: "306",
  nextTrue: 195,
  nextFalse: 196,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Kita mulai lagi latihannya kembali ya. Apakah kamu sudah siap?",
      option: {
        name: "Option",
        param: {
          options: [
            "Saya akan berusaha sebaik-baiknya!",
            "Siap, latihan ini pasti berhasil!",
          ],
          values: [true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Pilih 4 keterangan yang menurutmu adalah KEKUATAN?",
      option: {
        name: "SelectPut",
        param: {
          title: "Kekuatan",
          options: [
            "Fisik yang bugar",
            "Semangat berlatih yang tinggi",
            "Daya tangkap yang baik",
            "Bisa tidak makan dan minum selama 3 hari",
            "Teman perguruan yang asik",
            "Guru yang bagus dalam mengajar",
            "Perpustakaan yang lengkap",
            "Makanan yang berigizi",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "306",
        },
      },
    },
  ],
};

export default Scene194;
