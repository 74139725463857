const Scene159: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  next: 160,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Latih-tanding yang akan kamu lakukan berupa tanding kartu. Kamu akan diberikan 4 kartu dan kamu memiliki kesempatan untuk mengeluarkan kartu tersebut satu persatu hingga habis. Cukup ketuk satu kartu yang menjadi pilihanmu dan secara bersamaan lawan akan mengeluarkan kartu lain untuk melawan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Score akan ditampilkan pada bagian kanan bawah layar. Setiap kemenangan akan mendapat tambahan 1 score dan jika seri tidak ada yang mendapat tambahan nilai. Pastikan kamu memperoleh score lebih tinggi dari lawan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Apakah kamu sudah siap? Ucapkan, Bismillah!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene159;
