const Scene181: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 182,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Apa yang kamu pikirkan?! Fokus jika kamu ingin menang dalam pertarungan!  ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Kekuatan atau strength adalah hal-hal dalam diri yang dapat dilakukan dengan baik dan dapat membantu mencapai misi pribadi",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Dari pertanyaan sebelumnya, ini yang merupakan kekuatan sebagai pendekar menguasai jurus Matahari Tenggelam, kuda-kuda yang kokoh, kecepatan dalam bergerak, dan daya konsentrasi yang tinggi",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene181;
