import materi from "../assets/contents/materi2Practice3jawaban.jpg";

const Scene117: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "sfxCorrect",
  next: 119,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Pintar! Betul, tujuan yang dipilih lebih tepat karena sudah memenuhi seluruh kriteria BTS Hasil.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene117;
