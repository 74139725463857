async function upload(
  access_token: string,
  variable_code: string,
  value: boolean,
  remarks: string
) {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/assessment",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question_code: variable_code,
        is_valid: value,
        remarks: remarks,
      }),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data };
  } else {
    console.error({
      data: JSON.stringify({
        question_code: variable_code,
        is_valid: value,
        remarks: remarks,
      }),
      response: response,
    });
    return { status: response.status, data: response.statusText };
  }
}

async function upload_multiple(
  access_token: string,
  variable_code: string[],
  value: boolean[],
  remarks: string[]
) {
  const body = variable_code.map((code, index) => {
    return {
      question_code: code,
      is_valid: value[index],
      remarks: remarks[index],
    };
  });

  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/assessment/sync",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data };
  } else {
    console.error({
      data: JSON.stringify(body),
      response: response,
    });
    return { status: response.status, data: response.statusText };
  }
}

export { upload, upload_multiple };

