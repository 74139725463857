const Scene122: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Baik, para calon pendekar AMAAN. Saatnya penentuan apakah kamu akan lulus pada tahap ini dan berhasil menjadi pendekar muda?!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene122;
