const Scene173: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 174,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Baik, kita refleksi lagi. Apa kunci keberhasilan kamu untuk memenangkan pertandingan?",
      option: {
        name: "Option",
        param: {
          options: [
            "Tahu Aturan Main",
            "Tahu Kekuatan dan kelemahan yang dimiliki",
            "Tahu kekuatan dan kelemahan lawan",
            "Bisa memanfaatkan peluang yang ada",
          ],
          values: [true, true, true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene173;
