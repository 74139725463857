const Scene164: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmSad1",
  sfx: "",
  next: 165,
  dialogues: [
    {
      characters: ["eyang guru (shock)"],
      line: "Kalah? Hahaha. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Makanya fokus. Tenang, waspada dan.. pentingnya tahu aturan serta kenali kekuatan dan kelemahan kamu dan lawan sebelum bertanding. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene164;
