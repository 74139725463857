const Scene014: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: 'Kriteria yang belum terpenuhi dalam tujuan berikut ini adalah : "Memiliki 20 Ibu Idamaan dan 40 Sahabat AMAAN"',
      option: {
        name: "Option",
        param: {
          options: [
            "Berbatas waktu",
            "Tidak tahu",
            "Terukur",
            "Spesifik",
            "Hasil",
          ],
          values: [true, false, false, false, false],
          variableCode: "204",
          select: 1,
        },
      },
    },
  ],
};

export default Scene014;
