const Scene218: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "Scene218",
  nextTrue: 219,
  nextFalse: 220,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Nah, sekarang.. mana aspek SWOT yang termasuk eksternal? Pilih 2 juga ya!",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Weakness", "Opportunity", "Threat"],
          values: [false, false, true, true],
          select: 2,
          variableCode: "Scene218",
        },
      },
    },
  ],
};

export default Scene218;
