const Scene251: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmCalm",
  sfx: "",
  next: 252,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Terima kasih sudah mengerjakan post test! saya yakin nilainya pasti bagus-bagus ",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (proud)"],
      line: "Iya donk! karena rekan-rekan ini sudah belajar dengan serius bersama Eyang Guru.",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Nah sekarang kami mau ajak rekan-rekan CDP untuk membuat rencana tindakan",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Rencana tindakan ini sangat penting untuk dibuat, karena akan jadi dokumen pengingat bagi rekan-rekan terkait misi pribadi yang ingin dicapai",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Kita akan buat 2 jenis misi pribadi ya! satu sebagai CDP dan satu lagi 3 tahun setelah selesai sebagai CDP!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Caranya mudah sekali, kami sudah buatkan formulirnya,  rekan-rekan cukup menuliskan jawaban di kotak yang tersedia ya",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene251;
