import materi from "../assets/contents/materi2Practice8.jpg";

const Scene135: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "209",
  nextTrue: 136,
  nextFalse: 137,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Berikutnya, perhatikan kasus Singawidya ini. Pilih mana yang merupakan pernyataan tujuan yang lebih tepat?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Sebelum umur 35 tahun, menjadi pengajar yang berhasil mencetak 25 pendekar berprestasi di padepokan selaras",
            "Sebelum umur 35 tahun, berhasil menjadi pengajar yang disukai oleh murid-murid di padepokan selaras",
            "Saat berusia 35 tahun, berhasil menjadi panutan pengajar di padepokan selaras",
            "Saat berusia 35 tahun, menjadi pengajar yang berhasil mencetak pendekar lain di padepokan selaras",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "209",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene135;
