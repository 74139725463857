const SceneCekHasilRemedial: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Baik kita lanjut ke pembacaan hasil yang kamu dapat ya.",
      option: {
        name: null,
      },
    },
  ],
};

export default SceneCekHasilRemedial;
