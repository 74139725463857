const Scene199: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 200,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Salah dong! Jawabannya adalah tidak tahan udara panas, tidak menguasai jurus pukul dengan sempurna, meremehkan pendekar lain, dan mudah mengantuk. Kaya kamu itu! makanya salah",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene199;
