const Scene044: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 46,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Itu baru calon pendekar! Selalu ingin lebih baik! Sekarang persiapkan dirimu untuk UJI KECEPATAN LARI!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene044;
