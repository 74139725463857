const Scene059: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 61,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Alhamdulillah! Kamu fokus dan sudah memahami materi yang Eyang berikan. Jagawana memang pribadi yang prestatif karena selalu belajar dan berlatih untuk meningkatkan kemampuannya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, kita lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene059;
