const Scene209: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 210,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Pendekar Madya! Pelajaran Eyang sudahi dulu. Saatnya kamu kembali pada suhu di kelas. Semangat. Assalammualaikum!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene209;
