const Scene052: Scene = {
  type: "Scene",
  background: "padepokanselaras",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Iya, memang begitulah manusia seharusnya. Dengan segala kelebihan yang sudah diberikan sang pencipta kita harus selalu bersyukur dan melakukan yang terbaik.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene052;
