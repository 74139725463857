const Scene036: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Bagus! Pendekar itu harus tau dan bisa mengukur kemampuan dirinya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, sekarang persiapkan dirimu untuk UJI KECEPATAN LARI!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene036;
