const Scene182: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "307",
  nextTrue: 183,
  nextFalse: 184,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Pertanyaan selanjutnya, Mana yang termasuk kelemahan (weakness)? Pilih 4 lagi ya!",
      option: {
        name: "SelectPut",
        param: {
          title: "Kelemahan",
          options: [
            "Sulit berkonsentrasi",
            "Belum hafal urutan jurus",
            "Keengganan berlatih",
            "Tidak bisa membagi waktu",
            "Gerakan lawan yang sangat cepat",
            "Persaingan ketat antar pendekar",
            "Sesama pendekar yang iri dengan diri kita",
            "Persenjataan lawan yang lebih modern",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "307",
        },
      },
    },
  ],
};

export default Scene182;
