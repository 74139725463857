const Scene184: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 185,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Salah. Ayo konsentrasi jika ingin menang! ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Kelemahan atau weakness merupakan hal-hal yang menghambat misi pribadi dan masih harus dikembangkan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Ini merupakan hal-hal yang menjadi kelemahan pendekar sulit berkonsentrasi, belum hafal urutan jurus, keengganan berlatih, dan tidak bisa membagi waktu.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene184;
