const Scene253: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  next: 254,
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Yeay selesai! Insha Allah rencana tindakan kita semua bisa terwujud yaa",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Karena semua materi telah tersampaikan, saatnya kami meminta umpan balik dari kakak",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Caranya mudah! cukup dengan memberikan bintang 1 sampai dengan 10, semakin banyak bintangnya, semakin baik nilainya ",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Pertanyaan pertama, seberapa puas rekan-rekan dengan pembelajaran di game ini? ",
      option: {
        name: "Rating",
        param: {
          variableCode: "501",
        },
      },
    },
    {
      characters: ["Alif (think)"],
      line: "Pertanyaan kedua, menurut rekan-rekan seberapa tinggi tingkat kesulitan dalam game ini?",
      option: {
        name: "Rating",
        param: {
          variableCode: "502",
        },
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Terima kasih atas umpan baliknya! semoga pembelajaran ini bermanfaat bagi rekan-rekan semua",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Silakan lanjut memainkan game lain ya, sampai jumpa!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene253;
