import materi from "../assets/contents/materi1practice6.jpg";

const Scene076: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "107",
  nextTrue: 77,
  nextFalse: 78,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Soal berikutnya mengenai Singawidya. Siap-siap, konsentrasi.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah Singawidya tergolong pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Bukan", "Belum tentu", "Tidak tahu"],
          values: [false, true, false, false],
          select: 1,
          variableCode: "107",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Karena dasarnya adalah dendam dan kekuasaan",
            "Karena ingin membuat padepokan menjadi nomor satu",
            "Karena punya keinginan untuk membuat perguruan lain mau bekerjasama",
            "Karena ia adalah pendekar yang tangguh dan pemberani",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "107",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene076;
