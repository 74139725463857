const Scene111: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 113,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Betul, tujuan Jagawana tidak memenuhi kriteria afektif karena itu adalah keinginan istrinya bukan keinginan Jagawana.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene111;
