const Scene224: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 225,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Kesulitanmu dalam membagi waktu untuk merekrut Ibu Idamaan dan memastikan angsuran dibayar tepat waktu, dapat dikategorikan sebagai...",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Weakness", "Opportunity", "Threat"],
          values: [false, true, false, false],
          select: 1,
          variableCode: "312",
        },
      },
    },
  ],
};

export default Scene224;
