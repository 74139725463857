import tidakLulus from "../assets/contents/tidakLulusPendekarMuda.jpg";

const Scene124: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmWarm1",
  sfx: "",
  next: 125,
  dialogues: [
    {
      characters: ["eyang guru (shock)"],
      line: "Ya Allah, kamu belum lolos kali ini. Ayo coba lagi untuk jadi pendekar muda! Semangat!",
      option: {
        name: "Zoom",
        param: {
          contents: [tidakLulus],
        },
      },
    },
  ],
};

export default Scene124;
