const Scene087: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  // next: 54, by pass
  next: 88,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullah! Eyang sungguh kecewa. Kamu tidak lolos dari latihan ini. Ayo jangan patah semangat, tonton kembali materi. Konsentrasi!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene087;
