const Scene165: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmFestiveDesa",
  sfx: "",
  variableCheck: "Scene165",
  nextTrue: 166,
  nextFalse: 167,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Gimana masih semangat? Mau coba lagi? ",
      option: {
        name: "Option",
        param: {
          options: [
            "Masih semangat tentunya! Ayo coba lagi.",
            "Sudah menyerah Eyang Idam.",
          ],
          values: [true, false],
          select: 1,
          variableCode: "Scene165",
        },
      },
    },
  ],
};

export default Scene165;
