import Jagawana from "../assets/Jagawana.png";
import Jagawana1 from "../assets/Jagawana 1.png";
import Janilaya from "../assets/Janilaya.png";
import Janilaya1 from "../assets/Janilaya 1.png";
import Marutama from "../assets/Marutama.png";
import Marutama1 from "../assets/Marutama 1.png";
import Singawidya from "../assets/Singawidya.png";
import Singawidya1 from "../assets/Singawidya 1.png";

const profiles = [
  {
    image: Jagawana,
    presetatif: true,
  },
  {
    image: Jagawana1,
    presetatif: true,
  },
  {
    image: Marutama,
    presetatif: true,
  },
  {
    image: Marutama1,
    presetatif: true,
  },
  {
    image: Janilaya,
    presetatif: false,
  },
  {
    image: Janilaya1,
    presetatif: false,
  },
  {
    image: Singawidya,
    presetatif: false,
  },
  {
    image: Singawidya1,
    presetatif: false,
  },
];

const duration = 40;

export { profiles, duration };
