const Scene030: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Larilah dengan cara mengetuk gambar pendekar secepat-cepatnya, dari titik A ke titik B. Jarak yang kamu tempuh adalah 200 m.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Mengerti?",
      option: {
        name: "Option",
        param: {
          options: ["Mengerti", "Paham sekali"],
          values: [true, true],
          select: 1,
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Bagus!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene030;
