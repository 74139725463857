import { useAtom } from "jotai";
import { opponentScoreAtom, playerScoreAtom } from "../stores";
import styles from "../styles/score.module.css";

const Score = () => {
  const [playerScore] = useAtom(playerScoreAtom);
  const [opponentScore] = useAtom(opponentScoreAtom);

  return (
    <div className={styles.Score}>
      <div className={styles.ScoreText}>
        <div>Anda</div>
        <div>
          <b>{playerScore}</b>
        </div>
      </div>
      <div className={styles.ScoreText}>
        <div>Lawan</div>
        <div>
          <b>{opponentScore}</b>
        </div>
      </div>
    </div>
  );
};

export default Score;
