import { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import { CharacterName } from ".";
import styles from "../styles/dialogue.module.css";

interface DialogueProps {
  line: string;
  characters: string[];
  onLoopDone: () => void;
  instant?: boolean;
  textClass?: string;
  containerClass?: string;
}

const Dialogue = ({
  line,
  characters,
  instant,
  onLoopDone,
  textClass,
  containerClass,
}: DialogueProps) => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    setDone(false);
  }, [line]);

  // const Lines = () => {
  //   const { text } = useTypewriter({
  //     words: [line],
  //     typeSpeed: instant ? 2 : 20,
  //     onLoopDone: () => {
  //       setDone(true);
  //       onLoopDone();
  //     },
  //   });

  //   return (
  //     <div
  //       style={{
  //         fontSize: line.length <= 240 ? "1.4em" : "1.2em",
  //       }}
  //       className={textClass ?? styles.dialogueText}
  //     >
  //       {done ? line : text}
  //     </div>
  //   );
  // };

  return (
    <div
      className={containerClass ?? styles.dialogueContainer}
      onClick={() => {
        setDone(true);
        onLoopDone();
      }}
    >
      <CharacterName
        characters={characters}
        containerClass={styles.characterName}
      />
      <div
        // style={{
        //   fontSize: line.length <= 240 ? "1.4em" : "1.2em",
        // }}
        className={textClass ?? styles.dialogueText}
      >
        {done ? (
          line
        ) : (
          <Typewriter
            words={[line]}
            typeSpeed={instant ? 2 : 20}
            onLoopDone={() => {
              setDone(true);
              onLoopDone();
            }}
          />
        )}
      </div>
      {/* <Lines /> */}
    </div>
  );
};

export default Dialogue;
