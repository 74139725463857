const Scene157: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 158,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Baik! Review sudah selesai. Sebentar lagi kamu akan mendapatkan latih-tanding dengan pendekar lain. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ini adalah latihan penggemblengan untuk menjadi pendekar Madya!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene157;
