const Scene172: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 173,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Maaf, kamu kalah. Tahu kenapa? Itu dikarenakan kamu tidak tahu kekuatan dan kelemahan diri sendiri dan lawan, sehingga kamu tidak punya strategi untuk menang. Ayo, tetap waspada!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene172;
