const Scene166: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  next: 168,
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Bagus! Itu baru namanya calon pendekar Madya! Siapkan diri lebih baik, karena ini adalah kesempatan terakhir. Kali ini lawanmu yang akan jalan terlebih dahulu, persiapkanlah strategi untuk melawannya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene166;
