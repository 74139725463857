import rangkuman from "../assets/contents/rangkumanMateri1.jpg";

const Scene089: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmFocus1",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Selamat datang kembali $[fullname]! Sesi belajar materi 1 dengan Eyang Guru sudah selesai.",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Bersama Eyang Guru, kakak sudah mempelajari materi untuk menjadi pribadi yang prestatif. Yuk, coba dibaca kembali rangkuman mengenai materi tersebut.",
      option: {
        name: "Zoom",
        param: {
          contents: [rangkuman],
        },
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Selanjutnya akan ada soal untuk menguji pemahaman kakak terhadap materi pribadi prestatif. Apakah sudah siap mengerjakan?",
      option: {
        name: "Option",
        param: {
          options: ["Siapp!", "Sudah siap sekali!"],
          values: [true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene089;
