const Scene220: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 221,
  dialogues: [
    {
      characters: ["Alif (mad)"],
      line: "Aduh... Eyang Guru pasti sedih nih.. jika Strength dan Weakness adalah aspek internal maka.. Opportunity & Threat adalah aspek ekternal ya...",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene220;
