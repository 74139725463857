import materi from "../assets/contents/materi1practice7.jpg";

const Scene079: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "108",
  nextTrue: 80,
  nextFalse: 81,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Pertanyaan selanjutnya adalah mengenai pendekar Marutama. Baca, pahami dan konsentrasi.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah Marutama melakukan cara yang tepat untuk menjadi pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Bukan", "Belum tentu", "Tidak tahu"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "108",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Karena tidak giat berlatih untuk mencapai tujuan",
            "Karena melakukan refleksi",
            "Karena hidupnya tidak seimbang",
            "Karena terlalu bertanggung jawab",
          ],
          values: [false, true, false, false],
          select: 1,
          variableCode: "108",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene079;
