const Scene130: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 132,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah. Betul. Tujuan tersebut sudah memenuhi kriteria peluang karena terdapat peluang berhasil maupun gagal.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene130;
