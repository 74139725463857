const Scene167: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  next: 168,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Segini aja nyali kamu? Kenapa takut kalah? Ayo coba lagi. Semangat! Kali ini lawanmu yang akan jalan terlebih dahulu, persiapkanlah strategi untuk melawannya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene167;
