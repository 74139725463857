import styles from "../styles/indicator.module.css";
import { trackLength } from "../stores/constant";

type IndicatorProps = {
  clickCount: number;
  icon?: string;
};

const Indicator = ({ clickCount, icon }: IndicatorProps) => {
  return (
    <div className={styles.indicator}>
      <div className={styles.indicator_text}>START</div>
      <div className={styles.progress}>
        <div
          className={styles.progress_value}
          style={{ width: `${(clickCount / trackLength) * 100}%` }}
        ></div>
        {icon ? (
          <img
            className={styles.progress_icon}
            style={{ left: `${(clickCount / trackLength) * 100}%` }}
            src={icon}
            alt="runner"
          />
        ) : (
          <div
            className={styles.progress_icon}
            style={{
              left: `${(clickCount / trackLength) * 100}%`,
              width: 32,
              height: 32,
              background: "black",
              // border: "3px solid #000",
              borderRadius: 9999,
            }}
          ></div>
        )}
      </div>
      <div className={styles.indicator_text}>FINISH</div>
    </div>
  );
};

export default Indicator;
