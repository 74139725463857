import materi1a from "../assets/videos/materi1a.mp4";
import materi1b from "../assets/videos/materi1b.mp4";

const SceneTontonMateri1: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmClassic2",
  sfx: "",
  next: 256,
  dialogues: [
    {
      characters: ["Humaira (normal)"],
      line: "Silahkan tonton telebih dahulu materi berikut.",
      option: {
        name: "Video",
        param: {
          src: materi1a,
        },
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Lanjut ke bagian kedua.",
      option: {
        name: "Video",
        param: {
          src: materi1b,
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Untuk bisa lulus, kakak harus menjawab pertanyaan ini dengan benar.",
      option: {
        name: null,
      },
    },
  ],
};

export default SceneTontonMateri1;
