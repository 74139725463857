import materi from "../assets/contents/materi2Practice7.jpg";

const Scene132: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "208",
  nextTrue: 133,
  nextFalse: 134,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Dalam kasus Marutama ini, pilih mana yang merupakan pernyataan tujuan yang lebih tepat?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Dalam kurun waktu 5 tahun, sudah berhasil menguasai 20 jurus pamungkas dari kitab pusaka.",
            "Dalam kurun waktu 5 tahun, ingin berhasil menguasai semua jurus pamungkas dari kitab pusaka.",
            "Sebelum berusia 40 tahun, berusaha menguasai semua jurus pamungkas dari kitab pusaka.",
            "Sebelum berusia 40 tahun, sangat bermimpi bisa menguasai 20 jurus pamungkas dari kitab pusaka.",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "208",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene132;
