import tidakLulusPendekarMadya from "../assets/contents/tidakLulusPendekarMadya.jpg";

const Scene193: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmWarm1",
  sfx: "",
  next: 194,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Ayo lebih serius! Ikut latihan lagi. Tetap semangat!",
      option: {
        name: "Zoom",
        param: {
          contents: [tidakLulusPendekarMadya],
        },
      },
    },
  ],
};

export default Scene193;
