const Scene178: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  next: 175,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullah. Fokus! Ayo tonton ulang materi video lagi",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene178;
