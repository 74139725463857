import styles from "../styles/runner.module.css";

type RunnerProps = {
  spritesheet: string;
  frameJson: FrameJSON;
  clickCount: number;
  onClick: () => void;
};

type FrameJSON = {
  frames: Array<Frame>;
};

type Frame = {
  x: number;
  y: number;
  w: number;
  h: number;
};

const Runner = ({
  spritesheet,
  frameJson,
  clickCount,
  onClick,
}: RunnerProps) => {
  return (
    <div
      id="runner" // Debug only
      onClick={onClick}
      className={styles.runner}
      style={{
        width: frameJson["frames"][clickCount % frameJson["frames"].length].w,
        height: frameJson["frames"][clickCount % frameJson["frames"].length].h,
        backgroundImage: `url(${spritesheet})`,
        backgroundPositionX: `-${
          frameJson["frames"][clickCount % frameJson["frames"].length].x
        }px`,
        backgroundPositionY: `-${
          frameJson["frames"][clickCount % frameJson["frames"].length].y
        }px`,
      }}
    ></div>
  );
};

export default Runner;
