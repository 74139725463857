const Scene045: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Ayo, semangat. Pendekar itu tidak boleh berpuas diri dan mudah menyerah.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Ubah dan tentukan kembali targetmu. Setelah itu bersiap untuk UJI KECEPATAN LARI!",
      option: {
        name: "Option",
        param: {
          options: ["$[speedChallange]", "$[targetAwalChallange]"],
          values: [true, true],
          select: 1,
          variableCode: "targetBaru",
        },
      },
    },
  ],
};

export default Scene045;
