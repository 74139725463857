const newAnswer = {
  value: false,
  text: "",
  index: -1,
};

const initialState = {
  speedRondeSatu: newAnswer,
  speedRondeDua: newAnswer,
  speedRondeTiga: newAnswer,
  targetAwal: newAnswer,
  targetBaru: newAnswer,
};

export default initialState;
