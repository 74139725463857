const Scene232: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  next: 233,
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Untuk menyegarkan pikiran sebelum mengisi post test, tantangan yang akan kami berikan adalah... PINGPONG!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Caranya mudah! segera pukul bola sebelum menghilang",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Siap?",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene232;
