const Scene021: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmChill1",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Wah! sudah selesai! Selamat ya!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Saya yakin nilainya pasti belum bagus! ",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (shock)"],
      line: "Lho? kok belum bagus?",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Karena belum belajar tentang Personal Effectiveness 1!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Apa sih yang akan dipelajari di Personal Effectiveness 1 ini?",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene021;
