const Scene237: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 238,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Untuk bisa menjadi seseorang yang terus berkembang, maka kita perlu ",
      option: {
        name: "Option",
        param: {
          options: [
            "Berani bertanggungjawab secara pribadi",
            "Jangan mempedulikan masukan orang lain",
            "Selalu ingin mengalahkan orang lain",
            "Boleh menghalalkan segala cara demi sebuah tujuan",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "115",
        },
      },
    },
  ],
};

export default Scene237;
