function goToSceneRemedial(materi_number: number) {
  switch (materi_number) {
    case 1:
      return 255;
    case 2:
      return 266;
    case 3:
      return 277;
    default:
      return 0;
  }
}

function isShouldRemedial(materi_number: number) {
  // Todo: Implement whether should remedial or not by checking assessment
  return true;
}

export { goToSceneRemedial, isShouldRemedial };
