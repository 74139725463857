const Scene017: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Atasan yang sabar dalam membimbing kita sebagai bawahannya, dalam SWOT termasuk ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Strength",
            "Opportunity",
            "Tidak tahu",
            "Support",
            "Obligation",
          ],
          values: [false, true, false, false, false],
          variableCode: "302",
          select: 1,
        },
      },
    },
  ],
};

export default Scene017;
