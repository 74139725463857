import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { send_rating } from "../services/rating";
import { variablesAtom, accessTokenAtom } from "../stores";
import { getVarCodes } from "../utils";
import { rating_codes } from "../utils/codes";

const questions: { [key: string]: string } = {
  "501":
    "Pertanyaan pertama, seberapa puas rekan-rekan dengan pembelajaran di game ini?",
  "502":
    "Pertanyaan kedua, menurut rekan-rekan seberapa tinggi tingkat kesulitan dalam game ini?",
};

const useRating = (sceneNumber: number) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [variables] = useAtom(variablesAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const alert = useAlert();

  useEffect(() => {
    const sendData = async () => {
      setIsInProgress(true);
      const list_var_codes = getVarCodes(sceneNumber);
      if (list_var_codes === rating_codes) {
        const statusList: number[] = [];
        for (let index = 0; index < list_var_codes.length; index++) {
          const answer = variables[list_var_codes[index]] as Answer;
          const { status } = await send_rating(
            accessToken,
            questions[list_var_codes[index]],
            answer.text
          );
          statusList.push(status);
        }

        if (
          statusList.length === list_var_codes.length &&
          statusList.every((status) => status === 200)
        ) {
          alert.success("Rating berhasil disimpan");
        } else {
          alert.error("Rating gagal disimpan");
        }
      }
      setIsInProgress(false);
    };
    sendData();
  }, [accessToken, alert, sceneNumber, variables]);

  return { isInProgress };
};

export default useRating;
