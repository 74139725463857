const Scene200: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "308",
  nextTrue: 201,
  nextFalse: 202,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Sebutkan 4 hal yang termasuk KESEMPATAN?",
      option: {
        name: "SelectPut",
        param: {
          title: "Kesempatan",
          options: [
            "Ajang kompetisi pendekar setiap tahun",
            "Ayah angkat yang mendukung",
            "Sarana transportasi yang mudah untuk ke kota mengikuti diskusi antar pendekar",
            "Catatan Eyang Guru tentang kekuatan dan kelemahan Siluman Tamak",
            "Keinginan yang tinggi untuk menolong penduduk desa",
            "Motivasi yang tinggi untuk mau berlatih terus",
            "Kecepatan menghindari serangan lawan",
            "Mampu diam tanpa bergerak selama 3 jam",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "308",
        },
      },
    },
  ],
};

export default Scene200;
