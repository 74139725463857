const Scene075: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 76,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim. Konsentrasi. Baca dan pahami materi.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Jawaban yang benar adalah Marutama seorang pendekar prestatif, karena mau mengenali diri sendiri dan menerima masukan dari orang lain.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Ayo, lebih fokus, lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene075;
