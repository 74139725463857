import { useEffect, useRef, useState } from "react";
import {
  initCards,
  npcFirst,
  playerFirst,
  positionX,
  together,
} from "./stores/constant";
import Card from "./components/Card";
import styles from "./styles/battle-card.module.css";
import { random } from "lodash";
import { useAtom } from "jotai";
import { variablesAtom } from "../../stores";

interface BattleCardProps {
  ronde: number;
  onFinish: (playerWin: boolean) => void;
}

const BattleCard = ({ ronde, onFinish }: BattleCardProps) => {
  const [showMessage, setShowMessage] = useState(false);
  const [playerScore, setPlayerScore] = useState(0);
  const [npcScore, setNpcScore] = useState(0);
  const [playerCards, setPlayerCards] = useState<string[]>(initCards);
  const [npcCards, setNpcCards] = useState<string[]>(initCards);
  const [playerDrawCard, setPlayerDrawCard] = useState("");
  const [npcDrawCard, setNpcDrawCard] = useState("");
  const npcFirstRef = useRef<NodeJS.Timeout>();
  const [, setVariables] = useAtom(variablesAtom);

  useEffect(() => {
    if (ronde === together || ronde === playerFirst) {
      setShowMessage(true);
    }
  }, [ronde]);

  useEffect(() => {
    // NPC First Algorithm
    if (
      ronde === npcFirst &&
      npcDrawCard === "" &&
      npcCards.length > 0 &&
      !npcFirstRef.current
    ) {
      npcFirstRef.current = setTimeout(() => {
        const randomCard = npcCards[random(0, npcCards.length - 1)];
        setNpcCards(npcCards.filter((name_) => name_ !== randomCard));
        setNpcDrawCard(randomCard);
        setShowMessage(true);
      }, 2000);
    }
  }, [npcCards, npcDrawCard, ronde]);

  useEffect(() => {
    // Winner Algorithm
    const whoIsWinner = (player_card: string, npc_card: string) => {
      if (player_card === "Super Power") {
        return npc_card !== "Super Power" ? "Player" : "Draw";
      }
      if (npc_card === "Super Power") {
        return player_card !== "Super Power" ? "NPC" : "Draw";
      }
      if (npc_card === player_card) {
        return "Draw";
      }

      return npc_card !== winnerCard(player_card) ? "Player" : "NPC";
    };

    if (playerDrawCard !== "" && npcDrawCard !== "") {
      const winner = whoIsWinner(playerDrawCard, npcDrawCard);
      if (winner === "NPC") {
        setNpcScore((score) => score + 1);
      } else if (winner === "Player") {
        setPlayerScore((score) => score + 1);
      }

      setTimeout(() => {
        setNpcDrawCard("");
        setPlayerDrawCard("");
        if (ronde === together || ronde === playerFirst) {
          setShowMessage(true);
        }
      }, 2000);
    }
  }, [npcDrawCard, playerDrawCard, ronde]);

  const winnerCard = (name: string) => {
    if (name === "Mata") return "Tiupan";
    else if (name === "Tiupan") return "Pukulan";
    else if (name === "Pukulan") return "Mata";
    else return "Super Power";
  };

  const onClickCard = (name: string) => {
    if (ronde === together && playerDrawCard === "") {
      setShowMessage(false);
      setPlayerCards(playerCards.filter((name_) => name_ !== name));
      setPlayerDrawCard(name);

      setNpcCards(npcCards.filter((name_) => name_ !== winnerCard(name)));
      setNpcDrawCard(winnerCard(name));
    } else if (ronde === playerFirst && playerDrawCard === "") {
      setShowMessage(false);
      // if (playerCards.length === 4) {
      //   setTimeout(() => {
      //     setNpcCards(npcCards.filter((name_) => name_ !== winnerCard(name)));
      //     setNpcDrawCard(winnerCard(name));
      //   }, 2000);
      // } else {
      //   setTimeout(() => {
      //     const randomCard = npcCards[random(0, npcCards.length - 1)];
      //     setNpcCards(npcCards.filter((name_) => name_ !== randomCard));
      //     setNpcDrawCard(randomCard);
      //   }, 2000);
      // }
      setTimeout(() => {
        setNpcCards(npcCards.filter((name_) => name_ !== winnerCard(name)));
        setNpcDrawCard(winnerCard(name));
      }, 2000);
      setPlayerCards(playerCards.filter((name_) => name_ !== name));
      setPlayerDrawCard(name);
    } else if (
      ronde === npcFirst &&
      npcDrawCard !== "" &&
      playerDrawCard === ""
    ) {
      setShowMessage(false);
      setPlayerCards(playerCards.filter((name_) => name_ !== name));
      setPlayerDrawCard(name);
      npcFirstRef.current = undefined;
    }
  };

  useEffect(() => {
    if (
      npcCards.length === 0 &&
      playerCards.length === 0 &&
      npcDrawCard === "" &&
      playerDrawCard === ""
    ) {
      setVariables((variables) => {
        const code = `BattleCard${ronde}`;
        return {
          ...variables,
          [code]: {
            index: -1,
            text: (playerScore > npcScore).toString(),
            value: playerScore > npcScore,
          },
        };
      });
      onFinish(playerScore > npcScore);
    }
  }, [
    npcCards.length,
    npcDrawCard,
    npcScore,
    onFinish,
    playerCards.length,
    playerDrawCard,
    playerScore,
    ronde,
    setVariables,
  ]);

  return (
    <div className={styles.container}>
      {showMessage && <div className={styles.message}>Silakan pilih kartu</div>}
      <div className={styles.playerScore}>
        <div>Player</div>
        <div>{playerScore}</div>
      </div>
      <div className={styles.npcScore}>
        <div>NPC</div>
        <div>{npcScore}</div>
      </div>
      <div className={styles.playerDrawCard}>
        {playerDrawCard !== "" && <Card hidden={false} name={playerDrawCard} />}
      </div>
      <div className={styles.npcDrawCard}>
        {npcDrawCard !== "" && <Card hidden={false} name={npcDrawCard} />}
      </div>
      <div className={styles.playerCards}>
        {playerCards.map((name) => (
          <Card
            hidden={false}
            x={positionX(name)}
            y={-40}
            key={"player" + name}
            name={name}
            onClick={() => onClickCard(name)}
          />
        ))}
      </div>
      <div className={styles.npcCards}>
        {npcCards.map((name) => (
          <Card
            hidden
            x={positionX(name)}
            y={40}
            key={"npc" + name}
            name={name}
          />
        ))}
      </div>
    </div>
  );
};

export default BattleCard;
