import materi from "../assets/contents/materi1practice8.jpg";

const Scene082: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "109",
  nextTrue: 83,
  nextFalse: 84,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Semangat! Pertanyaan berikutnya mengenai pendekar Singawidya. Baca, pahami dan konsentrasi.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah Marutama melakukan cara yang tepat untuk menjadi pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Bukan", "Belum tentu", "Tidak tahu"],
          values: [false, true, false, false],
          select: 1,
          variableCode: "109",
          varibleIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Karena tujuannya adalah menjadi penguasa padepokan ",
            "Karena ingin membuktikan bahwa dirinya adalah nomor satu",
            "Karena tidak ingin dianggap layak oleh pendekar lain",
            "Karena ingin melakukan yang terbaik untuk menumpas pemberontak",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "109",
          varibleIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene082;
