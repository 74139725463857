import { useEffect, useRef, useState } from "react";
import { duration, profiles } from "../stores/constant";
import styles from "../styles/siapa-presetatif.module.css";
import indicatorTidakPrestatif from "../assets/TidakPrestatif.png";
import indicatorPrestatif from "../assets/Prestatif.png";
import { shuffle } from "lodash";
import useSound from "use-sound";
import falseSound from "../assets/false.wav";
import trueSound from "../assets/true.wav";

interface Card {
  image: string;
  presetatif: boolean;
}

interface SwipeCardProps {
  onFinish: (score: number) => void;
}

const SwipeCard = ({ onFinish }: SwipeCardProps) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [cardSwiped, setCardSwiped] = useState(0);
  const [score, setScore] = useState(0);
  const [countdown, setCountdown] = useState(duration);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
  const touchStartRef = useRef(0);
  const countdownRef = useRef<NodeJS.Timeout>();

  const [playTrue] = useSound(trueSound);
  const [playFalse] = useSound(falseSound);

  useEffect(() => {
    const shuffledCards = shuffle(profiles);
    setCards(shuffledCards);
    cardsRef.current = cardsRef.current.slice(0, shuffledCards.length);

    countdownRef.current = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);
    return () => {
      if (countdownRef.current) clearInterval(countdownRef.current);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      onFinish(score);
    } else if (cardSwiped === profiles.length) {
      if (countdownRef.current) clearInterval(countdownRef.current);
      onFinish(score);
    }
  }, [cardSwiped, countdown, onFinish, score]);

  const onSwipeLeft = (index: number) => {
    if (cardsRef.current[index]) {
      cardsRef.current[index]?.classList.add(styles.cardLeft);
    }
    if (cards[index].presetatif) {
      setScore((score) => score - 2);
      playFalse();
    } else {
      setScore((score) => score + 10);
      playTrue();
    }
    setCardSwiped((swiped) => swiped + 1);
  };

  const onSwipeRight = (index: number) => {
    if (cardsRef.current[index]) {
      cardsRef.current[index]?.classList.add(styles.cardRight);
    }
    if (cards[index].presetatif) {
      setScore((score) => score + 10);
      playTrue();
    } else {
      setScore((score) => score - 2);
      playFalse();
    }
    setCardSwiped((swiped) => swiped + 1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.countdownContainer}>
          <div className={styles.countdownText}>SISA WAKTU</div>
          <div className={styles.countdownNumber}>{countdown}</div>
        </div>
        <div className={styles.scoreContainer}>
          <div className={styles.scoreText}>POIN</div>
          <div className={styles.scoreNumber}>{score}</div>
        </div>
      </div>
      <div className={styles.indicatorLeft}>
        <div className={styles.indicatorText}>TIDAK PRESTATIF</div>
        <img
          className={styles.indicatorImage}
          src={indicatorTidakPrestatif}
          alt="Tidak Prestatif"
        />
      </div>
      <div className={styles.indicatorRight}>
        <div className={styles.indicatorText}>PRESTATIF</div>
        <img
          className={styles.indicatorImage}
          src={indicatorPrestatif}
          alt="Prestatif"
        />
      </div>
      {cards.map((card, index) => {
        return (
          <img
            key={card.image}
            src={card.image}
            alt={card.image}
            className={cardSwiped >= index ? styles.card : styles.cardHide}
            ref={(el) => (cardsRef.current[index] = el)}
            onTouchStart={(e) => {
              touchStartRef.current = e.changedTouches[0].clientX;
            }}
            onTouchEnd={(e) => {
              if (e.changedTouches[0].clientX < touchStartRef.current) {
                onSwipeLeft(index);
              } else {
                onSwipeRight(index);
              }
            }}
            draggable={true}
            onDragStart={(e) => {
              touchStartRef.current = e.clientX;
            }}
            onDragEnd={(e) => {
              if (e.clientX < touchStartRef.current) {
                onSwipeLeft(index);
              } else {
                onSwipeRight(index);
              }
            }}
          />
          // <div
          //   key={card.image}
          //   onTouchStart={(e) => {
          //     touchStartRef.current = e.changedTouches[0].clientX;
          //   }}
          //   onTouchEnd={(e) => {
          //     if (e.changedTouches[0].clientX < touchStartRef.current) {
          //       onSwipeLeft(index);
          //     } else {
          //       onSwipeRight(index);
          //     }
          //   }}
          //   className={styles.card}
          //   ref={(el) => (cardsRef.current[index] = el)}
          // ></div>
        );
      })}
    </div>
  );
};

export default SwipeCard;
