const Scene053: Scene = {
  type: "Scene",
  background: "padepokanselaras",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Alhamdulillah! Selamat $[fullname] telah lolos seleksi awal calon pendekar AMAAN.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Silahkan masuk ke dalam kelas. Ayo kita mulai pelajaran pertama. Ucapkan, Bismillah.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene053;
