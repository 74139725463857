const Scene109: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmCalm",
  sfx: "",
  next: 110,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, calon pendekar AMAAN. SAATNYA LATIHAN!",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Jika berhasil lolos, kamu akan menjadi pendekar muda! Semangat! Ucapkan, Bismillah!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene109;
