const Scene112: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 113,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Astagfirullah. Bagaimana sih. Tentu saja Jagawana tidak lolos karena tidak memenuhi kriteria afektif. Tujuan itu adalah keinginan istrinya bukan keinginan Jagawana.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene112;
