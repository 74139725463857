import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import Ball from "./components/Ball";
import Bet from "./components/Bet";
import Modal from "./components/Modal";
import Score from "./components/Score";
import { playerScoreAtom, opponentScoreAtom, winnerAtom } from "./stores";
import hitSound from "./assets/sfx/hit.mp3";
import Petunjuk from "./components/Petunjuk";
import styles from "./styles/ping-pong.module.css";

interface PingPongProps {
  onFinish: () => void;
}

const PingPong = ({ onFinish }: PingPongProps) => {
  const [gameStart, setGameStart] = useState(false);

  const [playerScore] = useAtom(playerScoreAtom);
  const [opponentScore] = useAtom(opponentScoreAtom);
  const [, setWinner] = useAtom(winnerAtom);

  const bet = useRef<HTMLImageElement>(null);

  const [play] = useSound(hitSound);

  useEffect(() => {
    if (playerScore === 21) {
      setWinner("player");
      onFinish();
    } else if (opponentScore === 21) {
      setWinner("opponent");
      onFinish();
    }
  }, [playerScore, opponentScore, setWinner, onFinish]);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { clientX, clientY } = event;

    if (bet.current) {
      bet.current.style.display = "block";
      bet.current.style.left = `${clientX}px`;
      bet.current.style.top = `${clientY}px`;

      play();

      setTimeout(() => {
        if (bet.current) {
          bet.current.style.display = "none";
        }
      }, 500);
    }
  };

  const playGame = () => {
    setGameStart(true);
  };

  if (gameStart) {
    return (
      <div className={styles.container}>
        <Score />
        <Ball onClick={onClick} />
        <Bet ref={bet} />
        <Modal />
      </div>
    );
  }

  return <Petunjuk onClick={playGame} />;
};

export default PingPong;
