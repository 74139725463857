import { useEffect, useState } from "react";

const maxSceneNumber = 254;

const useProgress = (sceneNumber: number) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (sceneNumber > maxSceneNumber) {
      setProgress(100);
    } else {
      setProgress(Math.round((sceneNumber / maxSceneNumber) * 100));
    }
  }, [sceneNumber]);

  return { progress };
};

export default useProgress;
