const Scene095: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Selamat datang kembali $[fullname]! Sudah tidak sabar ya untuk materi selanjutnya? Yuk kita minta Eyang Guru Idam membuka portal menuju padepokan selaras!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene095;
