const Scene114: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 116,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Betul, tujuan Janilaya tidak lolos karena tujuannya tidak bermanfaat untuk seorang pendekar.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene114;
