const Scene008: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Dalam bertindak, seorang pribadi prestatif akan memilih tindakan yang ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Memiliki risiko sedang",
            "Memiliki risiko tinggi",
            "Tidak tahu",
            "Memiliki risiko rendah",
            "Menghindari risiko",
          ],
          values: [true, false, false, false, false],
          variableCode: "103",
          select: 1,
        },
      },
    },
  ],
};

export default Scene008;
