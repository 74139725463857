const SceneMauMencobaLagi: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  variableCheck: "lanjutKeMateri2",
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Maaf kakak masih belum lulus. Mau mencoba kembali?",
      option: {
        name: "Option",
        param: {
          options: ["Ya", "Tidak"],
          values: [true, false],
          select: 1,
          variableCode: "lanjutKeMateri2",
        },
      },
    },
  ],
};

export default SceneMauMencobaLagi;
