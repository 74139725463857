const Scene242: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 243,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Salah satu cara untuk menguji tujuan yang kita miliki adalah dengan menggunakan kriteria AMPUH yang merupakan singkatan dari ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Afektif, Manfaat, Peluang, Urgensi, Harmoni",
            "Afektif, Manfaat, Prestatif, Usaha, Hasil",
            "Ambisi, Modal, Peluang, Urgensi, Hasil",
            "Ambisi, Modal, Prestatif, Usaha, Harmoni",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "215",
        },
      },
    },
  ],
};

export default Scene242;
