const Scene219: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 221,
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Ya! Betul! Jika Strength & Weakness adalah internal, maka.. Opportunity dan Threat adalah aspek eksternal",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene219;
