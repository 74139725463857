const Scene051: Scene = {
  type: "Scene",
  background: "padepokanselaras",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Pertanyaan selanjutnya. Apa yang sudah kamu pelajari tentang usaha pencapaian target dalam 3 ronde tadi?",
      option: {
        name: "Option",
        param: {
          options: [
            "Manusia pada dasarnya ingin hasil terbaik",
            "Jika ada kesempatan untuk bisa memberikan hasil yang lebih baik, seharusnya dimanfaatkan dengan sebaik-baiknya",
            "Manusia selalu memiliki dorongan untuk terus maju dan lebih baik dari sebelumnya",
            "Manusia pada dasarnya punya potensi untuk bisa lebih baik, namun terkadang perlu didorong oleh orang lain",
          ],
          values: [true, true, true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene051;
