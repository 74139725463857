import rangkuman from "../assets/contents/rangkumanMateri2.jpg";

const Scene143: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmFocus1",
  sfx: "",
  next: 144,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Selamat datang kembali! selamat sudah lulus jadi pendekar muda! Saya dapat bocoran dari Eyang Guru tentang materi yang tadi dipelajari",
      option: {
        name: "Zoom",
        param: {
          contents: [rangkuman],
        },
      },
    },
  ],
};

export default Scene143;
