const Scene032: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmChillDesa1",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "BAGUS! Hasil kamu adalah $[speedRondeSatu] detik",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene032;
