import tidakLulusPendekarMadya from "../assets/contents/tidakLulusPendekarMadya.jpg";

const Scene208: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  // next: 175, by pass
  next: 209,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Ya Allah! Ayo, latihan lagi. Konsentrasi!",
      option: {
        name: "Zoom",
        param: {
          contents: [tidakLulusPendekarMadya],
        },
      },
    },
  ],
};

export default Scene208;
