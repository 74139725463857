const Scene163: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmFestiveDesa",
  sfx: "sfxChampion",
  next: 165,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Memang sebelum bertanding, kamu harus tahu aturannya dan INGAT, kamu harus tahu kekuatan dan kelemahan diri kamu sendiri. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene163;
