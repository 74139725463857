const Scene252: Scene = {
  type: "ActionPlan",
  background: "",
  bgm: "[continue]",
  sfx: "",
  next: 253,
  dialogues: [],
};

export default Scene252;
