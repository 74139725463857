import materi2a from "../assets/videos/materi2a.mp4";
import materi2b from "../assets/videos/materi2b.mp4";

const SceneTontonMateri2: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmClassic2",
  sfx: "",
  next: 267,
  dialogues: [
    {
      characters: ["Humaira (normal)"],
      line: "Silahkan tonton telebih dahulu materi berikut.",
      option: {
        name: "Video",
        param: {
          src: materi2a,
        },
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Lanjut ke bagian kedua.",
      option: {
        name: "Video",
        param: {
          src: materi2b,
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Untuk bisa lulus, jawab pertanyaan ini dengan sungguh-sungguh ya kak.",
      option: {
        name: null,
      },
    },
  ],
};

export default SceneTontonMateri2;
