const Scene215: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "Scene215",
  nextTrue: 216,
  nextFalse: 217,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Mana aspek SWOT yang termasuk internal? pilih 2 ya!",
      option: {
        name: "Option",
        param: {
          options: ["Strength", "Weakness", "Opportunity", "Threat"],
          values: [true, true, false, false],
          select: 2,
          variableCode: "Scene215",
        },
      },
    },
  ],
};

export default Scene215;
