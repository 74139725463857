import bgmActionDesa1 from "./bgmActionDesa1.aac";
import bgmActionDesa2 from "./bgmActionDesa2.aac";
import bgmCalm from "./bgmCalm.aac";
import bgmChill1 from "./bgmChill1.aac";
import bgmChill2 from "./bgmChill2.aac";
import bgmChill3 from "./bgmChill3.aac";
import bgmClassic2 from "./bgmClassic2.aac";
import bgmChillDesa1 from "./bgmChillDesa1.aac";
import bgmChillDesa2 from "./bgmChillDesa2.aac";
import bgmFestiveDesa from "./bgmFestiveDesa.aac";
import bgmFocus1 from "./bgmFocus1.aac";
import bgmFocusDesa1 from "./bgmFocusDesa1.aac";
import bgmFocusDesa2 from "./bgmFocusDesa2.aac";
import bgmGame1 from "./bgmGame1.aac";
import bgmThemeAmaan from "./bgmThemeAmaan.aac";
import bgmTransReal from "./bgmTransReal.aac";
import bgmSad1 from "./bgmSad1.aac";
import bgmWarm1 from "./bgmWarm1.aac";
import bgmVideoMateri1 from "./bgmVideoMateri1.aac";
import bgmVideoMateri2 from "./bgmVideoMateri2.aac";

const bgms: { [key: string]: string } = {
  bgmThemeAmaan: bgmThemeAmaan,
  bgmChill1: bgmChill1,
  bgmChill2: bgmChill2,
  bgmChill3: bgmChill3,
  bgmClassic2: bgmClassic2,
  bgmChillDesa1: bgmChillDesa1,
  bgmChillDesa2: bgmChillDesa2,
  bgmActionDesa1: bgmActionDesa1,
  bgmActionDesa2: bgmActionDesa2,
  bgmGame1: bgmGame1,
  bgmFocus1: bgmFocus1,
  bgmFocus2: bgmThemeAmaan,
  bgmFocusDesa1: bgmFocusDesa1,
  bgmFocusDesa2: bgmFocusDesa2,
  bgmVideoIntro: bgmThemeAmaan,
  bgmVideoPeri: bgmThemeAmaan,
  bgmVideoMateri1: bgmVideoMateri1,
  bgmVideoMateri2: bgmVideoMateri2,
  bgmTransDesa: bgmThemeAmaan,
  bgmTransReal: bgmTransReal,
  bgmFestiveDesa: bgmFestiveDesa,
  bgmWarm1: bgmWarm1,
  bgmSad1: bgmSad1,
  bgmCalm: bgmCalm,
};

export default bgms;
