import Scene000 from "./Scene000";
import Scene001 from "./Scene001";
import Scene002 from "./Scene002";
import Scene003 from "./Scene003";
import Scene004 from "./Scene004";
import Scene005 from "./Scene005";
import Scene006 from "./Scene006";
import Scene007 from "./Scene007";
import Scene008 from "./Scene008";
import Scene009 from "./Scene009";
import Scene010 from "./Scene010";
import Scene011 from "./Scene011";
import Scene012 from "./Scene012";
import Scene013 from "./Scene013";
import Scene014 from "./Scene014";
import Scene015 from "./Scene015";
import Scene016 from "./Scene016";
import Scene017 from "./Scene017";
import Scene018 from "./Scene018";
import Scene019 from "./Scene019";
import Scene020 from "./Scene020";
import Scene021 from "./Scene021";
import Scene022 from "./Scene022";
import Scene023 from "./Scene023";
import Scene024 from "./Scene024";
import Scene025 from "./Scene025";
import Scene026 from "./Scene026";
import Scene027 from "./Scene027";
import Scene028 from "./Scene028";
import Scene029 from "./Scene029";
import Scene030 from "./Scene030";
import Scene031 from "./Scene031";
import Scene032 from "./Scene032";
import Scene033 from "./Scene033";
import Scene034 from "./Scene034";
import Scene035 from "./Scene035";
import Scene036 from "./Scene036";
import Scene037 from "./Scene037";
import Scene038 from "./Scene038";
import Scene039 from "./Scene039";
import Scene040 from "./Scene040";
import Scene041 from "./Scene041";
import Scene042 from "./Scene042";
import Scene043 from "./Scene043";
import Scene044 from "./Scene044";
import Scene045 from "./Scene045";
import Scene046 from "./Scene046";
import Scene047 from "./Scene047";
import Scene048 from "./Scene048";
import Scene049 from "./Scene049";
import Scene050 from "./Scene050";
import Scene051 from "./Scene051";
import Scene052 from "./Scene052";
import Scene053 from "./Scene053";
import Scene054 from "./Scene054";
import Scene055 from "./Scene055";
import Scene056 from "./Scene056";
import Scene057 from "./Scene057";
import Scene058 from "./Scene058";
import Scene059 from "./Scene059";
import Scene060 from "./Scene060";
import Scene061 from "./Scene061";
import Scene062 from "./Scene062";
import Scene063 from "./Scene063";
import Scene064 from "./Scene064";
import Scene065 from "./Scene065";
import Scene066 from "./Scene066";
import Scene067 from "./Scene067";
import Scene068 from "./Scene068";
import Scene069 from "./Scene069";
import Scene070 from "./Scene070";
import Scene071 from "./Scene071";
import Scene072 from "./Scene072";
import Scene073 from "./Scene073";
import Scene074 from "./Scene074";
import Scene075 from "./Scene075";
import Scene076 from "./Scene076";
import Scene077 from "./Scene077";
import Scene078 from "./Scene078";
import Scene079 from "./Scene079";
import Scene080 from "./Scene080";
import Scene081 from "./Scene081";
import Scene082 from "./Scene082";
import Scene083 from "./Scene083";
import Scene084 from "./Scene084";
import Scene085 from "./Scene085";
import Scene086 from "./Scene086";
import Scene087 from "./Scene087";
import Scene088 from "./Scene088";
import Scene089 from "./Scene089";
import Scene090 from "./Scene090";
import Scene091 from "./Scene091";
import Scene092 from "./Scene092";
import Scene093 from "./Scene093";
import Scene094 from "./Scene094";
import Scene095 from "./Scene095";
import Scene096 from "./Scene096";
import Scene097 from "./Scene097";
import Scene098 from "./Scene098";
import Scene099 from "./Scene099";
import Scene100 from "./Scene100";
import Scene101 from "./Scene101";
import Scene102 from "./Scene102";
import Scene103 from "./Scene103";
import Scene104 from "./Scene104";
import Scene105 from "./Scene105";
import Scene106 from "./Scene106";
import Scene107 from "./Scene107";
import Scene108 from "./Scene108";
import Scene109 from "./Scene109";
import Scene110 from "./Scene110";
import Scene111 from "./Scene111";
import Scene112 from "./Scene112";
import Scene113 from "./Scene113";
import Scene114 from "./Scene114";
import Scene115 from "./Scene115";
import Scene116 from "./Scene116";
import Scene117 from "./Scene117";
import Scene118 from "./Scene118";
import Scene119 from "./Scene119";
import Scene120 from "./Scene120";
import Scene121 from "./Scene121";
import Scene122 from "./Scene122";
import Scene123 from "./Scene123";
import Scene124 from "./Scene124";
import Scene125 from "./Scene125";
import Scene126 from "./Scene126";
import Scene127 from "./Scene127";
import Scene128 from "./Scene128";
import Scene129 from "./Scene129";
import Scene130 from "./Scene130";
import Scene131 from "./Scene131";
import Scene132 from "./Scene132";
import Scene133 from "./Scene133";
import Scene134 from "./Scene134";
import Scene135 from "./Scene135";
import Scene136 from "./Scene136";
import Scene137 from "./Scene137";
import Scene138 from "./Scene138";
import Scene139 from "./Scene139";
import Scene140 from "./Scene140";
import Scene141 from "./Scene141";
import Scene142 from "./Scene142";
import Scene143 from "./Scene143";
import Scene144 from "./Scene144";
import Scene145 from "./Scene145";
import Scene146 from "./Scene146";
import Scene147 from "./Scene147";
import Scene148 from "./Scene148";
import Scene149 from "./Scene149";
import Scene150 from "./Scene150";
import Scene151 from "./Scene151";
import Scene152 from "./Scene152";
import Scene153 from "./Scene153";
import Scene154 from "./Scene154";
import Scene155 from "./Scene155";
import Scene156 from "./Scene156";
import Scene157 from "./Scene157";
import Scene158 from "./Scene158";
import Scene159 from "./Scene159";
import Scene160 from "./Scene160";
import Scene161 from "./Scene161";
import Scene162 from "./Scene162";
import Scene163 from "./Scene163";
import Scene164 from "./Scene164";
import Scene165 from "./Scene165";
import Scene166 from "./Scene166";
import Scene167 from "./Scene167";
import Scene168 from "./Scene168";
import Scene169 from "./Scene169";
import Scene170 from "./Scene170";
import Scene171 from "./Scene171";
import Scene172 from "./Scene172";
import Scene173 from "./Scene173";
import Scene174 from "./Scene174";
import Scene175 from "./Scene175";
import Scene176 from "./Scene176";
import Scene177 from "./Scene177";
import Scene178 from "./Scene178";
import Scene179 from "./Scene179";
import Scene180 from "./Scene180";
import Scene181 from "./Scene181";
import Scene182 from "./Scene182";
import Scene183 from "./Scene183";
import Scene184 from "./Scene184";
import Scene185 from "./Scene185";
import Scene186 from "./Scene186";
import Scene187 from "./Scene187";
import Scene188 from "./Scene188";
import Scene189 from "./Scene189";
import Scene190 from "./Scene190";
import Scene191 from "./Scene191";
import Scene192 from "./Scene192";
import Scene193 from "./Scene193";
import Scene194 from "./Scene194";
import Scene195 from "./Scene195";
import Scene196 from "./Scene196";
import Scene197 from "./Scene197";
import Scene198 from "./Scene198";
import Scene199 from "./Scene199";
import Scene200 from "./Scene200";
import Scene201 from "./Scene201";
import Scene202 from "./Scene202";
import Scene203 from "./Scene203";
import Scene204 from "./Scene204";
import Scene205 from "./Scene205";
import Scene206 from "./Scene206";
import Scene207 from "./Scene207";
import Scene208 from "./Scene208";
import Scene209 from "./Scene209";
import Scene210 from "./Scene210";
import Scene211 from "./Scene211";
import Scene212 from "./Scene212";
import Scene213 from "./Scene213";
import Scene214 from "./Scene214";
import Scene215 from "./Scene215";
import Scene216 from "./Scene216";
import Scene217 from "./Scene217";
import Scene218 from "./Scene218";
import Scene219 from "./Scene219";
import Scene220 from "./Scene220";
import Scene221 from "./Scene221";
import Scene222 from "./Scene222";
import Scene223 from "./Scene223";
import Scene224 from "./Scene224";
import Scene225 from "./Scene225";
import Scene226 from "./Scene226";
import Scene227 from "./Scene227";
import Scene228 from "./Scene228";
import Scene229 from "./Scene229";
import Scene230 from "./Scene230";
import Scene231 from "./Scene231";
import Scene232 from "./Scene232";
import Scene233 from "./Scene233";
import Scene234 from "./Scene234";
import Scene235 from "./Scene235";
import Scene236 from "./Scene236";
import Scene237 from "./Scene237";
import Scene238 from "./Scene238";
import Scene239 from "./Scene239";
import Scene240 from "./Scene240";
import Scene241 from "./Scene241";
import Scene242 from "./Scene242";
import Scene243 from "./Scene243";
import Scene244 from "./Scene244";
import Scene245 from "./Scene245";
import Scene246 from "./Scene246";
import Scene247 from "./Scene247";
import Scene248 from "./Scene248";
import Scene249 from "./Scene249";
import Scene250 from "./Scene250";
import Scene251 from "./Scene251";
import Scene252 from "./Scene252";
import Scene253 from "./Scene253";
import Scene254 from "./Scene254";
import SceneTontonMateri1 from "./SceneTontonMateri1";
import SceneTontonMateri2 from "./SceneTontonMateri2";
import SceneTontonMateri3 from "./SceneTontonMateri3";
import SceneCekHasilRemedial from "./SceneCekHasilRemedial";
import SceneLulusRemedial from "./SceneLulusRemedial";
import SceneMariMenontonLagi from "./SceneMariMenontonLagi";
import SceneMauMencobaLagi from "./SceneMauMencobaLagi";

const scenes: { [key: number]: Scene } = {
  0: Scene000,
  1: Scene001,
  2: Scene002,
  3: Scene003,
  4: Scene004,
  5: Scene005,
  6: Scene006,
  7: Scene007,
  8: Scene008,
  9: Scene009,
  10: Scene010,
  11: Scene011,
  12: Scene012,
  13: Scene013,
  14: Scene014,
  15: Scene015,
  16: Scene016,
  17: Scene017,
  18: Scene018,
  19: Scene019,
  20: Scene020,
  21: Scene021,
  22: Scene022,
  23: Scene023,
  24: Scene024,
  25: Scene025,
  26: Scene026,
  27: Scene027,
  28: Scene028,
  29: Scene029,
  30: Scene030,
  31: Scene031,
  32: Scene032,
  33: Scene033,
  34: Scene034,
  35: Scene035,
  36: Scene036,
  37: Scene037,
  38: Scene038,
  39: Scene039,
  40: Scene040,
  41: Scene041,
  42: Scene042,
  43: Scene043,
  44: Scene044,
  45: Scene045,
  46: Scene046,
  47: Scene047,
  48: Scene048,
  49: Scene049,
  50: Scene050,
  51: Scene051,
  52: Scene052,
  53: Scene053,
  54: Scene054,
  55: Scene055,
  56: Scene056,
  57: Scene057,
  58: Scene058,
  59: Scene059,
  60: Scene060,
  61: Scene061,
  62: Scene062,
  63: Scene063,
  64: Scene064,
  65: Scene065,
  66: Scene066,
  67: Scene067,
  68: Scene068,
  69: Scene069,
  70: Scene070,
  71: Scene071,
  72: Scene072,
  73: Scene073,
  74: Scene074,
  75: Scene075,
  76: Scene076,
  77: Scene077,
  78: Scene078,
  79: Scene079,
  80: Scene080,
  81: Scene081,
  82: Scene082,
  83: Scene083,
  84: Scene084,
  85: Scene085,
  86: Scene086,
  87: Scene087,
  88: Scene088,
  89: Scene089,
  90: Scene090,
  91: Scene091,
  92: Scene092,
  93: Scene093,
  94: Scene094,
  95: Scene095,
  96: Scene096,
  97: Scene097,
  98: Scene098,
  99: Scene099,
  100: Scene100,
  101: Scene101,
  102: Scene102,
  103: Scene103,
  104: Scene104,
  105: Scene105,
  106: Scene106,
  107: Scene107,
  108: Scene108,
  109: Scene109,
  110: Scene110,
  111: Scene111,
  112: Scene112,
  113: Scene113,
  114: Scene114,
  115: Scene115,
  116: Scene116,
  117: Scene117,
  118: Scene118,
  119: Scene119,
  120: Scene120,
  121: Scene121,
  122: Scene122,
  123: Scene123,
  124: Scene124,
  125: Scene125,
  126: Scene126,
  127: Scene127,
  128: Scene128,
  129: Scene129,
  130: Scene130,
  131: Scene131,
  132: Scene132,
  133: Scene133,
  134: Scene134,
  135: Scene135,
  136: Scene136,
  137: Scene137,
  138: Scene138,
  139: Scene139,
  140: Scene140,
  141: Scene141,
  142: Scene142,
  143: Scene143,
  144: Scene144,
  145: Scene145,
  146: Scene146,
  147: Scene147,
  148: Scene148,
  149: Scene149,
  150: Scene150,
  151: Scene151,
  152: Scene152,
  153: Scene153,
  154: Scene154,
  155: Scene155,
  156: Scene156,
  157: Scene157,
  158: Scene158,
  159: Scene159,
  160: Scene160,
  161: Scene161,
  162: Scene162,
  163: Scene163,
  164: Scene164,
  165: Scene165,
  166: Scene166,
  167: Scene167,
  168: Scene168,
  169: Scene169,
  170: Scene170,
  171: Scene171,
  172: Scene172,
  173: Scene173,
  174: Scene174,
  175: Scene175,
  176: Scene176,
  177: Scene177,
  178: Scene178,
  179: Scene179,
  180: Scene180,
  181: Scene181,
  182: Scene182,
  183: Scene183,
  184: Scene184,
  185: Scene185,
  186: Scene186,
  187: Scene187,
  188: Scene188,
  189: Scene189,
  190: Scene190,
  191: Scene191,
  192: Scene192,
  193: Scene193,
  194: Scene194,
  195: Scene195,
  196: Scene196,
  197: Scene197,
  198: Scene198,
  199: Scene199,
  200: Scene200,
  201: Scene201,
  202: Scene202,
  203: Scene203,
  204: Scene204,
  205: Scene205,
  206: Scene206,
  207: Scene207,
  208: Scene208,
  209: Scene209,
  210: Scene210,
  211: Scene211,
  212: Scene212,
  213: Scene213,
  214: Scene214,
  215: Scene215,
  216: Scene216,
  217: Scene217,
  218: Scene218,
  219: Scene219,
  220: Scene220,
  221: Scene221,
  222: Scene222,
  223: Scene223,
  224: Scene224,
  225: Scene225,
  226: Scene226,
  227: Scene227,
  228: Scene228,
  229: Scene229,
  230: Scene230,
  231: Scene231,
  232: Scene232,
  233: Scene233,
  234: Scene234,
  235: Scene235,
  236: Scene236,
  237: Scene237,
  238: Scene238,
  239: Scene239,
  240: Scene240,
  241: Scene241,
  242: Scene242,
  243: Scene243,
  244: Scene244,
  245: Scene245,
  246: Scene246,
  247: Scene247,
  248: Scene248,
  249: Scene249,
  250: Scene250,
  251: Scene251,
  252: Scene252,
  253: Scene253,
  254: Scene254,
  // Remedial Materi 1
  255: SceneTontonMateri1,
  256: { ...Scene236, next: 257 },
  257: { ...Scene237, next: 258 },
  258: { ...Scene238, next: 259 },
  259: { ...Scene239, next: 260 },
  260: { ...Scene240, next: 261 },
  261: Scene090,
  262: Scene091,
  263: Scene092,
  264: Scene093,
  265: SceneCekHasilRemedial,
  // Remedial Materi 2
  266: SceneTontonMateri2,
  267: { ...Scene241, next: 268 },
  268: { ...Scene242, next: 269 },
  269: { ...Scene243, next: 270 },
  270: { ...Scene244, next: 271 },
  271: { ...Scene245, next: 272 },
  272: { ...Scene145, next: 273 },
  273: { ...Scene146, next: 274 },
  274: { ...Scene147, next: 275 },
  275: { ...Scene148, next: 276 },
  276: SceneCekHasilRemedial,
  // Remedial Materi 3
  277: SceneTontonMateri3,
  278: { ...Scene246, next: 279 },
  279: { ...Scene247, next: 280 },
  280: { ...Scene248, next: 281 },
  281: { ...Scene249, next: 282 },
  282: { ...Scene250, next: 283 },
  283: { ...Scene222, next: 284 },
  284: { ...Scene223, next: 285 },
  285: { ...Scene224, next: 286 },
  286: { ...Scene225, next: 287 },
  287: { ...Scene226, next: 288 },
  288: { ...Scene227, next: 289 },
  289: { ...Scene228, next: 290 },
  290: { ...Scene229, next: 291 },
  291: SceneCekHasilRemedial,

  292: SceneLulusRemedial(1),
  293: { ...SceneMauMencobaLagi, nextTrue: 294, nextFalse: 254 },
  294: { ...SceneMariMenontonLagi, next: 255 },

  295: SceneLulusRemedial(2),
  296: { ...SceneMauMencobaLagi, nextTrue: 297, nextFalse: 254 },
  297: { ...SceneMariMenontonLagi, next: 266 },

  298: SceneLulusRemedial(3),
  299: { ...SceneMauMencobaLagi, nextTrue: 300, nextFalse: 254 },
  300: { ...SceneMariMenontonLagi, next: 277 },
};

export { scenes };
