import materi from "../assets/contents/materi2Practice1.jpg";

const Scene110: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "206",
  nextTrue: 111,
  nextFalse: 112,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Lihat dan cermati kasus Jagawana ini",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah tujuan Jagawana sudah lolos pengujian salah satu kriteria AMPUH?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [false, true],
          select: 1,
          variableCode: "206",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Karena sudah memenuhi kriteria Afektif",
            "Karena belum memenuhi kriteria Afektif",
            "Karena sudah memenuhi kriteria Harmoni",
            "Karena belum memenuhi kriteria Harmoni",
          ],
          values: [false, true, false, false],
          select: 1,
          variableCode: "206",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene110;
