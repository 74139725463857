import { useState } from "react";
import logo from "../assets/logo/logo.png";
import userIcon from "../assets/icons/user.png";
import passwordIcon from "../assets/icons/lock.png";
import Button from "../components/Button";
import Input from "../components/Input";
import styles from "../styles/login.module.css";
import { useAtom } from "jotai";
import { accessTokenAtom, variablesAtom } from "../stores";
import { get_point, get_profile, login } from "../services";
import { getJumpSceneNumber } from "../utils";
import { useAlert } from "react-alert";
import ls from "localstorage-slim";
import { xpAtom } from "../stores/variable";
import { PXLearningIdAtom } from "../stores/auth";

interface LoginProps {
  onStartGame: (sceneNumber: number) => void;
}

const Login = ({ onStartGame }: LoginProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [, setPXLearningId] = useAtom(PXLearningIdAtom);
  const [, setVariable] = useAtom(variablesAtom);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [, setXp] = useAtom(xpAtom);

  const demoMode = true;

  const [user, setUser] = useState<User>({
    fullname: "",
    sceneNumber: 0,
    xp: 0,
    progress: 0,
  });

  const requestProfile = async (token: string) => {
    // Get profile
    if (token !== "") {
      let name = "";
      let xp = -1;

      const { status, data } = await get_profile(token);
      if (status === 200) {
        setPXLearningId(data.px_learning_id);
        name = data.name;
      } else {
        console.error(data);
      }

      const { status: status_point, data: amount } = await get_point(token);
      if (status_point === 200) {
        xp = amount !== "" ? parseInt(amount) : 0;
      }

      if (name !== "" && xp !== -1) {
        alert.success("Login berhasil");
        setXp(xp);
        // Check cache
        const lastScene = ls.get("lastScene") as number;
        const lastLogin = ls.get("lastLogin") as string;
        if (lastScene && lastLogin === name) {
          setUser({
            fullname: name,
            sceneNumber: lastScene,
            xp: xp,
            progress: 0,
          });
        } else {
          ls.set("lastLogin", name);
          setUser({
            fullname: name,
            sceneNumber: data.last_assessment
              ? getJumpSceneNumber(data.last_assessment)
              : 1,
            xp: xp,
            progress: 0,
          });
        }
      }
    }
  };

  const onLogin = async () => {
    setLoading(true);
    if (demoMode) {
      setUser({
        fullname: "Test",
        sceneNumber: username !== "" ? parseInt(username) : 1,
        xp: 0,
        progress: 0,
      });
    } else {
      if (username !== "" && password !== "") {
        const { status, data } = await login(username, password);
        if (status === 200) {
          // Save acceess token
          setAccessToken(data);
          await requestProfile(data);
        } else {
          if (data === "invalid_grant") {
            alert.error("Username atau password salah");
          } else {
            alert.error(data);
          }
        }
      }
    }
    setLoading(false);
  };

  const onPlay = () => {
    setVariable((variable) => {
      return {
        ...variable,
        fullname: {
          index: -1,
          value: true,
          text: user.fullname,
        },
      };
    });
    onStartGame(user.sceneNumber);
  };

  if (user.fullname !== "" && user.sceneNumber === 1) {
    return (
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.formTitle}>Selamat datang, {user.fullname}!</div>
        <div className={styles.formSubtitle}>
          Untuk memainkan game secara optimal, gunakan perangkat tablet dalam
          posisi landscape dan klik tombol di bagian kiri bawah untuk tampilan
          full screen.
        </div>
        <Button text="Start" type="primary" onClick={onPlay} />
      </div>
    );
  }
  if (user.fullname !== "" && user.sceneNumber !== 1) {
    return (
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.formTitle}>
          Selamat datang kembali, {user.fullname}!
        </div>
        <div className={styles.formSubtitle}>
          Untuk memainkan game secara optimal, gunakan perangkat tablet dalam
          posisi landscape dan klik tombol di bagian kiri bawah untuk tampilan
          full screen.
        </div>
        <Button text="Continue" type="primary" onClick={onPlay} />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="logo" />
      <div className={styles.formTitle}>Demo Mode</div>
      {/* <div className={styles.formTitle}>Login</div> */}
      {/* <div className={styles.formContainer}>
        <Input
          type="text"
          placeholder="Username"
          icon={userIcon}
          onChange={setUsername}
        />
        <Input
          type="password"
          placeholder="Password"
          icon={passwordIcon}
          onChange={setPassword}
        />
      </div> */}
      <Button
        text={loading ? "Mohon tunggu" : "Login"}
        type="primary"
        onClick={onLogin}
        loading={loading}
      />
    </div>
  );
};

export default Login;

