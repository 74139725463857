async function send_rating(
  access_token: string,
  question: string,
  answer: string
) {
  const body = JSON.stringify({
    question,
    answer,
  });
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/update-px-survey",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body,
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data };
  } else {
    console.error({
      body,
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

export { send_rating };
