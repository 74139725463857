import styles from "../styles/card.module.css";
import tiupan from "../assets/wind.png";
import pukulan from "../assets/punch.png";
import mata from "../assets/eye.png";
import superCard from "../assets/super.png";
import hiddenCard from "../assets/back.png";

interface CardProps {
  name: string;
  hidden: boolean;
  x?: number | string;
  y?: number | string;
  onClick?: () => void;
}

function cardImage(name: string) {
  if (name === "Tiupan") {
    return tiupan;
  } else if (name === "Mata") {
    return mata;
  } else if (name === "Pukulan") {
    return pukulan;
  } else {
    return superCard;
  }
}

const Card = ({ name, hidden, x, y, onClick }: CardProps) => {
  if (x && y) {
    return (
      <img
        alt={name}
        style={{
          position: "absolute",
          left: x,
          top: y,
          transform: "translate(-50%, -50%)",
        }}
        className={styles.card}
        onClick={onClick}
        src={hidden ? hiddenCard : cardImage(name)}
      />
    );
  }
  return (
    <img
      className={styles.card}
      onClick={onClick}
      alt={name}
      src={hidden ? hiddenCard : cardImage(name)}
    />
  );
};

export default Card;
