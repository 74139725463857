interface CharacterNameProps {
  characters: string[];
  and?: string;
  containerClass?: string;
}

const CharacterName = ({
  characters,
  and,
  containerClass,
}: CharacterNameProps) => {
  if (characters.length === 0) {
    return <div></div>;
  }
  return (
    <div className={containerClass}>
      {characters.map((char, index) => {
        if (index === characters.length - 1 && characters.length > 1) {
          return (and ?? "dan ") + char;
        }
        return char + (characters.length > 1 ? ", " : "");
      })}
    </div>
  );
};

export default CharacterName;
