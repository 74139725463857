const newAnswer = {
  value: false,
  text: "",
  index: -1,
};

const initialState = {
  BattleCard1: newAnswer,
  BattleCard2: newAnswer,
  BattleCard3: newAnswer,
};

export default initialState;
