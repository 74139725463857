import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { showNextButtonAtom } from "../../stores";
import { ReactComponent as ZoomInButton } from "../../assets/buttons/zoom-in.svg";
import { ReactComponent as ZoomOutButton } from "../../assets/buttons/zoom-out.svg";
import { ReactComponent as PrevButton } from "../../assets/buttons/prev-slide.svg";
import { ReactComponent as NextButton } from "../../assets/buttons/next-slide.svg";
import styles from "../../styles/zoom.module.css";

interface ZoomProps {
  contents: string[];
  containerClass?: string;
  contentClass?: string;
  contentActiveClass?: string;
  handlerClass?: string;
  prevClass?: string;
  nextClass?: string;
  fullWidth?: boolean;
}

const Zoom = ({
  contents,
  containerClass,
  contentClass,
  contentActiveClass,
  handlerClass,
  prevClass,
  nextClass,
  fullWidth,
}: ZoomProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handle = useFullScreenHandle();

  const [, setShowNextButton] = useAtom(showNextButtonAtom);

  useEffect(() => {
    if (activeIndex === contents.length - 1) {
      setShowNextButton(true);
    }
  }, [activeIndex, contents.length, setShowNextButton]);

  const nextContent = useCallback(() => {
    setActiveIndex((idx) => {
      return idx + 1 < contents.length ? idx + 1 : 0;
    });
  }, [contents.length]);

  const prevContent = useCallback(() => {
    setActiveIndex((idx) => {
      return idx - 1 >= 0 ? idx - 1 : contents.length - 1;
    });
  }, [contents.length]);

  return (
    <FullScreen
      handle={handle}
      className={
        containerClass ??
        (fullWidth ? styles.zoomContainerFullWidth : styles.zoomContainer)
      }
    >
      {contents.map((content, index) => {
        return (
          <img
            style={{
              transition: "0.1s",
            }}
            className={
              index === activeIndex
                ? contentActiveClass ??
                  (handle.active
                    ? styles.zoomContentActiveFullScreen
                    : styles.zoomContentActive)
                : contentClass ??
                  (handle.active
                    ? styles.zoomContentFullScreen
                    : styles.zoomContent)
            }
            src={content}
            alt={content}
            key={content}
          />
        );
      })}
      {handle.active ? (
        <ZoomOutButton
          className={handlerClass ?? styles.zoomHandler}
          onClick={handle.exit}
        />
      ) : (
        <ZoomInButton
          className={handlerClass ?? styles.zoomHandler}
          onClick={handle.enter}
        />
      )}
      {contents.length > 1 && (
        <>
          <NextButton
            onClick={nextContent}
            className={nextClass ?? styles.zoomNext}
          />
          <PrevButton
            className={prevClass ?? styles.zoomPrev}
            onClick={prevContent}
          />
        </>
      )}
    </FullScreen>
  );
};

export default Zoom;

