const Scene131: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 132,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Astagfirullah. Ya salah dong. Tentu saja Singawidya lolos uji! Ia memenuhi kriteria peluang. Di mana ada peluang berhasil dan gagal yang sama besar. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene131;
