const Scene170: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "bgmFestiveDesa",
  sfx: "",
  next: 171,
  dialogues: [
    {
      characters: ["eyang guru (shock)"],
      line: "Hahaha kalah? Ya sudah, terima. Kalah menang itu hal biasa. Ayo bangkit, semangat! Pendekar tidak gampang menyerah, masih akan ada pertandingan lainnya. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ya sudah, terima. Kalah menang itu hal biasa. Ayo bangkit, semangat! Pendekar tidak gampang menyerah, masih akan ada pertandingan lainnya. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene170;
