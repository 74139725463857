const Scene060: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 61,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim. Fokus! Pahami materi.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Jawaban yang benar adalah Jagawana dikategorikan pendekar prestatif karena selalu belajar dan berlatih untuk meningkatkan kemampuannya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Ayo, lebih fokus, lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene060;
