const Scene195: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 197,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Ya betul. Lanjutkan latihannya ya",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene195;
