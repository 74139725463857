const Scene066: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 67,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim. Salah! Baca yang teliti! Tingkatkan konsentrasi.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Jagawana sudah melakukan cara yang tepat untuk menjadi pendekar prestatif, karena selalu berpikir maju dan lebih baik, serta berani mengambil risiko dan tidak cepat puas. Paham?",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Ayo, lebih fokus, lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene066;
