const Scene050: Scene = {
  type: "Scene",
  background: "padepokanselaras",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Bagus! Bangga, Eyang, kamu memang calon pendekar AMAAN. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (proud)"],
      line: "Dari 4 pilihan tadi, Intinya semua benar. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (proud)"],
      line: "Seorang pendekar itu wajib dan harus berani menetapkan target, kalau bisa di atas standar. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (proud)"],
      line: "Setelah itu sebagai pendekar harus mengetahui kemampuan yang dimiliknya, agar dapat menetapkan target yang lebih baik dari sebelumnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene050;
