import materi from "../assets/contents/materi2Practice7jawaban.jpg";

const Scene133: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 135,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Pintar. Benar, tujuan yang dipilih lebih tepat karena sudah memenuhi seluruh kriteria BTS Hasil",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene133;
