const Scene023: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Betul sekali! dan bukan dengan kita, tapi dengan sahabat lama kita yang kita hormati, Eyang Guru Idam!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Asal jangan terlalu asik saja berada di sana lalu lupa kembali ke kelas ini",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Tidak mungkin lupa! Karena nanti setiap selesai belajar 1 materi, Eyang Guru Idam akan mengirim kakak pulang dulu ke kelas ini",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (normal)"],
      line: "Oh iya betul! Berapa lama sih biasanya belajar 1 materi bersama Eyang Idam?",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "1 materi biasanya selesai dalam 1 kali Jumat Belajar, tapi kalau mau menyelesaikan semua materi dalam 1 kali Jumat Belajar boleh lho!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Oh begitu! jadi 1 game ini harusnya selesai dalam waktu 3-4x Jumat Belajar ya!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Sudah.. sudah.. tidak perlu lama-lama.. yuk kita minta Eyang Guru Idam membuka portal menuju Padepokan Selaras!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (shock)"],
      line: "Suara apa itu....",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene023;
