import { useAtom } from "jotai";
import { winnerAtom } from "../stores";
import styles from "../styles/modal.module.css";

const Modal = () => {
  const [winner] = useAtom(winnerAtom);

  return (
    <div
      className={
        styles.Modal +
        " " +
        (winner === "" ? styles.ModalHide : styles.ModalShow)
      }
    >
      {winner === "player" ? "You Win!" : "You Lose!"}
    </div>
  );
};

export default Modal;
