const Scene104: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 105,
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Bagus. Alhamdulillah. Jawaban kamu benar 2 XP untuk kamu!",
      xp: 2,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Yang membuat trio pendekar Idamaan berhasil menjadi versi terbaik dari dirinya adalah punya mimpi dan sabar untuk terus fokus mencapai mimpinya.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Kamu juga harus punya misi pribadi. Cari apa mimpimu.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene104;
