import tujuanPE1 from "../assets/contents/TujuanPE1.jpg";
import materiPE1 from "../assets/contents/MateriPE1.jpg";

const Scene022: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmChill1",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Wah! banyak dan menarik sekali! Secara detailnya, tujuan dari pelatihan bisa dilihat pada gambar berikut ini ya.",
      option: {
        name: "Zoom",
        param: {
          contents: [tujuanPE1],
        },
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Untuk bisa mencapai tujuan tersebut ada 3 materi yang akan kakak pelajari : Menjadi Pribadi Prestatif, Memiliki Tujuan Pribadi dan Personal SWOT",
      option: {
        name: "Zoom",
        param: {
          contents: [materiPE1],
        },
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Seru sekali! bagaimana cara belajarnya nanti?",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Bagaimana yaa? tadi sudah disebutkan sebenarnya...",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (proud)"],
      line: "Melalui permainan ya? ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene022;
