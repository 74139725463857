const Scene029: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Sekarang kamu harus mengikuti seleksi calon pendekar AMAAN.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Seleksi awal adalah UJI KECEPATAN LARI.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Tujuannya untuk mengukur seberapa cepat kamu berlari, karena untuk menjadi pendekar perlu stamina yang kuat agar dapat mengalahkan musuh!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene029;
