const Scene187: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 188,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Konsentrasi. Salah. Jawabannya adalah...",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Kesempatan atau opportunity merupakan hal-hal di luar diri yang bila didapatkan bisa memperbesar peluang untuk mencapai misi pribadi.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Kesempatan yang dimiliki untuk menjadi pendekar AMAAN yang hebat di padepokan selaras adalah Eyang Guru yang bersedia mengajar, lawan yang sedang cedera, pendekar seperguruan yang mau jadi lawan tanding, dan kitab berisikan ajaran ilmu silat tingkat tinggi.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene187;
