import { useEffect, useRef, useState } from "react";
import styles from "../styles/background.module.css";
interface BackgroundProps {
  src: string;
  transition?: "dark" | "light";
  onEnded?: () => void;
  loop?: boolean;
}

const Background = ({ src, transition, loop, onEnded }: BackgroundProps) => {
  const [loading, setLoading] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const loadVideoRef = useRef<XMLHttpRequest>();

  useEffect(() => {
    if (videoRef.current && !loadVideoRef.current && src.includes("mp4")) {
      setLoading(0);
      videoRef.current.src = "";
      loadVideoRef.current = new XMLHttpRequest();
      loadVideoRef.current.open("GET", src, true);
      loadVideoRef.current.responseType = "blob";

      loadVideoRef.current.onload = function () {
        if (this.status === 200) {
          var videoBlob = this.response;
          var vid = URL.createObjectURL(videoBlob);
          if (videoRef.current) {
            videoRef.current.src = vid;
            videoRef.current.play();
            videoRef.current.onended = () => {
              if (onEnded) {
                onEnded();
              }
            };
            loadVideoRef.current = undefined;
          }
        }
      };

      loadVideoRef.current.onprogress = function (e) {
        if (e.lengthComputable) {
          var percentComplete = (e.loaded / e.total) * 100;
          setLoading(Math.round(percentComplete));
        }
      };

      loadVideoRef.current.send();
    }
  }, [onEnded, src]);

  if (!src) {
    return <div></div>;
  }

  if (src.includes("jpg") || src.includes("jpeg") || src.includes("png")) {
    // Image
    return (
      <>
        <div
          className={styles.backgroundImage}
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></div>
        {transition && (
          <div
            className={styles.overlay}
            style={{
              background:
                transition === "dark"
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 0.6)",
            }}
          ></div>
        )}
      </>
    );
  } else if (src.includes("mp4")) {
    // Video
    return (
      <>
        {loading !== 100 && (
          <div className={styles.loading}>Loading {loading}%</div>
        )}
        <video
          ref={videoRef}
          loop={loop}
          className={styles.backgroundVideo}
        ></video>
        {transition && (
          <div
            className={styles.overlay}
            style={{
              background:
                transition === "dark"
                  ? "rgba(0, 0, 0, 0.6)"
                  : "rgba(255, 255, 255, 0.6)",
            }}
          ></div>
        )}
      </>
    );
  }
  throw new Error("File not supported");
};

export default Background;
