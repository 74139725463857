const Scene071: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 88,
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Selamat. Alhamdulillah. Eyang bangga sekali kamu sudah berhasil melawati tahap latihan. Ini 20 XP untuk kamu. Untuk saat ini, pelajaran kita sudahi dulu.",
      xp: 20,
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (proud)"],
      line: "Sekarang, kamu akan bertemu dengan Suhu di kelas training virtual. Jaga sikap dan tetap waspada. Jangan malu-maluin, Eyang.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene071;
