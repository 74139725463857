const Scene226: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 227,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Dari pernyataan berikut ini, manakah yang termasuk Strength seorang CDP ? (Silahkan pilih 4 pernyataan)",
      option: {
        name: "Option",
        param: {
          options: [
            "Keterampilan dalam membagi waktu untuk meeting dan turun ke lapangan",
            "Kreativitas dalam mencari cara-cara agar Ibu-ibu tidak telat dalam membayar angsuran",
            "Keterampilan  untuk bisa mengajarkan Ibu-ibu agar bisa menggunakan aplikasi AMAAN",
            "Keterbukaan dalam menerima masukan dari Ibu-ibu agar pengelolaan kelompok berjalan dengan baik",
            "Perusahaan yang berganti-ganti kebijakan sehingga membuat bingung orang di lapangan",
            "Atasan yang tidak memberitahukan perubahan SOP",
            "Peraturan baru dalam bisnis pembiayaan",
            "Tidak memahami SOP dengan baik",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "314",
        },
      },
    },
  ],
};

export default Scene226;
