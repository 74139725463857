import lulusPendekarMadya from "../assets/contents/lulusPendekarMadya.jpg";

const Scene192: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 209,
  dialogues: [
    {
      characters: ["eyang guru (proud)"],
      line: "Alhamdulillah! Memang kerja keras tidak pernah meninggalkan hasil. Selamat kamu berhasil melewati latihan dengan baik. Tambahan 25 XP dari Eyang. Sekarang kamu sudah berhasil menjadi pendekar Madya!",
      xp: 25,
      option: {
        name: "Zoom",
        param: {
          contents: [lulusPendekarMadya],
        },
      },
    },
  ],
};

export default Scene192;
