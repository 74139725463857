import { MouseEventHandler } from "react";
import styles from "../styles/button.module.css";

interface ButtonProps {
  text: string;
  type: "next" | "primary" | "secondary";
  onClick?: MouseEventHandler;
  buttonClass?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button = ({
  text,
  type,
  buttonClass,
  onClick,
  disabled,
  loading,
}: ButtonProps) => {
  if (loading) {
    return (
      <button
        style={{ opacity: 0.5, pointerEvents: "none" }}
        className={
          buttonClass ??
          (type === "secondary" ? styles.buttonSecondary : styles.buttonPrimary)
        }
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
  if (type === "next") {
    return (
      <button
        className={buttonClass ?? styles.nextButton}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  } else {
    return (
      <button
        className={
          buttonClass ??
          (type === "secondary" ? styles.buttonSecondary : styles.buttonPrimary)
        }
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
};

export default Button;
