const Scene250: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 251,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Berikut ini merupakan komponen dalam kompetensi, KECUALI ...",
      option: {
        name: "Option",
        param: {
          options: ["Kemampuan", "Pengetahuan", "Keterampilan", "Sikap"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "322",
        },
      },
    },
  ],
};

export default Scene250;
