const Scene177: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 179,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Pertahankan. Ini 10 XP dari Eyang. Persiapkan diri kamu untuk latihan selanjutnya.",
      xp: 10,
      option: {
        name: null,
      },
    },
  ],
};

export default Scene177;
