const Scene243: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 244,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Memastikan adanya keselarasan di antara setiap tujuan yang kita miliki, merupakan penjelasan dari ...",
      option: {
        name: "Option",
        param: {
          options: ["Afektif", "Peluang", "Selaras", "Harmoni"],
          values: [false, false, false, true],
          select: 1,
          variableCode: "216",
        },
      },
    },
  ],
};

export default Scene243;
