import { useAtom } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import ballImage from "../assets/images/ball.png";
import { ballDuration, opponentScoreAtom, playerScoreAtom } from "../stores";
import styles from "../styles/ball.module.css";
import hitSound from "../assets/sfx/hit.mp3";
import failSound from "../assets/sfx/fail.mp3";

interface BallProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Ball = ({ onClick }: BallProps) => {
  const [playerScore, setPlayerScore] = useAtom(playerScoreAtom);
  const [opponentScore, setOpponentScore] = useAtom(opponentScoreAtom);

  const [ballState, setBallState] = useState({
    x: 0,
    y: 0,
    show: false,
  });
  const createBallTimer = useRef<NodeJS.Timeout | null>(null);
  const clearBallTimer = useRef<NodeJS.Timeout | null>(null);

  const hitRef = useRef<HTMLAudioElement>(null);
  const failRef = useRef<HTMLAudioElement>(null);

  const createBall = useCallback(() => {
    const randX = Math.round(
      Math.random() * (window.innerWidth - 120 - 120) + 120
    );
    const randY = Math.round(
      Math.random() * (window.innerHeight - 120 - 120) + 120
    );
    setBallState({
      x: randX,
      y: randY,
      show: true,
    });
    if (hitRef.current) hitRef.current.play();

    const timeToCreate = Math.round(
      Math.random() * (5000 - ballDuration) + ballDuration
    );
    createBallTimer.current = setTimeout(() => {
      createBall();
    }, timeToCreate);
  }, []);

  const clickBall = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setBallState({
      x: 0,
      y: 0,
      show: false,
    });
    if (clearBallTimer.current) {
      setPlayerScore((prev) => prev + 1);
      clearTimeout(clearBallTimer.current);
      clearBallTimer.current = null;
      onClick(event);
    }
  };

  useEffect(() => {
    createBall();
  }, [createBall]);

  useEffect(() => {
    if (
      ballState.x !== 0 &&
      ballState.y !== 0 &&
      clearBallTimer.current == null
    ) {
      clearBallTimer.current = setTimeout(() => {
        setBallState({
          x: 0,
          y: 0,
          show: false,
        });
        setOpponentScore((prev) => prev + 1);
        clearBallTimer.current = null;
        if (failRef.current) failRef.current.play();
      }, ballDuration);
    }
  }, [ballState.x, ballState.y, setOpponentScore]);

  useEffect(() => {
    if (playerScore === 21 || opponentScore === 21) {
      if (createBallTimer.current) {
        clearTimeout(createBallTimer.current);
      }
    }
  }, [playerScore, opponentScore]);

  return (
    <>
      <audio ref={hitRef} key="hit">
        <source src={hitSound} type="audio/mp3" />
      </audio>
      <audio ref={failRef} key="fail">
        <source src={failSound} type="audio/mp3" />
      </audio>
      <img
        style={{
          top: ballState.y,
          left: ballState.x,
          display: ballState.show ? "block" : "none",
        }}
        className={styles.Ball}
        src={ballImage}
        alt="ball"
        onClick={clickBall}
      />
    </>
  );
};

export default Ball;
