import prolog from "../assets/videos/prolog.mp4";

const Scene025: Scene = {
  type: "Transition",
  background: prolog,
  bgm: "",
  sfx: "",
  dialogues: [
    {
      characters: [""],
      line: "",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene025;
