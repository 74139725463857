import { useEffect, useRef, useState } from "react";
import background from "./assets/background.jpg";
import runner from "./assets/runner/runner.png";
import frameJson from "./assets/runner/spritesheet.json";
import { trackLength } from "./stores/constant";
import Background from "./components/Background";
import Indicator from "./components/Indicator";
import Runner from "./components/Runner";
import Countdown from "./components/Countdown";
import { useAtom } from "jotai";
import { variablesAtom } from "../../stores";

interface UjiLariProps {
  ronde: number;
  onFinish: (speed: number) => void;
}

const UjiLari = ({ ronde, onFinish }: UjiLariProps) => {
  const [, setVariables] = useAtom(variablesAtom);
  const [clickCount, setClickCount] = useState(0);
  const startRef = useRef(0);

  useEffect(() => {
    const startTime = setTimeout(() => {
      startRef.current = new Date().getTime();
    }, 3000);
    return () => clearTimeout(startTime);
  }, []);

  useEffect(() => {
    if (clickCount === trackLength) {
      const speed = Math.round(
        (new Date().getTime() - startRef.current) / 1000
      );
      const data: Answer = {
        index: -1,
        text: speed.toString(),
        value: true,
      };
      if (ronde === 1) {
        setVariables((variables) => {
          return {
            ...variables,
            speedRondeSatu: data,
          };
        });
      } else if (ronde === 2) {
        setVariables((variables) => {
          return {
            ...variables,
            speedRondeDua: data,
          };
        });
      } else if (ronde === 3) {
        setVariables((variables) => {
          return {
            ...variables,
            speedRondeTiga: data,
          };
        });
      }
      onFinish(speed);
    }
  }, [clickCount, onFinish, ronde, setVariables]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Countdown />
      <Background url={background} clickCount={clickCount} />
      <Indicator clickCount={clickCount} />
      <Runner
        spritesheet={runner}
        frameJson={frameJson}
        onClick={() => setClickCount((prev) => prev + 1)}
        clickCount={clickCount}
      />
    </div>
  );
};

export default UjiLari;
