import styles from "../styles/character-images.module.css";

interface CharacterImagesProps {
  characters: string[];
  containerClass?: string;
  imageClass?: string;
}

const CharacterImages = ({
  characters,
  containerClass,
  imageClass,
}: CharacterImagesProps) => {
  if (characters.filter((char) => char === undefined).length > 0) {
    return <div></div>;
  }
  return (
    <div className={containerClass ?? styles.characterContainer}>
      {characters.map((char) => {
        return (
          <img
            key={char}
            className={imageClass ?? styles.characterImage}
            src={char}
            alt={char}
          />
        );
      })}
    </div>
  );
};

export default CharacterImages;
