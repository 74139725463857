const Scene034: Scene = {
  type: "Scene",
  background: "lapangan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (shock)"],
      line: "Apa? Itu target yang kamu pilih?",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (shock)"],
      line: "Pendekar itu harus percaya diri!",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo, pilih kembali target yang lebih tepat!",
      option: {
        name: "Option",
        param: {
          options: ["20 detik", "35 detik", "40 detik", "60 detik"],
          values: [20, 35, 40, 60],
          select: 1,
          variableCode: "targetAwal",
        },
      },
    },
  ],
};

export default Scene034;
