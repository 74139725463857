import transisi from "../assets/videos/transisi1ke2kelas.mp4";

const Scene151: Scene = {
  type: "Transition",
  background: transisi,
  bgm: "",
  sfx: "",
  dialogues: [
    {
      characters: [""],
      line: "",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene151;
