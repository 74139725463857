import materi2b from "../assets/videos/materi2b.mp4";

const Scene106: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmFocusDesa1",
  sfx: "",
  variableCheck: "Scene106",
  nextTrue: 107,
  nextFalse: 108,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Tujuan pribadi merupakan hal yang sengaja dan sadar diinginkan",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo kita lanjutkan menonton video bagian 2",
      option: {
        name: "Video",
        param: {
          src: materi2b,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Kamu sudah selesai menonton bagian 2 kan, ayo jawab pertanyaan Eyang terkait materi yang sudah dipelajari",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa singkatan kriteria pengujian tujuan adalah ? ",
      option: {
        name: "Option",
        param: {
          options: ["AMPUH", "SMART", "BTS HASIL", "MANJUR"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene106",
          variableIndex: 0,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Lalu, apa singkatan kriteria penulisan tujuan ? ",
      option: {
        name: "Option",
        param: {
          options: ["AMPUH", "SMART", "BTS HASIL", "MANJUR"],
          values: [false, false, true, false],
          select: 1,
          variableCode: "Scene106",
          variableIndex: 1,
        },
      },
    },
  ],
};

export default Scene106;
