import alifnormal from "./Alif (normal).png";
import alifhappy from "./Alif (happy).png";
import alifsad from "./Alif (sad).png";
import alifmad from "./Alif (mad).png";
import alifthink from "./Alif (think).png";
import alifproud from "./Alif (proud).png";
import alifshock from "./Alif (shock).png";
import humairanormal from "./Humaira (normal).png";
import humairahappy from "./Humaira (happy).png";
import humairasad from "./Humaira (sad).png";
import humairamad from "./Humaira (mad).png";
import humairathink from "./Humaira (think).png";
import humairashock from "./Humaira (shock).png";
import humairaproud from "./Humaira (proud).png";
import eyanggurunormal from "./eyang guru (normal).png";
import eyangguruhappy from "./eyang guru (happy).png";
import eyanggurusad from "./eyang guru (sad).png";
import eyanggurumad from "./eyang guru (mad).png";
import eyangguruthink from "./eyang guru (think).png";
import eyanggurushock from "./eyang guru (shock).png";
import eyangguruproud from "./eyang guru (proud).png";

const characters: { [key: string]: string } = {
  "Alif (normal)": alifnormal,
  "Alif (happy)": alifhappy,
  "Alif (sad)": alifsad,
  "Alif (mad)": alifmad,
  "Alif (think)": alifthink,
  "Alif (shock)": alifshock,
  "Alif (proud)": alifproud,

  "Humaira (normal)": humairanormal,
  "Humaira (happy)": humairahappy,
  "Humaira (sad)": humairasad,
  "Humaira (mad)": humairamad,
  "Humaira (think)": humairathink,
  "Humaira (shock)": humairashock,
  "Humaira (proud)": humairaproud,

  "eyang guru (normal)": eyanggurunormal,
  "eyang guru (happy)": eyangguruhappy,
  "eyang guru (sad)": eyanggurusad,
  "eyang guru (mad)": eyanggurumad,
  "eyang guru (think)": eyangguruthink,
  "eyang guru (shock)": eyanggurushock,
  "eyang guru (proud)": eyangguruproud,
};

export default characters;
