const Scene191: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Saatnya pengumuman nilai. Terima kasih kamu sudah mengikuti latihan. ",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene191;
