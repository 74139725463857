const Scene230: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmGame1",
  sfx: "",
  next: 231,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Terima kasih $[fullname] sudah mengerjakan uji pengetahuan tentang personal SWOT",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Tidak terasa pelatihan Personal Effectiveness 1 sudah hampir selesai lho!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Setelah ini, kakak diminta untuk mengisi post test, action plan, dan evaluasi. Namun... ada 1 tantangan lagi yang perlu dilakukan",
      option: {
        name: null,
      },
    },
    {
      characters: ["Alif (happy)"],
      line: "Waah! apa itu.. jadi tidak sabar...",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene230;
