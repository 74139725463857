const Scene229: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 230,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Dari pernyataan berikut ini, manakah yang termasuk Threat seorang CDP ? (Silahkan pilih 4 pernyataan)",
      option: {
        name: "Option",
        param: {
          options: [
            "Ibu-ibu yang tidak disiplin mengikuti silaturahmi rutin",
            "Aparat yang tidak memberi ijin operasi AMAAN di wilayahnya",
            "Koneksi internet yang sering hilang di wilayah tertentu",
            "Lembaga pembiayaan lain yang ingin masuk ke wilayah yang sama",
            "Ketidakmampuan meyakinkan aparat untuk menerima keberadaan AMAAN di wilayah mereka",
            "Belum terampil dalam melakukan wawancara untuk menilai apakah Ibu-ibu layak menerima pembiayaan",
            "Sering melakukan kesalahan pencatatan di aplikasi",
            "Ketidakmampuan membawakan pelatihan bagi Ibu-ibu secara menarik",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "317",
        },
      },
    },
  ],
};

export default Scene229;
