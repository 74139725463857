import Jagawana from "../games/SiapaPrestatif/assets/Jagawana.png";
import Jagawana1 from "../games/SiapaPrestatif/assets/Jagawana 1.png";
import Marutama from "../games/SiapaPrestatif/assets/Marutama.png";
import Marutama1 from "../games/SiapaPrestatif/assets/Marutama 1.png";

const Scene099: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa2",
  sfx: "",
  next: 100,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Dari cerita pendekar tersebut Jagawana dan Marutama merupakan pendekar dengan memiliki ciri dan cara yang perstarif. Kamu masih ingat kan? Kamu harus jadi seperti mereka dan kalahkan siluman Tamak.",
      option: {
        name: "Zoom",
        param: {
          contents: [Jagawana, Jagawana1, Marutama, Marutama1],
        },
      },
    },
  ],
};

export default Scene099;
