const Scene217: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 218,
  dialogues: [
    {
      characters: ["Humaira (sad)"],
      line: "Wah! kakak kurang menyimak penjelasan Eyang Guru ya.. yang merupakan aspek internal adalah Strength dan Weakness",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene217;
