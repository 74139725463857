const Scene153: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 154,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Sebelum lanjut ke materi selanjutnya, ayo kita review sedikit materi mengenai misi pribadi. Pada setiap pertanyaan akan ada tambahan XP jika kamu berhasil menjawab dengan benar.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Baik, sekarang sebutkan apa yang dimaksud dengan tujuan pribadi?",
      xp: 2,
      option: {
        name: "Option",
        param: {
          options: [
            "Tujuan yang secara sengaja dan sadar diinginkan",
            "Tujuan yang ingin dicapai oleh diri sendiri",
            "Tujuan yang pencapaiannya tidak melibatkan orang lain",
            "Tujuan yang didasari oleh keinginan bersama",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "Scene153",
        },
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Ayo diingat baik-baik ya.. tujuan pribadi adalah tujuan yang secara sengaja dan sadar diinginkan",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene153;
