const Scene196: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 197,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Ya Allah. Salah. Yang benar adalah fisik yang bugar, semangat berlatih yang tinggi, daya tangkap yang baik, dan bisa tidak makan dan minum selama 3 hari.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene196;
