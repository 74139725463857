import { useAtom } from "jotai";
import { useRef, useState } from "react";
import logo from "../assets/logo/logo.png";
import { Button } from "../components";
import { download, request, status } from "../services";
import { accessTokenAtom } from "../stores";
import styles from "../styles/end.module.css";
import pdf1 from "../assets/pdf/Materi 1 - Menjadi Pribadi Prestatif.pdf";
import pdf2 from "../assets/pdf/Materi 2 - Menetapkan Tujuan Pribadi.pdf";
import pdf3 from "../assets/pdf/Materi 3 - Personal SWOT.pdf";
import { useAlert } from "react-alert";
import { goToSceneRemedial, isShouldRemedial } from "../utils/remedial";

interface EndProps {
  onRemedial: (scene_number: number) => void;
}

const End = ({ onRemedial }: EndProps) => {
  const [accessToken] = useAtom(accessTokenAtom);
  const checkStatusRef = useRef<NodeJS.Timeout>();
  const [waitingPDF, setWaitingPDF] = useState(false);
  const alert = useAlert();

  const requestAP = async () => {
    setWaitingPDF(true);
    const { status, data } = await request(accessToken);
    if (status === 200) {
      return data;
    } else return null;
  };

  const checkStatusAP = async (task_id: string) => {
    const { status: status_code, data } = await status(accessToken, task_id);
    if (status_code === 200) {
      return data.task_status;
    } else return null;
  };

  const downloadAP = async (task_id: string) => {
    const { status, data } = await download(accessToken, task_id);
    if (status === 200) {
      const a = document.createElement("a");
      a.href = data.file;
      a.download = data.file.split("/").pop();
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setWaitingPDF(false);
    }
  };

  const onDownloadAP = async () => {
    const task_id = await requestAP();
    if (task_id !== null) {
      checkStatusRef.current = setInterval(async () => {
        let status_ap = await checkStatusAP(task_id);
        if (status_ap === "SUCCESS") {
          clearInterval(checkStatusRef.current as NodeJS.Timeout);
          downloadAP(task_id);
        }
      }, 1000);
    }
  };

  const onRemedialMateri = (materi_number: number) => {
    const remedial = isShouldRemedial(materi_number);
    if (!remedial) {
      alert.info("Kamu sudah lulus, tidak perlu mengulang kembali!");
      return;
    }
    onRemedial(goToSceneRemedial(materi_number));
  };

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="logo" />
      <div className={styles.title}>The End</div>
      <div className={styles.subtitle}>
        Silahkan ketuk tombol di bawah ini untuk mengakses materi ajar dan
        action plan.
        <br />
        Halaman masih bisa diakses ketika kamu login kembali untuk game 1.
      </div>
      <div className={styles.buttonContainer}>
        <a href={pdf1} download="Materi 1 - Menjadi Pribadi Prestatif.pdf">
          <Button
            text="Download PDF Materi 1"
            type="primary"
            buttonClass={styles.button}
          />
        </a>
        <a href={pdf2} download="Materi 2 - Menetapkan Tujuan Pribadi.pdf">
          <Button
            text="Download PDF Materi 2"
            type="primary"
            buttonClass={styles.button}
          />
        </a>
        <a href={pdf3} download="Materi 3 - Personal SWOT.pdf">
          <Button
            text="Download PDF Materi 3"
            type="primary"
            buttonClass={styles.button}
          />
        </a>
        <a href="#" onClick={onDownloadAP}>
          <Button
            text={waitingPDF ? "File sedang dibuat" : "Download Action Plan"}
            type="primary"
            buttonClass={styles.button}
            // onClick={onDownloadAP}
            loading={waitingPDF}
          />
        </a>
      </div>
      <br />
      <div className={styles.buttonContainer}>
        <Button
          text="Menjadi Pribadi Prestatif"
          type="primary"
          buttonClass={`${styles.buttonRemedial} ${styles.miniText}`}
          onClick={() => onRemedialMateri(1)}
        />
        <Button
          text="Menetapkan Tujuan Pribadi"
          type="primary"
          buttonClass={styles.buttonRemedial}
          onClick={() => onRemedialMateri(2)}
        />

        <Button
          text="Personal SWOT"
          type="primary"
          buttonClass={styles.buttonRemedial}
          onClick={() => onRemedialMateri(3)}
        />
      </div>
    </div>
  );
};

export default End;

