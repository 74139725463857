import materi from "../assets/contents/materi2Practice8jawaban.jpg";

const Scene137: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 138,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Salah dong. Tujuan yang kamu pilih tidak memenuhi kriteria hasil! Perhatikan penjelasan berikut ini! Konsentrasi!",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene137;
