const Scene043: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Sekarang, ayo, tentukan targetmu untuk UJI KECEPATAN LARI berikutnya.",
      option: {
        name: "Option",
        param: {
          options: [
            "$[speedRondeDuaPlus]",
            "$[targetAwalPlus]",
            "$[speedChallange]",
            "$[targetAwalChallange]",
          ],
          values: [false, false, true, true],
          select: 1,
          variableCode: "targetBaru",
        },
      },
    },
  ],
};

export default Scene043;
