import materi from "../assets/contents/materi1practice4.jpg";

const Scene067: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "109",
  nextTrue: 68,
  nextFalse: 69,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Sekarang tentang pendekar Janilaya. Baca dan pahami.",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah Janilaya melakukan cara yang tepat untuk menjadi pendekar prestatif?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Ya caranya sudah tepat",
            "Belum tepat",
            "Belum sepenuhnya tepat",
            "Tidak tahu",
          ],
          values: [false, true, false, false],
          select: 1,
          variableCode: "109",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Selalu berpikir untuk maju dan lebih baik",
            "Berpikir jangka panjang",
            "Mau mendengarkan masukan orang lain",
            "Karena lebih mementingkan relasi harmonis",
          ],
          values: [false, false, false, true],
          select: 1,
          variableCode: "109",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene067;
