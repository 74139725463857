import materi from "../assets/contents/materi1prove3.jpg";

const Scene092: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Silahkan dibaca telebih dahulu profil CDP bernama Legirah berikut",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Berdasarkan cerita di samping, apakah Legirah tergolong CDP yang prestatif",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [true, false],
          select: 1,
          variableCode: "112",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["Humaira (think)"],
      line: "Apa alasan kakak menggolongkan Legirah seperti jawaban sebelumnya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Capaian target sering melebihi standar",
            "Berusaha mencari terobosan dari kesulitan yang terjadi",
            "Selalu meminta bantuan dari orang lain",
            "Memiliki niat mulia untuk membantu orang lain",
          ],
          values: [true, true, false, false],
          select: 1,
          variableCode: "112",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene092;
