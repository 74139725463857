import materi2a from "../assets/videos/materi2a.mp4";

const Scene105: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  next: 106,
  dialogues: [
    {
      characters: ["eyang guru (normal)"],
      line: "Untuk bisa menentukan misi pribadi dengan tepat, Eyang sudah menyiapkan materi video.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (normal)"],
      line: "Lihat, dengar dan pelajari baik-baik. Akan ada pertanyaan setelah materi diberikan.",
      option: {
        name: "Video",
        param: {
          src: materi2a,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Ehm... udah menonton videonya kan. Sekarang jawab pertanyaan, Eyang. Tujuan pribadi adalah sesuatu yang?",
      option: {
        name: "Option",
        param: {
          options: [
            "Sengaja dan sadar diinginkan",
            "Diimpikan oleh banyak orang",
            "Baik dan berguna",
            "Amanah dan bermanfaat",
          ],
          values: [true, false, false, false],
          select: 1,
        },
      },
    },
  ],
};

export default Scene105;
