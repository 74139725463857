import rangkuman from "../assets/contents/rangkuman1.jpg";
import rangkumanMateri1 from "../assets/contents/rangkumanMateri1.jpg";
import rangkumanMateri2 from "../assets/contents/rangkumanMateri2.jpg";
import rangkumanMateri3 from "../assets/contents/rangkumanMateri3.jpg";

const Scene235: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "Scene235",
  nextTrue: 236,
  nextFalse: 235,
  dialogues: [
    {
      characters: ["Humaira (normal)"],
      line: "Sebelumnya mari kita ingat-ingat kembali materi kita di Pelatihan Personal Effectiveness 1 ini yaa",
      option: {
        name: "Zoom",
        param: {
          contents: [
            rangkuman,
            rangkumanMateri1,
            rangkumanMateri2,
            rangkumanMateri3,
          ],
        },
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Setelah membaca rangkuman tadi semoga kakak kembali ingat mengenai materi yang sudah dipelajari di Pelatihan Personal Effectiveness 1 ini ya",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (normal)"],
      line: "Sudah siap untuk post test?",
      option: {
        name: "Option",
        param: {
          options: [
            "Ya sudah siap!",
            "Siap, aku akan berusaha yang terbaik!",
            "Belum, mau kembali ke rangkuman.",
          ],
          values: [true, true, false],
          select: 1,
          variableCode: "Scene235",
        },
      },
    },
  ],
};

export default Scene235;
