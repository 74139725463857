// interface ActionPlan {
//   title: string;
//   subtitle: string;
//   questions: string[];
//   variableCode: string[];
//   note?: string;
// }

const page_1 = [
  {
    title: "MISI PRIBADI SAYA SEBAGAI CDP",
    subtitle: "berikut adalah gambaran ideal diri saya selama menjadi CDP",
    questions: [
      "Prestasi yang telah saya raih..",
      "Tabungan yang  saya miliki..",
      "Aset yang saya miliki..",
      "Pengetahuan yang saya miliki..",
      "Keterampilan yang saya kuasai..",
      "Nilai yang saya utamakan..",
    ],
    variableCodes: ["401", "402", "403", "404", "405", "406"],
    maxLength: [50, 50, 50, 50, 50, 50],
  },
  {
    title: "ANALISIS SWOT UNTUK MEWUJUDKAN MISI PRIBADI",
    subtitle:
      "analisis terhadap hal-hal yang bisa mendukung atau menghambat pencapaian misi",
    questions: [
      "Kekuatan yang saya miliki",
      "Kelemahan yang saya miliki",
      "Peluang yang bisa saya manfaatkan",
      "Ancaman yang mungkin terjadi",
    ],
    variableCodes: ["407", "408", "409", "410"],
    maxLength: [100, 100, 100, 100],
    note: "Catatan khusus : Kekuatan dan kelemahan adalah sesuatu yang berasal dari dalam diri, sedangkan peluang dan ancaman adalah sesuatu yang berasal dari luar diri",
  },
  {
    title: "RENCANA PENGEMBANGAN DIRI",
    subtitle:
      "hal-hal yang bisa saya lakukan untuk bisa mewujudkan misi pribadi",
    questions: [
      "Cara saya mempertahankan kekuatan adalah..",
      "Cara saya mengatasi kelemahan adalah..",
      "Cara saya memanfaatkan peluang yang ada..",
      "Cara saya menghindari atau mengatasi ancaman yang ada..",
    ],
    variableCodes: ["411", "412", "413", "414"],
    maxLength: [100, 100, 100, 100],
  },
];

const page_2 = [
  {
    title: "MISI PRIBADI SAYA 3 TAHUN SETELAH CDP",
    subtitle:
      "berikut adalah gambaran ideal diri saya 3 tahun setelah selesai sebagai CDP",
    questions: [
      "Prestasi yang telah saya raih..",
      "Tabungan yang  saya miliki..",
      "Aset yang saya miliki..",
      "Pengetahuan yang saya miliki..",
      "Keterampilan yang saya kuasai..",
      "Nilai yang saya utamakan..",
    ],
    variableCodes: ["415", "416", "417", "418", "419", "420"],
    maxLength: [50, 50, 50, 50, 50, 50],
  },
  {
    title: "ANALISIS SWOT UNTUK MEWUJUDKAN MISI PRIBADI",
    subtitle:
      "analisis terhadap hal-hal yang bisa mendukung atau menghambat pencapaian misi",
    questions: [
      "Kekuatan yang saya miliki",
      "Kelemahan yang saya miliki",
      "Peluang yang bisa saya manfaatkan",
      "Ancaman yang mungkin terjadi",
    ],
    variableCodes: ["421", "422", "423", "424"],
    maxLength: [100, 100, 100, 100],
    note: "Catatan khusus : Kekuatan dan kelemahan adalah sesuatu yang berasal dari dalam diri, sedangkan peluang dan ancaman adalah sesuatu yang berasal dari luar diri",
  },
  {
    title: "RENCANA PENGEMBANGAN DIRI",
    subtitle:
      "hal-hal yang bisa saya lakukan untuk bisa mewujudkan misi pribadi",
    questions: [
      "Cara saya mempertahankan kekuatan adalah..",
      "Cara saya mengatasi kelemahan adalah..",
      "Cara saya memanfaatkan peluang yang ada..",
      "Cara saya menghindari atau mengatasi ancaman yang ada..",
    ],
    variableCodes: ["425", "426", "427", "428"],
    maxLength: [100, 100, 100, 100],
  },
];

export { page_1, page_2 };
