const Scene009: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Berikut ini, pernyataan yang benar tentang salah satu ciri pribadi prestatif adalah...",
      option: {
        name: "Option",
        param: {
          options: [
            "Bersedia menerima masukan dari orang lain",
            "Tidak bersedia menerima masukan orang lain",
            "Tidak tahu",
            "Bersedia menerima masukan yang baik-baik saja",
            "Berhati-hati terhadap masukan orang lain",
          ],
          values: [true, false, false, false, false],
          variableCode: "104",
          select: 1,
        },
      },
    },
  ],
};

export default Scene009;
