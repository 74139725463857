const Scene152: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmChillDesa1",
  sfx: "",
  next: 153,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Assalammualaikum wahai calon pendekar AMAAN! Terima kasih tetap semangat untuk belajar bersama Eyang. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Tapi, apakah kamu masih ingat materi sebelumnya yang sudah dipelajari?",
      option: {
        name: "Option",
        param: {
          options: ["Tentu saja ingat!", "Masih ragu antara ingat atau tidak."],
          values: [true, true],
          select: 1,
        },
      },
    },
  ],
};

export default Scene152;
