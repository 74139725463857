const Scene108: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmSad1",
  sfx: "",
  // next: 106, by pass
  next: 109,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Ya Allah, jawabanmu banyak yang kurang tepat. Ayo ulang tonton video Tujuan Pribadi!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene108;
