const Scene102: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "Scene102",
  nextTrue: 104,
  nextFalse: 103,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Nah, sekarang jawab pertanyaan Eyang. Apakah betul kesamaan dari 3 pendekar itu adalah punya mimpi yang ingin dicapai?",
      option: {
        name: "Option",
        param: {
          options: ["Benar", "Salah"],
          values: [true, false],
          select: 1,
          variableCode: "Scene102",
          variableIndex: 0,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Pertanyaan selanjutnya. Apakah betul kesamaan dari 3 pendekar itu adalah membutuhkan waktu yang sebentar untuk mencapai mimpinya? ",
      option: {
        name: "Option",
        param: {
          options: ["Benar", "Salah"],
          values: [false, true],
          select: 1,
          variableCode: "Scene102",
          variableIndex: 1,
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Lalu, apakah betul, mimpi yang dimiliki oleh pendekar itu membantu dalam mengarahkan dirinya menjadi versi terbaiknya?",
      option: {
        name: "Option",
        param: {
          options: ["Benar", "Salah"],
          values: [true, false],
          select: 1,
          variableCode: "Scene102",
          variableIndex: 2,
        },
      },
    },
  ],
};

export default Scene102;
