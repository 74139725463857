import selamatDatang from "../assets/contents/SelamatDatang.jpg";

const Scene001: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmChill3",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (happy)"],
      line: "Selamat datang, $[fullname]! Perkenalkan nama saya Alif, dan ini adalah rekan saya...",
      option: {
        name: "Zoom",
        param: {
          contents: [selamatDatang],
        },
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Humaira! kami berdua akan mendampingi kakak dalam program pelatihan ini..",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Metode pelatihan kita kali ini memang berbeda dengan pelatihan lain. Seperti apa ya bedanya..",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene001;
