const Scene083: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 85,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Alhamdulillah! Bagus!",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (happy)"],
      line: "Baik, kita lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene083;
