import materi from "../assets/contents/materi2Practice2.jpg";

const Scene113: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "207",
  nextTrue: 114,
  nextFalse: 115,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Baik, selanjutnya cermati kasus Janilaya ini",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apakah tujuan Jagawana sudah lolos pengujian salah satu kriteria AMPUH?",
      option: {
        name: "OptionWithContent",
        param: {
          options: ["Ya", "Tidak"],
          values: [false, true],
          select: 1,
          variableCode: "207",
          variableIndex: 0,
          contents: [materi],
        },
      },
    },
    {
      characters: ["eyang guru (think)"],
      line: "Apa alasannya?",
      option: {
        name: "OptionWithContent",
        param: {
          options: [
            "Karena sudah memenuhi kriteria Peluang",
            "Karena belum memenuhi kriteria Peluang",
            "Karena sudah memenuhi kriteria Manfaat",
            "Karena belum memenuhi kriteria Manfaat",
          ],
          values: [false, false, false, true],
          select: 1,
          variableCode: "207",
          variableIndex: 1,
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene113;
