import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { showNextButtonAtom } from "../../stores";
import styles from "../../styles/video.module.css";

interface VideoProps {
  src: string;
  containerClass?: string;
  videoClass?: string;
  //   controlClass?: string;
}

const Video = ({ src, containerClass, videoClass }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  //   const controlRef = useRef<HTMLDivElement>(null);
  const [, setShowNextButton] = useAtom(showNextButtonAtom);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
    // if (videoRef.current && controlRef.current) {
    //   controlRef.current.style.display = "none";
    //   videoRef.current.load();
    //   videoRef.current.onloadedmetadata = () => {
    //     if (videoRef.current && controlRef.current) {
    //       controlRef.current.style.display = "flex";
    //       controlRef.current.style.width = `${videoRef.current.clientWidth}px`;
    //     }
    //   };
    // }
  }, [src]);

  return (
    <div className={containerClass ?? styles.videoContainer}>
      {/* <div ref={controlRef} className={controlClass}>
        <button onClick={() => onTogglePlay()}>
          {play ? "Pause" : "Play"}
        </button>
        <button onClick={() => null}>Fullscreen</button>
      </div>
      <progress></progress> */}
      <video
        ref={videoRef}
        className={videoClass ?? styles.videoBox}
        controls
        controlsList="noplaybackrate nodownload noremoteplayback"
        disablePictureInPicture
        onEnded={() => {
          setShowNextButton(true);
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default Video;
