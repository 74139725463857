import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { upload_multiple } from "../services";
import { accessTokenAtom, variablesAtom } from "../stores";
import { getValues, getVarCodes, getRemarks } from "../utils";

const useCheckpoint = (sceneNumber: number) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [variables] = useAtom(variablesAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const alert = useAlert();

  useEffect(() => {
    const sendData = async () => {
      setIsInProgress(true);
      const list_var_codes = getVarCodes(sceneNumber);
      if (list_var_codes.length > 0) {
        const list_values = getValues(list_var_codes, variables);
        const list_remarks = getRemarks(list_var_codes, variables);

        const { status } = await upload_multiple(
          accessToken,
          list_var_codes,
          list_values,
          list_remarks
        );

        if (status === 200) {
          alert.success("Checkpoint tersimpan");
        } else {
          alert.error("Checkpoint gagal");
        }

        setIsInProgress(false);
      }
    };
    sendData();
  }, [accessToken, alert, sceneNumber, variables]);

  return { isInProgress };
};

export default useCheckpoint;

