const Scene010: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Berikut ini, YANG BUKAN cara untuk melatih diri menjadi pribadi prestatif adalah ...",
      option: {
        name: "Option",
        param: {
          options: [
            "Merasa tidak puas dengan apa yang sudah dicapai saat ini",
            "Mengikuti kompetisi-kompetisi",
            "Tidak tahu",
            "Bisa menemukan siapa yang salah bila terjadi kegagalan",
            "Membiasakan diri berpikir jangka panjang",
          ],
          values: [false, false, false, true, false],
          variableCode: "105",
          select: 1,
        },
      },
    },
  ],
};

export default Scene010;
