const Scene245: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  next: 246,
  dialogues: [
    {
      characters: ["Humaira (think)"],
      line: "Berikut ini, penulisan tujuan yang benar dan sesuai dengan kriteria adalah ....",
      option: {
        name: "Option",
        param: {
          options: [
            "Pada bulan Desember 2021 berada di peringkat 1 dalam lomba CDP terbaik antar modis ",
            "Memiliki tabungan haji sebesar 10 juta rupiah",
            "Pada tanggal 1 Juli 2022 berusaha mengumpulkan 40 ibu Idamaan",
            "Pada tahun 2022 dikenal sebagai CDP yang amanah oleh Ibu Idamaan",
          ],
          values: [true, false, false, false],
          select: 1,
          variableCode: "218",
        },
      },
    },
  ],
};

export default Scene245;
