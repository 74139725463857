const SceneLulusRemedial = (materi: number): Scene => ({
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "sfxChampion",
  next: 254,
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: `Selamat kakak sudah lulus materi ${materi}.`,
      option: {
        name: null,
      },
    },
  ],
});

export default SceneLulusRemedial;
