async function get_point(access_token: string) {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/point",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data.amount };
  } else {
    console.error({
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

async function put_point(access_token: string, amount: number) {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/point",
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount,
      }),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data.amount };
  } else {
    console.error({
      data: JSON.stringify({
        amount: amount,
      }),
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

export { get_point, put_point };
