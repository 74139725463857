const Scene072: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "bgmWarm1",
  sfx: "",
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Ya Allah. Kamu tidak berhasil lolos latihan tahap pertama. Jangan berkecil hati, tetap fokus gunakan rasa dan pikiran. Kamu jangan lupa untuk mengambil paket latihan ke-2!",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene072;
