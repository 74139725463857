const Scene078: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 79,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullahaladzim. Masih belum mengerti juga?",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Singawidya bukan tergolong pendekar prestatif, karena ia mendasarkan pada dendam dan kekuasaan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (mad)"],
      line: "Ayo, lebih fokus, lanjutkan latihan selanjutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene078;
