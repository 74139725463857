import { atomWithStorage } from "jotai/utils";
import ujiLariState from "../games/UjiLari/stores/state";
import battleCardState from "../games/BattleCard/stores/state";
import { atom } from "jotai";

const newAnswer: Answer = {
  value: false,
  text: "",
  index: -1,
};

const initialVariables = {
  name: newAnswer,
  fullname: newAnswer,
  ...ujiLariState,
  ...battleCardState,
  Scene054: [newAnswer, newAnswer],
  Scene056: [newAnswer, newAnswer],
  Scene102: [newAnswer, newAnswer, newAnswer],
  Scene106: [newAnswer, newAnswer],
  Scene176: [newAnswer, newAnswer, newAnswer],
  Scene235: newAnswer,
  lanjutKeMateri2: newAnswer,
  lanjutKeMateri3: newAnswer,

  // Send to Server
  "101": newAnswer,
  "102": newAnswer,
  "103": newAnswer,
  "104": newAnswer,
  "105": newAnswer,
  "201": newAnswer,
  "202": newAnswer,
  "203": newAnswer,
  "204": newAnswer,
  "205": newAnswer,
  "301": newAnswer,
  "302": newAnswer,
  "303": newAnswer,
  "304": newAnswer,
  "305": newAnswer,
  "106": [newAnswer, newAnswer],
  "107": [newAnswer, newAnswer],
  "108": [newAnswer, newAnswer],
  "109": [newAnswer, newAnswer],
  "110": [newAnswer, newAnswer],
  "111": [newAnswer, newAnswer],
  "112": [newAnswer, newAnswer],
  "113": [newAnswer, newAnswer],
  "206": [newAnswer, newAnswer],
  "207": [newAnswer, newAnswer],
  "208": [newAnswer],
  "209": [newAnswer],
  "210": [newAnswer, newAnswer],
  "211": [newAnswer, newAnswer],
  "212": [newAnswer],
  "213": [newAnswer],
  "306": newAnswer,
  "307": newAnswer,
  "308": newAnswer,
  "309": newAnswer,
  "310": newAnswer,
  "311": newAnswer,
  "312": newAnswer,
  "313": newAnswer,
  "314": newAnswer,
  "315": newAnswer,
  "316": newAnswer,
  "317": newAnswer,
  "401": newAnswer,
  "402": newAnswer,
  "403": newAnswer,
  "404": newAnswer,
  "405": newAnswer,
  "406": newAnswer,
  "407": newAnswer,
  "408": newAnswer,
  "409": newAnswer,
  "410": newAnswer,
  "411": newAnswer,
  "412": newAnswer,
  "413": newAnswer,
  "414": newAnswer,
  "415": newAnswer,
  "416": newAnswer,
  "417": newAnswer,
  "418": newAnswer,
  "419": newAnswer,
  "420": newAnswer,
  "421": newAnswer,
  "422": newAnswer,
  "423": newAnswer,
  "424": newAnswer,
  "425": newAnswer,
  "426": newAnswer,
  "427": newAnswer,
  "428": newAnswer,
  "501": newAnswer,
  "502": newAnswer,
  "114": newAnswer,
  "115": newAnswer,
  "116": newAnswer,
  "117": newAnswer,
  "118": newAnswer,
  "214": newAnswer,
  "215": newAnswer,
  "216": newAnswer,
  "217": newAnswer,
  "218": newAnswer,
  "318": newAnswer,
  "319": newAnswer,
  "320": newAnswer,
  "321": newAnswer,
  "322": newAnswer,
};

const variablesAtom = atomWithStorage<{ [key: string]: Answer | Answer[] }>(
  "variables",
  initialVariables
);

const xpAtom = atom(0);

export { variablesAtom, xpAtom };
