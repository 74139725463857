import { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import { Background, Button } from "../components";
import styles from "../styles/scene.module.css";
import transitionStyle from "../styles/transition.module.css";

interface TransitionProps {
  background: string;
  text?: string;
  transition?: "dark" | "light";
  onEnded?: () => void;
}

const Transition = ({
  background,
  text,
  transition,
  onEnded,
}: TransitionProps) => {
  const [showContinue, setShowContinue] = useState(false);
  const { text: text_ } = useTypewriter({
    words: [text ?? ""],
    typeSpeed: 20,
    onLoopDone: () => {
      // if (onEnded && !background.includes("mp4")) {
      //   setTimeout(() => onEnded(), 2000);
      // }
      setShowContinue(true);
    },
    onType: () => {
      setShowContinue(false);
    },
  });

  if (background.includes("mp4")) {
    return (
      <div className={styles.sceneContainer}>
        <Background
          src={background}
          transition={transition}
          loop={false}
          onEnded={onEnded}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.sceneContainer}>
        <Background src={background} transition={transition} />
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <div className={styles.sceneCenter}>
            <div className={styles.textCenter}>{text_}</div>
          </div>
          <div className={styles.sceneBottom}>
            {showContinue && (
              <div className={transitionStyle.continueButton}>
                <Button type="primary" text="Continue" onClick={onEnded} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Transition;
