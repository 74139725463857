import _4GamePembelajaran from "../assets/contents/4GamePembelajaran.jpg";

const Scene003: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Humaira (happy)"],
      line: "Nah $[name]! sudah coba semua kan fitur-fiturnya kan. Semua fitur tersebut, akan kakak temui dalam program ini. Semoga mudah dipahami dan digunakan ya!",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Metode pelatihan kita, kita sebut dengan game-based learning atau belajar melalui permainan.",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (happy)"],
      line: "Kakak akan mengikuti program belajar melalui sebuah permainan yang sudah kami rancang khusus untuk mengembangkan kemampuanmu sebagai CDP",
      option: {
        name: null,
      },
    },
    {
      characters: ["Humaira (proud)"],
      line: "Selama 4 bulan ke depan, kakak akan mengikuti 4 game pembelajaran. Setiap game akan dimainkan secara terpisah ya!",
      option: {
        name: "Zoom",
        param: {
          contents: [_4GamePembelajaran],
        },
      },
    },
  ],
};

export default Scene003;
