const Scene185: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "",
  variableCheck: "308",
  nextTrue: 186,
  nextFalse: 187,
  dialogues: [
    {
      characters: ["eyang guru (think)"],
      line: "Berikutnya, apa yang dikategorikan sebagai kesempatan (opportunity) dalam menjadi pendekar yang hebat? Silahkan pilih 4 juga ya!",
      option: {
        name: "SelectPut",
        param: {
          title: "Kesempatan",
          options: [
            "Eyang Guru yang bersedia mengajar",
            "Lawan yang sedang cedera",
            "Pendekar seperguruan yang mau jadi lawan tanding",
            "Kitab berisikan ajaran silmu silat tingkat tinggi",
            "Kemampuan mengelola emosi",
            "Kemampuan meramalkan jurus selanjutnya dari lawan",
            "Memilki ilmu silat maha dahsyat untuk mengalahkan siapapun",
            "Dorongan yang tinggi untuk mengembangkan jurus-jurus baru",
          ],
          values: [true, true, true, true, false, false, false, false],
          select: 4,
          variableCode: "308",
        },
      },
    },
  ],
};

export default Scene185;
