const Scene236: Scene = {
  type: "Scene",
  background: "class",
  bgm: "bgmClassic2",
  sfx: "",
  next: 237,
  dialogues: [
    {
      characters: ["Alif (think)"],
      line: "Seseorang yang selalu ingin memperoleh hasil yang lebih baik dari sebelumnya, disebut ...",
      option: {
        name: "Option",
        param: {
          options: ["Prestatif", "Ambisius", "Tidak mau kalah", "Kompetitif"],
          values: [true, false, false, false],
          select: 1,
          variableCode: "114",
        },
      },
    },
  ],
};

export default Scene236;
