const Scene150: Scene = {
  type: "Scene",
  background: "class",
  bgm: "[continue]",
  sfx: "",
  dialogues: [
    {
      characters: ["Alif (normal)"],
      line: "Selamat datang kembali $[fullname]! Sudah tidak sabar ya untuk materi selanjutnya? Saya akan mengirimkan kakak langsung ke Eyang Guru! Kita tunggu Eyang membuka portal yaa",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene150;
