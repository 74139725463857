async function request(access_token: string) {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/sapi/users/request-action-plan",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data.task_id };
  } else {
    console.error({
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

async function status(access_token: string, task_id: string) {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/sapi/users/status-action-plan?task_id=${task_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data };
  } else {
    console.error({
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

async function download(access_token: string, task_id: string) {
  const response = await fetch(
    process.env.REACT_APP_API_URL +
      `/sapi/users/download-action-plan?task_id=${task_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data: data };
  } else {
    console.error({
      response,
    });
    return { status: response.status, data: response.statusText };
  }
}

export { request, status, download };
