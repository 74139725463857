import { useSceneManager } from "./hooks";
import { ActionPlan, End, Login, Scene, Transition } from "./pages";
import { scenes } from "./scenes";
import "./App.css";
import {
  npcFirst,
  playerFirst,
  together,
} from "./games/BattleCard/stores/constant";
import { BattleCard, PingPong, SiapaPrestatif, UjiLari } from "./games";
import { Audio, Button, Modal, Progress, XP } from "./components";
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ReactComponent as Expand } from "./assets/buttons/expand-solid.svg";
import { ReactComponent as Compress } from "./assets/buttons/compress-solid.svg";

const App = () => {
  const {
    sceneNumber,
    type,
    background,
    bgm,
    sfx,
    transition,
    characterNames,
    characterImages,
    line,
    option,
    next,
    nextScene,
    nextUjiLari,
    nextBattleCard,
  } = useSceneManager(scenes);
  const [showModal, setShowModal] = useState(false);

  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <div
        className="App"
        style={{ overflow: type === "ActionPlan" ? "auto" : "hidden" }}
      >
        {/* <img
          src={handle.active ? compress : expand}
          style={{
            position: "fixed",
            width: 24,
            height: 24,
            bottom: 12,
            left: 12,
            zIndex: 2,
          }}
          alt="Full Screen"
          onClick={handle.active ? handle.exit : handle.enter}
        /> */}
        {handle.active ? (
          <Compress
            style={{
              position: "fixed",
              width: 24,
              height: 24,
              bottom: 12,
              left: 12,
              zIndex: 2,
            }}
            onClick={handle.exit}
          />
        ) : (
          <Expand
            style={{
              position: "fixed",
              width: 24,
              height: 24,
              bottom: 12,
              left: 12,
              zIndex: 2,
            }}
            onClick={handle.enter}
          />
        )}
        <Modal show={showModal} onClose={() => setShowModal(false)} />
        <div className="Top">
          <Button text="?" type="primary" onClick={() => setShowModal(true)} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {type !== "Login" && type !== "End" && type !== "ActionPlan" && (
              <>
                <Progress sceneNumber={sceneNumber} />
                <XP />
              </>
            )}
            <Audio
              sfxSrc={sfx}
              bgmSrc={bgm}
              silent={type === "Transition" || option.name === "Video"}
            />
          </div>
        </div>
        <div>
          {type === "Login" && <Login onStartGame={nextScene} />}
          {type === "End" && <End onRemedial={nextScene} />}
          {type === "Scene" && (
            <Scene
              background={background}
              transition={transition}
              characterNames={characterNames}
              characterImages={characterImages}
              line={line}
              option={option}
              onNext={next}
            />
          )}
          {type === "Transition" && (
            <Transition
              background={background}
              text={line}
              transition={transition}
              onEnded={next}
            />
          )}
          {type === "UjiLari1" && <UjiLari ronde={1} onFinish={nextUjiLari} />}
          {type === "UjiLari2" && <UjiLari ronde={2} onFinish={nextUjiLari} />}
          {type === "UjiLari3" && <UjiLari ronde={3} onFinish={nextUjiLari} />}
          {type === "SiapaPrestatif" && <SiapaPrestatif onFinish={next} />}
          {type === "BattleCard1" && (
            <BattleCard ronde={together} onFinish={nextBattleCard} />
          )}
          {type === "BattleCard2" && (
            <BattleCard ronde={playerFirst} onFinish={nextBattleCard} />
          )}
          {type === "BattleCard3" && (
            <BattleCard ronde={npcFirst} onFinish={nextBattleCard} />
          )}
          {type === "ActionPlan" && <ActionPlan onFinish={next} />}
          {type === "PingPong" && <PingPong onFinish={next} />}
        </div>
      </div>
    </FullScreen>
  );
};

export default App;

