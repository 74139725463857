const Scene205: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 206,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Astagfirullah! Masih semangatkan? Ayo, fokus jika ingin menang. ",
      option: {
        name: null,
      },
    },
    {
      characters: ["eyang guru (sad)"],
      line: "Jawabannya adalah Siluman Tamak yang semakin banyak pendukungnya, ilmu Siluman Tamak yang sudah tinggi, pendekar lain yang tidak mau kerjasama, dan wabah penyakit di masa pancaroba.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene205;
