const Scene115: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 116,
  dialogues: [
    {
      characters: ["eyang guru (sad)"],
      line: "Ya Allah. Konsentrasi dong. Tentu saja Janilaya tidak lolos karena tidak memenuhi kriteria manfaat. Menjadi populer dan disukai orang lain, kurang bermanfaat dalam peran sebagai pendekar.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene115;
