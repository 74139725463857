const Scene180: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxCorrect",
  next: 182,
  dialogues: [
    {
      characters: ["eyang guru (happy)"],
      line: "Bagus! Lanjutkan latihan berikutnya.",
      option: {
        name: null,
      },
    },
  ],
};

export default Scene180;
