import materi from "../assets/contents/materi2Practice7jawaban.jpg";

const Scene134: Scene = {
  type: "Scene",
  background: "kelaspadepokan",
  bgm: "[continue]",
  sfx: "sfxWrong",
  next: 135,
  dialogues: [
    {
      characters: ["eyang guru (mad)"],
      line: "Ya salah dong. Tujuan yang kamu pilih tidak memenuhi kriteria hasil. Ayo, perhatikan penjelasan berikut ini. ",
      option: {
        name: "Zoom",
        param: {
          contents: [materi],
        },
      },
    },
  ],
};

export default Scene134;
